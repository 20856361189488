import { IWidgetBase, IGroup } from "../TabDataFields/group.model";
import { UISchemaField } from "./ui-shema.model";

export function getPageFieldIndex (id: string): number {
    return Number(id.replace("page-fields-", ""));
}

export function getName(field: UISchemaField): string {
    if ((<IGroup>field).groupName) {
        return (<IGroup>field).groupName;
    } else if ((<IWidgetBase>field).fieldName) {
        return (<IWidgetBase>field).fieldName;
    } else {
        return "";
    }
}

export function getSystemInternalId(field: UISchemaField): string {
    if ((<IGroup>field).groupSystemInternalId) {
        return (<IGroup>field).groupSystemInternalId;
    } else if ((<IWidgetBase>field).fieldSystemInternalId) {
        return (<IWidgetBase>field).fieldSystemInternalId;
    } else {
        return "";
    }
}

export const reorder = < T extends unknown >(list: T[], startIndex: number, endIndex: number): T[] => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};
