import React from "react";
import { IGlobalState } from "./state.model";
import { namespacedLocalStorage, signInService } from "@surelync/common";

const GlobalContext = React.createContext<IGlobalState>({
    clientLogo: null,
    currentUser: {
        authDetails: signInService.loadAuthDetails(),
        userDetails: signInService.loadUserDetails(),
    },
    genericAttachments: null,
    linkedEnv: null,
    isProd: null,
    locale: null,
    productForCopy: null,
    namespacedLocalStorage,
});

export default GlobalContext;
