import React from "react";
import { Link } from "react-router-dom";
import { CellInfo } from "react-table";
import { Link as LinkButton } from "@material-ui/core";

export const getHeaderColumnsI18 = (t: (s: string) => string) => [
    {
        Header: () => t("change"),
        accessor: "change",
        size: "large",
    },
    {
        Header: () => t("details"),
        accessor: "details",
        size: "small",
        Cell: (row: CellInfo) => (
            <LinkButton component={Link} to={{
                pathname: `/surebyld/${row.row.original.productId}/${row.row.original.version}`,
                state: { from: `/surebyld/${row.row.original.productId}/versions`, backBtnlabel: t("productHistory") }
            }}>
                {t("view")}
            </LinkButton>
        ),
    },
    {
        Header: () => t("version"),
        accessor: "version",
        size: "small",
    },
    {
        Header: () => t("status"),
        accessor: "status",
        size: "small",
    },
    {
        Header: () => t("modifiedBy"),
        accessor: "modifiedBy",
        size: "medium",
    },
    {
        Header: () => t("participants"),
        accessor: "participants",
        size: "medium",
    },
    {
        Header: () => t("approvalStatus"),
        accessor: "approvalStatus",
        size: "medium",
    },
    {
        Header: () => t("comments"),
        accessor: "comments",
        size: "medium",
    },
    // {
    //     Header: () => t("actions"),
    //     accessor: "actions",
    //     size: "small",
    // }
];
