import { IAction, IGlobalState } from "./state.model";
import { isEnvProdLike, namespacedLocalStorage } from "@surelync/common";

export const SET_CLIENT_LOGO = "SET_CLIENT_LOGO";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_LINKED_ENV = "SET_LINKED_ENV";
export const SET_GENERIC_ATTACHMENTS = "SET_GENERIC_ATTACHMENTS";
export const SET_LOCALE = "SET_LOCALE";
export const SET_PRODUCT_FOR_COPY = "SET_PRODUCT_FOR_COPY";

export const defaultState: IGlobalState = {
    clientLogo: null,
    currentUser: null,
    genericAttachments: null,
    isProd: isEnvProdLike(),
    linkedEnv: null,
    locale: null,
    productForCopy: null,
    namespacedLocalStorage
};

export const globalReducer = (state: IGlobalState, action: IAction) => {
    switch (action.type) {
        case SET_CLIENT_LOGO:
            return { ...state, clientLogo: action.payload };

        case SET_CURRENT_USER:
            return { ...state, currentUser: action.payload };

        case SET_GENERIC_ATTACHMENTS:
            return { ...state, genericAttachments: action.payload };

        case SET_LINKED_ENV:
            return { ...state, linkedEnv: action.payload };

        case SET_LOCALE:
            return { ...state, locale: action.payload };

        case SET_PRODUCT_FOR_COPY:
            return { ...state, productForCopy: action.payload };

        default:
            return state;
    }
};
