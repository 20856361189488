import React, { Fragment, useContext } from "react";
import { Card, CardContent, CardHeader, Divider, Grid, Typography, useTheme } from "@material-ui/core";
import { useTranslationNamespaces } from '@blocksure/blocksure-core/dist/src/utilities';
import { EAttachmentTransaction, i18n, InputCustom, INotification, IProduct, SelectCustom } from "@surelync/common";
import { IFormik } from "../../../models";
import GlobalContext from "../../../../context/global-context";
import { optionsFrequency } from "../constants";
import { applyDenomination, applyNumber, parseDenomination, parseNumber } from "./helpers";
import get from "lodash/get";
import camelCase from "lodash/camelCase";
import useStyles from "../../../styles";

interface IProps {
    formik: IFormik<IProduct>;
}

const RenewalOffsets: React.FC<IProps> = ({ formik }) => {
    const { isProd } = useContext(GlobalContext);
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslationNamespaces(i18n, ["surebyld", "surelync"]);

    const handleChangeNumber = (indexNotification: number, indexOffset: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.currentTarget;
        const property = `notifications[${indexNotification}].offsets[${indexOffset}]`;
        const offset = get(formik.values, property);
        const newNumber = applyNumber(offset, value);

        formik.setFieldValue(property, newNumber);
        formik.setFieldTouched(property, true, true);
    };

    const handleChangeDenomination = (indexNotification: number, indexOffset: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event) {
            return;
        }

        const { value: newDenomination } = event.target;
        const property = `notifications[${indexNotification}].offsets[${indexOffset}]`;
        const offset = get(formik.values, property);
        const newOffset = applyDenomination(offset, newDenomination);

        formik.setFieldValue(property, newOffset);
        formik.setFieldTouched(property, true, true);
    };

    const renderNotification = (transaction: EAttachmentTransaction) => {
        const result = formik.values.notifications.map((notification: INotification, indexNotification: number) => {
            if (notification.transaction === transaction) {
                if (!notification.offsets) {
                    notification.offsets = [""];
                }
                return notification.offsets.map((offset: string, indexOffset: number) => (
                    <Fragment key={`renewal-${indexNotification}-${indexOffset}`}>
                        <Grid item xs={1} classes={{ root: classes.py0 }}>
                            <InputCustom
                                autoComplete="off"
                                data-testid="renewal-invite"
                                readOnly={isProd}
                                theme={theme}
                                value={parseNumber(offset)}
                                onChange={handleChangeNumber(indexNotification, indexOffset)}
                            />
                        </Grid>
                        <Grid item xs={2} classes={{ root: classes.py0 }}>
                            <SelectCustom
                                data-testid="renewal-invite-denomination"
                                options={optionsFrequency(t)}
                                readOnly={isProd}
                                theme={theme}
                                value={parseDenomination(offset)}
                                onChange={handleChangeDenomination(indexNotification, indexOffset)}
                            />
                        </Grid>
                        <Grid item xs={3} classes={{ root: classes.py0 }} />
                    </Fragment>
                ));
            } else {
                return null;
            }
        }).filter((it) => Boolean(it));

        return result.length
            ? result
            : (
                <Typography variant="caption">
                    {`${t("docsFieldDisabled", { fieldName: t(camelCase(transaction)) })}`}
                </Typography>
            );
    }

    return (
        <Grid item sm={12}>
            <Card variant="outlined">
                <CardHeader classes={{ root: classes.root, title: classes.title }} title={t("renewalOffsets")} />
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={6} classes={{ root: classes.py0 }}>
                            <Typography align="right" variant="body1">
                                {t("unitsB4Expiry", {
                                    value: t("invitationToRenew")
                                })}
                            </Typography>
                        </Grid>

                        {renderNotification(EAttachmentTransaction.RenewalInvite)}

                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} classes={{ root: classes.py0 }}>
                            <Typography align="left" variant="body1">
                                {t("ifNotRenewedThenEmails")}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} classes={{ root: classes.py0 }}>
                            <Typography align="right" variant="body1">
                                {t("unitsB4Expiry", {
                                    value: t("renewalReminder")
                                })}
                            </Typography>
                        </Grid>

                        {renderNotification(EAttachmentTransaction.PreRenewalReminder)}

                        <Grid item xs={12}>
                            <Divider />
                        </Grid>

                        <Grid item xs={6} classes={{ root: classes.py0 }}>
                            <Typography align="right" variant="body1">
                                {t("unitsB4Expiry", {
                                    value: t("lapsedWarning")
                                })}
                            </Typography>
                        </Grid>

                        {renderNotification(EAttachmentTransaction.LapseWarning)}

                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
};

export default React.memo(RenewalOffsets);
