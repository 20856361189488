import React, { Fragment } from 'react';
import { Box, Divider, Grid, Typography } from '@material-ui/core';
import { IGroup, IWidgetBase } from '../../../../TabDataFields/group.model';
import { WidgetProperties } from './WidgetProperties';
import { FormikErrors } from 'formik';
import {JSONSchema7} from "json-schema";

interface Props {
    errors: string;
    field: IGroup;
    readOnly: boolean;
    onDefault: (value: string, index?: number) => void;
    onHidden: (checked: boolean, index?: number) => void;
}

export const AddressProperties: React.FC<Props> = React.memo(({ errors, field, readOnly, onDefault, onHidden }) => {

    const handleDefault = (index: number) => (value: string) => {
        onDefault(value, index);
    }

    const handleHidden = (index: number) => (checked: boolean) => {
        onHidden(checked, index);
    }

    return (
        <Grid container spacing={1}>
            {(field.properties as IWidgetBase[]).map((field, index) => (
                <Fragment key={index}>
                    <Grid item xs={4}>
                        <Box pt={1}>
                            <Typography variant="subtitle2">{field.fieldName}</Typography>
                            <Typography variant="body1">{field.fieldSystemInternalId}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={8}>
                        <WidgetProperties
                            error={(errors as any)?.properties[index]?.fieldDefault}
                            field={field}
                            readOnly={readOnly}
                            onDefault={handleDefault(index)}
                            onHidden={handleHidden(index)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                </Fragment>
            ))}
        </Grid>
    );
});
