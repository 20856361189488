import React, { Fragment, useCallback, useContext } from "react";
import { Box, Button, Card, CardContent, Checkbox, FormControlLabel, Grid, Hidden, IconButton, Typography, useTheme } from "@material-ui/core";
import { useTranslationNamespaces } from '@blocksure/blocksure-core/dist/src/utilities';
import { IFormik } from "../../../../models";
import get from "lodash/get";
import { IDataDictionary } from "../../data-dictionary.model";
import { i18n, InputCustom, SelectCustom } from "@surelync/common";
import { IEnumWidget } from "../../group.model";
import DeleteIcon from "@material-ui/icons/Delete";
import useStyles from "../../../../styles";
import GlobalContext from "../../../../../context/global-context";
import { Add } from "@material-ui/icons";
import isNil from "lodash/isNil";
import { handleFocus, isReadOnly, optionsType } from "../../helper";
import { ProductContext } from "../../../../contexts";

interface IProps {
    countryLabel: string;
    isAdvancedMode: boolean;
    formik: IFormik<IDataDictionary>;
    property: string;
    section: IEnumWidget;
    onAutoGenerateSystemID: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onDropdown: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onRemove: () => void;
    onRequired: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const EnumWidgets: React.FC<IProps> = ({ countryLabel, isAdvancedMode, formik, property, section, onAutoGenerateSystemID, onDropdown, onRemove, onRequired }) => {
    const { isProd } = useContext(GlobalContext);
    const [, dispatch] = useContext(ProductContext);
    const classes = useStyles();
    const { t } = useTranslationNamespaces(i18n, ["surebyld", "surelync"]);
    const theme = useTheme();

    const handleAddDropdownItem = useCallback(() => {
        formik.setFieldValue(`${property}.enum`, [...section.enum, ""]);
        if (section.enumNames) {
            formik.setFieldValue(`${property}.enumNames`, [...section.enumNames, ""]);
        }
    }, [section.enum, section.enumNames]);

    const handleRemoveDropdownItem = useCallback((index: number) => () => {
        section.enum.splice(index, 1);
        formik.setFieldValue(`${property}.enum`, section.enum.length ? [...section.enum] : [""]);
        if (section.enumNames) {
            section.enumNames.splice(index, 1);
            formik.setFieldValue(`${property}.enumNames`, section.enumNames.length ? [...section.enumNames] : [""]);
        }
    }, [section.enum, section.enumNames]);

    const getPropPath = (name: string, index?: number) => isNil(index) ? `${property}.${name}` : `${property}.${name}[${index}]`;
    const readOnly = isReadOnly(isProd, section);

    return (
        <Grid container spacing={1} key={`section-${property}`}>
            <Grid item xs={12}>
                <Card variant="outlined" classes={{ root: readOnly ? classes.requiredCard : null }}>
                    <CardContent>
                        {readOnly ? (
                            <Box mb={3}>
                                <Typography variant="caption">{t("fieldRestriction", { country: countryLabel })}</Typography>
                            </Box>
                        ) : null}
                        <Grid container spacing={3}>
                            <Grid item xs={11}>
                                <InputCustom
                                    autoComplete="off"
                                    error={get(formik.errors, getPropPath("fieldName"))}
                                    label={`${t("field")} ${t("name")} (${t("displayedToAllParties")})`}
                                    name={getPropPath("fieldName")}
                                    readOnly={isProd}
                                    required
                                    theme={theme}
                                    touched={get(formik.touched, getPropPath("fieldName"))}
                                    value={section.fieldName}
                                    onBlur={onAutoGenerateSystemID}
                                    onChange={formik.handleChange}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                {!isProd ? (
                                    <Box textAlign="right">
                                        <IconButton
                                            color="primary"
                                            data-testid={`delete-${property}`}
                                            disabled={section.isFromUnersalSchema}
                                            size="small"
                                            onClick={onRemove}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Box>
                                ) : null}
                            </Grid>
                            <Hidden xsUp={!isAdvancedMode}>
                                <Grid item xs={6}>
                                    <InputCustom
                                        autoComplete="off"
                                        error={get(formik.errors, getPropPath("fieldSystemInternalId"))}
                                        label={`${t("field")} ${t("id")} (${t("systemInternal")} ${t("id")})`}
                                        name={getPropPath("fieldSystemInternalId")}
                                        readOnly={readOnly}
                                        required
                                        theme={theme}
                                        touched={get(formik.touched, getPropPath("fieldSystemInternalId"))}
                                        value={section.fieldSystemInternalId}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        onFocus={readOnly ? null : handleFocus(section.fieldSystemInternalId, get(formik.touched, getPropPath("fieldSystemInternalId")), dispatch)}
                                    />
                                </Grid>
                            </Hidden>
                            <Grid item xs={3}>
                                <SelectCustom
                                    name={getPropPath("type")}
                                    label={t("fieldType")}
                                    options={optionsType.map(({label, value}) => ({label: t(label), value}))}
                                    readOnly={readOnly}
                                    required
                                    theme={theme}
                                    value={section.fieldType}
                                    onChange={formik.handleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2">{t("Dropdown Items")}</Typography>
                            </Grid>
                            <Grid item xs={5} classes={{ root: classes.py0 }}>
                                <Typography variant="body2">{t("value")}</Typography>
                            </Grid>
                            <Grid item xs={5} classes={{ root: classes.py0 }}>
                                <Typography variant="body2">{t("name")}</Typography>
                            </Grid>
                            {section.enum.map((item, index) => (
                                <Fragment key={index}>
                                    <Grid item xs={4} classes={{ root: classes.py0 }}>
                                        <InputCustom
                                            autoComplete="off"
                                            error={get(formik.errors, getPropPath("enum", index))}
                                            name={getPropPath("enum", index)}
                                            readOnly={isProd}
                                            required
                                            theme={theme}
                                            touched={get(formik.touched, getPropPath("enum", index))}
                                            value={item}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={5} classes={{ root: classes.py0 }}>
                                        <InputCustom
                                            autoComplete="off"
                                            error={get(formik.errors, getPropPath("enumNames", index))}
                                            name={getPropPath("enumNames", index)}
                                            readOnly={isProd}
                                            required
                                            theme={theme}
                                            touched={get(formik.touched, getPropPath("enumNames", index))}
                                            value={section.enumNames ? section.enumNames[index] : item}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={1} classes={{ root: classes.py0 }}>
                                        {!isProd ? (
                                            <IconButton
                                                color="primary"
                                                data-testid={`delete-${getPropPath("enum", index)}`}
                                                size="small"
                                                onClick={handleRemoveDropdownItem(index)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        ) : null}
                                    </Grid>
                                    {!isProd ? (
                                        <Grid item xs={2} classes={{ root: classes.py0 }}>
                                            {index === 0 && (
                                                <Box textAlign="right">
                                                    <Button endIcon={<Add />} variant="text" onClick={handleAddDropdownItem}>
                                                        {t("add")}
                                                    </Button>
                                                </Box>
                                            )}
                                        </Grid>
                                    ) : null}
                                </Fragment>
                            ))}

                            <Grid item xs={3} classes={{ root: classes.py0 }}>
                                <FormControlLabel
                                    label={t("required")}
                                    disabled={readOnly}
                                    control={
                                        <Checkbox
                                            checked={section.isRequired}
                                            color={section.isRequired ? "primary" : "secondary"}
                                            onChange={onRequired}
                                        />
                                    }
                                />
                            </Grid>
                            <Grid item xs={3} classes={{ root: classes.py0 }}>
                                <FormControlLabel
                                    label={t("dropdown")}
                                    disabled={readOnly}
                                    control={
                                        <Checkbox
                                            checked={true}
                                            color="primary"
                                            onChange={onDropdown}
                                        />
                                    }
                                />
                            </Grid>

                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid >
    );
};

export default React.memo(EnumWidgets);
