import React, { useContext } from "react";
import { Box, Card, CardContent, CardHeader, Checkbox, FormControlLabel, Grid, useTheme } from "@material-ui/core";
import useStyles from "../../../styles";
import { useTranslationNamespaces } from '@blocksure/blocksure-core/dist/src/utilities';
import { CountriesSelect, i18n, generateLanguageOptions, InputCustom, IProduct, SelectCustom } from "@surelync/common";
import { IFormik } from "../../../models";
import GlobalContext from "../../../../context/global-context";

enum ECurrency {
    GBP = "GBP",
    IDR = "IDR",
    JPY = "JPY",
    EUR = "EUR",
    USD = "USD",
}
const optionsCurrency = Object.keys(ECurrency).map((currency) => ({label: currency, value: currency}));

interface IProps {
    formik: IFormik<IProduct>;
}

const Summary: React.FC<IProps> = ({ formik }) => {
    const { isProd } = useContext(GlobalContext);
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslationNamespaces(i18n, ["surebyld", "surelync"]);

    const handleChangeRefundMode = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.checked ? "Enabled" : "Disabled";
        formik.setFieldValue("refundMode", value);
    };

    const handleChangeCountry = (event: React.ChangeEvent,  value: string) => {
        formik.setFieldValue("country", value || "", true);
        formik.setFieldTouched("country", true, false);
    };

    const isRefundModeChecked = formik.values.refundMode === "Enabled";

    const optionsLanguages = generateLanguageOptions(t);

    
    return (
        <Grid item sm={12}>
            <Card variant="outlined">
                <CardHeader classes={{ root: classes.root, title: classes.title }} title={t("summary")} />
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item xs={6} classes={{ root: classes.noPaddingTop }}>
                            <CountriesSelect
                                error={formik.errors.country}
                                fullWidth
                                getLabel={false}
                                label={`${t("country")}*`}
                                name="country"
                                readOnly={isProd}
                                theme={theme}
                                touched={formik.touched.country}
                                value={formik.values.country}
                                onChange={handleChangeCountry}
                            />
                        </Grid>
                        <Grid item xs={3} classes={{ root: classes.noPaddingTop }}>
                            <SelectCustom
                                error={formik.errors.currency as string}
                                label={`${t("currency")}*`}
                                name="payment.currency"
                                options={optionsCurrency}
                                readOnly={isProd}
                                theme={theme}
                                touched={formik.touched.payment?.currency}
                                value={formik.values.payment.currency}
                                onChange={formik.handleChange}
                            />
                        </Grid>
                        <Grid item xs={3} classes={{ root: classes.noPaddingTop }}>
                            <SelectCustom
                                error={formik.errors.language as string}
                                label={`${t("language")}*`}
                                name="language"
                                options={optionsLanguages}
                                readOnly={isProd}
                                theme={theme}
                                touched={!!formik.touched.language}
                                value={formik.values.language}
                                onChange={formik.handleChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <InputCustom
                                autoComplete="off"
                                error={formik.errors.insuranceType as string}
                                label={`${t("insuranceType")}*`}
                                name="insuranceType"
                                readOnly={isProd}
                                theme={theme}
                                touched={!!formik.touched.insuranceType}
                                value={formik.values.insuranceType}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Box pt={1}>
                                <FormControlLabel
                                    label={t("refundMode")}
                                    control={
                                        <Checkbox
                                            checked={isRefundModeChecked}
                                            color={isRefundModeChecked ? "primary" : "secondary"}
                                            disabled={isProd}
                                            onChange={handleChangeRefundMode}
                                        />
                                    }
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
};

export default Summary;