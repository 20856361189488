import React, { useContext, useState } from "react";
import { useTranslationNamespaces } from '@blocksure/blocksure-core/dist/src/utilities';
import {generateErrorMessage} from '@blocksure/blocksure-core/dist/src/utilities/ErrorHandler';
import { Box, Grid, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { ProductApiClient } from "@blocksure/blocksure-core/dist/src/services/api-clients/";
import { i18n, MessageBox, PageContainer, RecordsNotFound, TableContainer } from "@surelync/common";
import { setDataHistory } from "./helper";
import GlobalContext from "../context/global-context";
import { BackButton } from "../components";
import { getHeaderColumnsI18 } from "./columns.config";
import { BannerClientLogo } from "../components/BannerClientLogo/BannerClientLogo";
import { useParams } from "react-router-dom";
import { IHistoryResponse } from "./history.model";

const ProductVersionListPage: React.FC = () => {
    const { currentUser: { authDetails }, namespacedLocalStorage } = useContext(GlobalContext);
    const { id } = useParams<{ id: string }>();
    const [allData, setAllData] = useState<IHistoryResponse[]>(null);
    const [data, setData] = useState(null);
    const [headerColumns, setHeaderColumns] = useState(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>(null);
    const [total, setTotal] = useState<number>(null);

    const { t } = useTranslationNamespaces(i18n, ["surebyld", "surelync"]);
    const theme = useTheme();

    const fetchData = async (rowsPerPage: number, page: number) => {
        const productApiClient = new ProductApiClient(namespacedLocalStorage);
        // server do not support pagination, so get all data and make fake pagination
        if (allData) {
            const productsByPage: any = Object.values(allData).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
            const dataPolicies = setDataHistory(productsByPage, authDetails);
            setData(dataPolicies);
            return;
        }

        setLoading(true);
        try {
            const response: IHistoryResponse[] = await productApiClient.getProductDeltas(id);
            const dataByPage = response.slice(0, rowsPerPage);
            const dataPolicies = setDataHistory(dataByPage, authDetails);
            const headerColumnsI18 = getHeaderColumnsI18(t);

            setHeaderColumns(headerColumnsI18);
            setAllData(response);
            setData(dataPolicies);
            setTotal(response.length);
        } catch (error) {
            setError(generateErrorMessage(error));
            console.warn("error", error.message);
        }
        setLoading(false);
    };

    return (
        <PageContainer theme={theme} title={t("productHistory")}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Grid container alignItems="flex-end">
                        <Grid item xs={12} sm={8} md={5}>
                            <Box mb={1}>
                                <BackButton />
                            </Box>
                            <Typography variant="h5">{t("productHistory")}</Typography>
                            {id !== "new" ? (
                                <Typography color="textPrimary" variant="body2">
                                    {`${t("id")} - ${id}`}
                                </Typography>
                            ) : null}
                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                            <Box textAlign={{ xs: "right", md: "center" }}>
                                <BannerClientLogo />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8} md={5} />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TableContainer theme={theme} columns={headerColumns} data={data} fetchData={fetchData} loading={loading} total={total} />
                </Grid>
                {data && data.length === 0 && (
                    <Grid item xs={12}>
                        <RecordsNotFound theme={theme} />
                    </Grid>
                )}
                {error && (
                    <Grid item xs={12} md={8} data-testid="product-error">
                        <MessageBox message={error} theme={theme} variant="error" onClose={() => setError(null)} />
                    </Grid>
                )}
            </Grid>
        </PageContainer>
    );
};

export default ProductVersionListPage;
