import React, { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { useFormik } from "formik";
import { useTranslationNamespaces } from '@blocksure/blocksure-core/dist/src/utilities';
import cloneDeep from "lodash/cloneDeep";
import isNil from "lodash/isNil";
import { Box, Button, Grid, IconButton, Tab, Tabs, Typography, useTheme } from "@material-ui/core";
import { Add, Delete, FileCopy } from "@material-ui/icons";
import { i18n, Preloader, getCountries, MessageBox } from "@surelync/common";
import { ConfirmDialog } from "../ConfirmDialog/ConfirmDialog";
import { BlobApiClient, PolicyholderApiClient, ProductApiClient } from "@blocksure/blocksure-core/dist/src/services/api-clients";
import { getCountry, getDictionaryTag, getNotManadatoryGroups, getUniversalFields } from "./helper";
import GlobalContext from "../../../context/global-context";
import { IDataDictionary } from "./data-dictionary.model";
import { GroupType, IGroup } from "./group.model";
import { MAX_TAB_NAME, ProductContext } from "../../contexts";
import useStyle from "../../styles";
import Overview from "./components/Overview/Overview";
import { validateDataDictionary } from "./validate-data-dictionary";
import { setDataFields, showSystemInternalID } from "../../reducer";

type IProps = Record<string, unknown>;

const TabDataFields: React.ForwardRefRenderFunction<Record<string, unknown>, IProps> = (props, ref) => {
    const { isProd, namespacedLocalStorage } = useContext(GlobalContext);
    const [{ data: product, isSystemInternalID, dataFields }, dispatch] = useContext(ProductContext);
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    // const [dictionaries, setDictionaries] = useState(null);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [countryLabel, setCountryLabel] = useState("");
    // const [universalPolicyholderSchema, setUniversalPolicyholderSchema] = useState(null);
    const classes = useStyle();
    const theme = useTheme();
    const { t } = useTranslationNamespaces(i18n, ["surebyld", "surelync"]);

    const formik = useFormik<IDataDictionary>({
        initialValues: {
            groups: dataFields,
        },
        enableReinitialize: true,
        validate: validateDataDictionary,
        onSubmit: async (values) => {
            // storeDataDictionary();
            // update Product request
            dispatch(setDataFields(values.groups));  // FIXME store Blob
        },
    });

    useEffect(() => {
        // Create new Product required Country
        if (!product.country) {
            formik.setFieldValue("groups", [], true);
            return;
        }

        // Goups already parsed and stored in the ProductContext, init formik
        if (formik.values.groups) {
            setSelectedGroup(0);
            return;
        }

        let isMount = true;
        const fetchBlob = async () => {
            const blobApiClient = new BlobApiClient(namespacedLocalStorage);
            const policyholderApiClient = new PolicyholderApiClient(namespacedLocalStorage);
            const productApiClient = new ProductApiClient(namespacedLocalStorage);
            const dictionaryTag = getDictionaryTag(product);
            const attachmentDef = product.hiddenAttachments?.find((a) => a.name === "def.json");

            try {
                const universalPolicyholderSchema = await policyholderApiClient.getSchema(product.country);
                const def = attachmentDef ? await productApiClient.getAttachment(product.id, attachmentDef.location, true) : {};
                // const blob = await blobApiClient.retrievedTag(dictionaryTag);
                if (!isMount) {
                    return;
                }
                // setUniversalPolicyholderSchema(universalPolicyholderSchema);
                // setDictionaries(blob);
                const manadatoryPersonalGroup = getUniversalFields(universalPolicyholderSchema, def, ["personal", "contact"]);
                const manadatoryAddressGroup = getUniversalFields(universalPolicyholderSchema, def, ["address"]);
                const notManadatoryGroups = getNotManadatoryGroups(def);
                setSelectedGroup(0);
                // should be 2 mandatory tabs (personal + address) from UPS
                dispatch(setDataFields([manadatoryPersonalGroup, manadatoryAddressGroup, ...notManadatoryGroups]))

            } catch (error) {
                console.error(error);
                if (!isMount) {
                    return;
                }
                // setDictionaries([]);
                setSelectedGroup(0);
                formik.setFieldValue("groups", [], true);
            }
        };

        fetchBlob();

        return () => {
            isMount = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (product.country) {
            const fetchCountries = async () => {
                try {
                    const countries = await getCountries();
                    const countryLabel = getCountry(countries, product.country);
                    setCountryLabel(countryLabel);
                } catch (error) {
                    console.error(error);
                }
            }
            fetchCountries();
        }
    }, []);

    // AutoSave
    // useEffect(() => {
    //     if (!formik.dirty || isEqual(formik.values.groups, dictionaries)) return;

    //     const timerId = setTimeout(async () => {
    //         const params = {
    //             activeTab: ETab.DataFields,
    //             product,
    //         };
    //         storeDataDictionary();
    //         const savedProduct = storeProduct(params);
    //         dispatch(update(savedProduct));
    //         dispatch(autosave(Date.now()));
    //     }, DELAY_AUTO_SAVE);

    //     return () => clearTimeout(timerId);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [formik.dirty, formik.values, dispatch, namespacedLocalStorage, product]);

    const groupNames = useMemo(() => {
        const { groups } = formik.values;
        if (!groups) {
            return [];
        }
        return groups ? groups.map((it) => it.groupName) : [];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.groups]);

    const getNewGroupName = () => {
        const { groups } = formik.values;
        return `${t("name")} ${groups ? groups.length + 1 : 1}`;
    };

    const handleAddGroup = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation();
            event.preventDefault();

            const { groups } = formik.values;
            const newGroups: IGroup[] = [];

            if (groups && groups.length) {
                newGroups.push(...groups);
            }

            newGroups.push({
                groupName: getNewGroupName(),
                groupSystemInternalId: "",
                isFromUnersalSchema: false,
                type: GroupType.AdditionalDictionary,
            });

            formik.setFieldValue("groups", newGroups, true);
            setSelectedGroup(newGroups.length - 1);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [formik, selectedGroup]
    );

    const handleDeleteGroup = useCallback(
        (enable: boolean) => {
            setOpenConfirmDelete(false);
            if (!enable) {
                return;
            }

            const { groups } = formik.values;
            const selected = selectedGroup === 0 ? (groups.length === 1 ? null : 0) : selectedGroup - 1;
            setSelectedGroup(selected);
            groups.splice(selectedGroup, 1);
            formik.setFieldValue("groups", [...groups], true);
        },
        [formik, selectedGroup]
    );

    const handleDuplicate = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation();
            event.preventDefault();

            const { groups } = formik.values;
            const group: IGroup = cloneDeep(groups[selectedGroup]);
            const preffix = ` (${t("copy")})`;
            groups.push({
                ...group,
                groupName: `${group.groupName.replace(preffix, "")}${preffix}`,
            });
            formik.setFieldValue("groups", [...groups], true);
            setSelectedGroup(groups.length - 1);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [formik, selectedGroup]
    );

    const handleGroupClick = useCallback(
        (event: React.ChangeEvent<unknown>, value: number) => {
            event.stopPropagation();
            event.preventDefault();

            setSelectedGroup(value);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [formik.values.groups, selectedGroup, formik.errors]
    );

    const storeDataDictionary = async () => {
        const blobApiClient = new BlobApiClient(namespacedLocalStorage);
        const dictionaryTag = getDictionaryTag(product);
        const { groups } = formik.values;
        const response = await blobApiClient.setBlob(groups);
        await blobApiClient.assignTag(response.hash, dictionaryTag);
    };

    const renderLabel = ({ groupName, isFromUnersalSchema }: IGroup, isSelected: boolean) => {
        const name = groupName.trim();
        const displayName = name.length > MAX_TAB_NAME ? `${name.substring(0, MAX_TAB_NAME)}...` : name;

        if (isSelected) {
            return (
                <Grid container spacing={1} alignItems="center" wrap="nowrap">
                    {!isProd ? (
                        <>
                            <Grid item>
                                <IconButton color="primary" component="span" size="small" title={t("duplicateGroup")} onClick={handleDuplicate}>
                                    <FileCopy />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <IconButton
                                    color="primary"
                                    component="span"
                                    disabled={isFromUnersalSchema}
                                    size="small"
                                    title={t("deleteGroup")}
                                    onClick={() => setOpenConfirmDelete(true)}
                                >
                                    <Delete />
                                </IconButton>
                            </Grid>
                        </>
                    ) : null}

                    <Grid item>
                        <Typography color="textPrimary" variant="caption">
                            {displayName}
                        </Typography>
                    </Grid>
                </Grid>
            );
        } else {
            return displayName || t("name");
        }
    };

    // call methods from parent
    useImperativeHandle(ref, () => formik);

    // console.log("universalPolicyholderSchema", universalPolicyholderSchema);

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container style={{ marginTop: -24 }}>
                <Grid item sm={12} md={10} lg={8} xl={6}>
                    <Grid container alignItems="center" wrap="nowrap">
                        <Grid item xs={9}>
                            {groupNames.length ? (
                                <Tabs
                                    aria-label="tabs"
                                    className="sub"
                                    indicatorColor="primary"
                                    scrollButtons="auto"
                                    value={selectedGroup}
                                    variant="scrollable"
                                    onChange={handleGroupClick}
                                >
                                    {formik.values.groups.map((group, index) => (
                                        // Hide the "meta" tab
                                        group.groupName !== "meta"
                                            ? (<Tab
                                                className="sub-tab"
                                                label={renderLabel(group, index === selectedGroup)}
                                                key={`tab-${group.groupName}-${index}`}
                                            />) : null
                                    ))}
                                </Tabs>
                            ) : null}
                        </Grid>
                        <Grid item xs={3}>
                            {product.country && !isProd ? (
                                <Box textAlign="right">
                                    <Button endIcon={<Add />} variant="text" onClick={handleAddGroup}>
                                        {t("addGroup")}
                                    </Button>
                                </Box>
                            ) : null}
                        </Grid>
                    </Grid>
                    {!product.country ? (
                        <Grid item xs={12}>
                            <MessageBox message={t("Country required.")} theme={theme} variant="important" />
                        </Grid>
                    ) : null}
                    {product.country && !formik?.values?.groups ? (
                        <Grid container spacing={1} className={classes.subSection}>
                            <Preloader fullHeight label={true} theme={theme} />
                        </Grid>
                    ) : null}
                    {formik.values.groups?.length && !isNil(selectedGroup) ? (
                        <Grid container spacing={1} className={classes.subSection}>
                            <Overview formik={formik} selectedGroupIndex={selectedGroup || 0} countryLabel={countryLabel} />
                        </Grid>
                    ) : null}
                </Grid>
            </Grid>
            <ConfirmDialog
                message={t("rUSureDeleteGroup", { name: formik.values.groups?.[selectedGroup]?.groupName.toUpperCase() })}
                open={openConfirmDelete}
                onClose={handleDeleteGroup}
            />
            <ConfirmDialog
                message={t("sure2changeSystemId")}
                open={!!isSystemInternalID}
                onClose={() => dispatch(showSystemInternalID(false))}
            />
        </form>
    );
};

export default forwardRef(TabDataFields);
