import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useTranslationNamespaces } from '@blocksure/blocksure-core/dist/src/utilities';
import { i18n } from "@surelync/common";

const TableHeader = () => {
    const { t } = useTranslationNamespaces(i18n, ["surebyld", "surelync"]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={6}>
                <Typography align="center" component="p" variant="caption">
                    {t("name")}
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography align="center" component="p" variant="caption">
                    {t("amount")}
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography align="center" component="p" variant="caption">
                    {t("payer")}
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography align="center" component="p" variant="caption">
                    {t("payee")}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default TableHeader;
