import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import React from "react";
import { useTranslationNamespaces } from '@blocksure/blocksure-core/dist/src/utilities';
import { i18n } from "@surelync/common";

export interface ConfirmDialogProps {
    open: boolean;
    message: string;
    title?: string;
    onClose: (enable: boolean) => void;
}

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({ open, message, title, onClose }) => {
    const { t } = useTranslationNamespaces(i18n, ["surebyld", "surelync"]);

    const handleCancel = () => onClose(false);

    const handleOk = () => onClose(true);

    const handleClose = (event, reason) => {
        if (reason === "backdropClick" || reason === "escapeKeyDown") {
            return false;
        }
        handleCancel();
    };

    return (
        <Dialog maxWidth="sm" aria-labelledby="confirmation-dialog-title" open={open} onClose={handleClose}>
            {title ? <DialogTitle id="confirm-dialog-title">{title}</DialogTitle> : null}
            <DialogContent dividers>
                <Box m={3}>
                    <Typography variant="body1">{message}</Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="secondary">
                    {t("cancel")}
                </Button>
                <Button autoFocus onClick={handleOk} color="primary">
                    {t("ok")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
