import { EApprovalStatus, i18n, IProduct, IPrrovalable, namespacedLocalStorage } from "@surelync/common";
import { loadDraftProducts } from "../utils";
import { ProductApiClient } from "@blocksure/blocksure-core/dist/src/services/api-clients/";

export function handleUnderDevelopment(isProd: boolean, rowsPerPage: number, page: number): Promise<{total: number, products: IProduct[]}> {
    const productApiClient = new ProductApiClient(namespacedLocalStorage);
    return new Promise(async (resolve, reject) => {
        const draftProducts = !isProd ? loadDraftProducts() : [];
        const remoteProducts: IProduct[] = [];
        const rowsCount = rowsPerPage * page + rowsPerPage;
        const isMix = rowsPerPage * page < draftProducts.length;
        const requestCount = rowsCount - rowsPerPage * page - (isMix ? draftProducts.length : 0);
        const requestPage = Math.floor(draftProducts.length / rowsPerPage);
        const config = getDefaultConfig(true);
        // if table will be populate only with local Draft products
        // lets get remote products total count
        try {
            const response = await productApiClient.search(config, requestCount > 0 ? requestCount : 1, requestPage);
            // skip if we did fake request for getting total count
            if (requestCount > 0) {
                remoteProducts.push(...response.items);
            }
            const total = response.total + draftProducts.length;
            const products = [...draftProducts.slice(rowsPerPage * page), ...remoteProducts];
            resolve({ products, total });
        } catch (error) {
            reject(error);
        }
    })
}

export function getApprovalStatus(approvals: { [key: string]: IPrrovalable }, party: string): EApprovalStatus {
    const data = Object.values(approvals);

    if (!data.length) return;

    // Approved by all parties to go live
    if (data.filter((it) => it.status === EApprovalStatus.Approved).length === data.length) {
        return EApprovalStatus.Approved;

        // Awaiting approval by this organisation
    } else if (approvals[party].status === EApprovalStatus.Pending) {
        return EApprovalStatus.AwaitingYourApproval;

        // Changes have been rejected and need to be revised
    } else if (data.find((it) => it.status === EApprovalStatus.Rejected)) {
        return EApprovalStatus.Rejected;

        // Awaiting approval by counter-party
    } else {
        return EApprovalStatus.Pending;
    }
}

export function getDefaultConfig(pending = false) {
    return { "load.approvals": true, "load.roles": true, pending };
}

export const setDataProduct = (products: IProduct[], party: string, countries: { label: string, value: string }[], linkedEnv: string) =>
    products.map((item) => {
        const approvalStatus = item.state === "Draft" ? "NotRequested" : (getApprovalStatus(item.approvals, party) || "");
        const country = countries?.find(it => it.value === item.country)?.label || "";
        const domain = linkedEnv || "";
        const path = item.id ? `/surebyld/${item.id}` : `/surebyld/new/${item.idDraft}`;
        return {
            approvalStatus,
            country,
            id: item.id,
            lineOfBusiness: item.lineOfBusiness,
            link: `${domain}${path}`,
            localStorageKey: item.id || item.idDraft,
            name: item.id ? item.name : `${item.name || i18n.t("name")}`,
            owner: item.owner,
            status: item.state,
            ts: item.ts,
            partner: "",
            version: item.version, // used for Export action
        };
    });
