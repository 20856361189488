import React, { useContext } from "react";
import { Box, Button, Card, CardContent, CardHeader, Checkbox, Divider, FormControlLabel, Grid, IconButton, useTheme } from "@material-ui/core";
import { IFormik } from "../../../../models";
import { IDataDictionary } from "../../data-dictionary.model";
import { IQuestion, IQuestionWidget } from "../../group.model";
import get from "lodash/get";
import { i18n, InputCustom } from "@surelync/common";
import { useTranslationNamespaces } from '@blocksure/blocksure-core/dist/src/utilities';
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import useStyles from "../../../../styles";
import FooterQuestions from "./FooterQuestions";
import GlobalContext from "../../../../../context/global-context";

interface IProps {
    formik: IFormik<IDataDictionary>;
    property: string;
}

const Questions: React.FC<IProps> = ({ formik, property }) => {
    const { isProd } = useContext(GlobalContext);
    const classes = useStyles();
    const { t } = useTranslationNamespaces(i18n, ["surebyld", "surelync"]);
    const theme = useTheme();
    const questionsData: IQuestionWidget = get(formik.values, `${property}.properties`) || {};

    const handleAddQuestion = () => {
        const newQuestion = {
            answerNegative: "",
            answerPositive: "",
            isRequired: false,
            text: "",
        };
        if (questionsData && questionsData.properties) {
            questionsData.properties.push(newQuestion);
        } else {
            questionsData.properties = [newQuestion];
        }
        formik.setFieldValue(`${property}.properties`, questionsData, true);
    };

    const handleCheck = (property: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue(property, event.target.checked, true);
    };

    const handleRemoveQuestion = (index: number) => () => {
        questionsData.properties.splice(index, 1);
        formik.setFieldValue(`${property}.properties.properties`, [...questionsData.properties], true);
        if (questionsData.properties.length < 2) {
            formik.setFieldValue(`${property}.properties.isAnswerAll`, false, true);
        }
    };

    const getPropPath = (index: number, name: string) => `${property}.properties.properties[${index}].${name}`;

    return (
        <Grid item xs={12}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <InputCustom
                        autoComplete="off"
                        error={get(formik.errors, `${property}.properties.header`)}
                        label={t("quoteHeading")}
                        maxLength={80}
                        name={`${property}.properties.header`}
                        readOnly={isProd}
                        required
                        theme={theme}
                        touched={get(formik.touched, `${property}.properties.header`)}
                        value={questionsData?.header}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputCustom
                        autoComplete="off"
                        error={get(formik.errors, `${property}.properties.instructionQuote`)}
                        label={t("instructionQuote")}
                        maxLength={256}
                        name={`${property}.properties.instructionQuote`}
                        readOnly={isProd}
                        required
                        theme={theme}
                        touched={get(formik.touched, `${property}.properties.instructionQuote`)}
                        value={questionsData?.instructionQuote}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputCustom
                        autoComplete="off"
                        error={get(formik.errors, `${property}.properties.instructionMTA`)}
                        label={t("instructionMTA")}
                        name={`${property}.properties.instructionMTA`}
                        maxLength={256}
                        readOnly={isProd}
                        required
                        theme={theme}
                        touched={get(formik.touched, `${property}.properties.instructionMTA`)}
                        value={questionsData?.instructionMTA}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Card variant="outlined">
                        <CardHeader classes={{ root: classes.root, title: classes.title }} title={t("questions")} />
                        <CardContent>
                            {questionsData?.properties
                                ? (questionsData.properties as IQuestion[]).map((question: IQuestion, index: number, arr: IQuestion[]) => (
                                    <Grid container spacing={3} key={`question-${index}-${arr.length}`}>
                                        <Grid item xs={11}>
                                            <InputCustom
                                                autoComplete="off"
                                                error={get(formik.errors, getPropPath(index, "text"))}
                                                label={`${t("question")} ${index + 1}`}
                                                maxLength={256}
                                                name={getPropPath(index, "text")}
                                                readOnly={isProd}
                                                required
                                                theme={theme}
                                                touched={get(formik.touched, getPropPath(index, "text"))}
                                                value={question.text}
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={1}>
                                            {!isProd ? (
                                                <Box textAlign="right">
                                                    <IconButton
                                                        color="primary"
                                                        data-testid={`delete-${index}`}
                                                        size="small"
                                                        onClick={handleRemoveQuestion(index)}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Box>
                                            ) : null}
                                        </Grid>
                                        <Grid item xs={6}>
                                            <InputCustom
                                                autoComplete="off"
                                                error={get(formik.errors, getPropPath(index, "answerPositive"))}
                                                label={`${t("answerPositive")} ${index + 1}`}
                                                maxLength={30}
                                                name={getPropPath(index, "answerPositive")}
                                                readOnly={isProd}
                                                required
                                                theme={theme}
                                                touched={get(formik.touched, getPropPath(index, "answerPositive"))}
                                                value={question.answerPositive}
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={5}>
                                            <InputCustom
                                                autoComplete="off"
                                                error={get(formik.errors, getPropPath(index, "answerNegative"))}
                                                label={`${t("answerNegative")} ${index + 1}`}
                                                maxLength={30}
                                                name={getPropPath(index, "answerNegative")}
                                                readOnly={isProd}
                                                required
                                                theme={theme}
                                                touched={get(formik.touched, getPropPath(index, "answerNegative"))}
                                                value={question.answerNegative}
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={11} classes={{ root: classes.py0 }}>
                                            <FormControlLabel
                                                label={t("required")}
                                                control={
                                                    <Checkbox
                                                        checked={question.isRequired || false}
                                                        color={question.isRequired ? "primary" : "secondary"}
                                                        disabled={isProd}
                                                        onChange={handleCheck(getPropPath(index, "isRequired"))}
                                                    />
                                                }
                                            />
                                        </Grid>
                                        {questionsData.properties.length ? (
                                            <Grid item xs={12}>
                                                <Box mb={2}>
                                                    <Divider />
                                                </Box>
                                            </Grid>
                                        ) : null}
                                    </Grid>
                                ))
                                : null}
                            {!isProd ? (
                                <Box textAlign="right">
                                    <Button endIcon={<AddIcon />} variant="outlined" onClick={handleAddQuestion}>
                                        {t("addQuestion")}
                                    </Button>
                                </Box>
                            ) : null}
                        </CardContent>
                    </Card>
                </Grid>
                {questionsData?.properties && questionsData.properties.length > 1 ? (
                    <Grid item xs={12}>
                        <FormControlLabel
                            label={t("groupAnswer")}
                            control={
                                <Checkbox
                                    checked={questionsData.isAnswerAll || false}
                                    color={questionsData.isAnswerAll ? "primary" : "secondary"}
                                    disabled={isProd}
                                    onChange={(event) => formik.setFieldValue(`${property}.properties.isAnswerAll`, event.target.checked, true)}
                                />
                            }
                        />
                    </Grid>
                ) : null}
                {questionsData?.isAnswerAll ? (
                    <>
                        <Grid item xs={6}>
                            <InputCustom
                                autoComplete="off"
                                error={get(formik.errors, `${property}.properties.answerAllPositive`)}
                                label={t("answerPositive")}
                                name={`${property}.properties.answerAllPositive`}
                                maxLength={30}
                                readOnly={isProd}
                                required
                                theme={theme}
                                touched={get(formik.touched, `${property}.properties.answerAllPositive`)}
                                value={questionsData?.answerAllPositive}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <InputCustom
                                autoComplete="off"
                                error={get(formik.errors, `${property}.properties.answerAllNegative`)}
                                label={t("answerNegative")}
                                name={`${property}.properties.answerAllNegative`}
                                maxLength={30}
                                readOnly={isProd}
                                required
                                theme={theme}
                                touched={get(formik.touched, `${property}.properties.answerAllNegative`)}
                                value={questionsData?.answerAllNegative}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                            />
                        </Grid>
                    </>
                ) : null}
                <Grid item xs={12}>
                    <FooterQuestions
                        header={t("footerQuoteQuestions")}
                        formik={formik}
                        path={`${property}.properties.footer`}
                        propertyName="questionsQuote"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FooterQuestions
                        header={t("footerMTAQuestions")}
                        formik={formik}
                        path={`${property}.properties.footer`}
                        propertyName="questionsMTA"
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default React.memo(Questions);
