import { IAttachment } from "@surelync/common";

type IsSystemNameFn = (attachment: IAttachment, genericAttachments: { attachments: { dynamicDocumentTemplates: IAttachment[], hiddenAttachments: IAttachment[], staticAttachments: IAttachment[] }}) => boolean;
export const isSystemName: IsSystemNameFn = (attachment, genericAttachments) => {
    const { dynamicDocumentTemplates, staticAttachments } = genericAttachments.attachments;
    return [...dynamicDocumentTemplates, ...staticAttachments].map((it) => it.name).includes(attachment.name);
}

// Trim a file extension
export function getTemplateName(file: File): string {
    const { name, type } = file;
    return type === "text/html" || type === "text/plain" ? name.replace(/\.[^/.]+$/, "") : name;
}

const notificationSuffix = "Email";

export function getEmailName(file: File): string {
    const name = file.name.replace(/\.[^/.]+$/, "");
    return validNotificationName(name) ? name : `${name}${notificationSuffix}`;
}

export function validNotificationName(name: string): boolean {
    return name.endsWith(notificationSuffix);
}