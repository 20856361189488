import React from "react";

interface IProps {
    component: React.ReactElement;
    index: number;
    value: number;
}

const TabPanel: React.FC<IProps> = ({ component, value, index }) => (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`}>
        {value === index && component}
    </div>
);

export default TabPanel;
