import { INewFile, IProduct } from "@surelync/common";
import { IGroup } from "./components/TabDataFields/group.model";

export type TabType = { id: string; title: string };

enum ActionType {
    AdvancedMode,
    AutoSave,
    ConfirmUpdate,
    FetchStarted,
    FetchDone,
    FetchFailure,
    SetActiveTab,
    SetDataFields,
    SetEnvWarnModal,
    SetFiles,
    SetParties,
    SetTabs,
    SetTitle,
    ShowSystemInternalID,
    UpdateProduct,
}

interface IActionAdvancedMode {
    type: ActionType.AdvancedMode;
    payload: boolean;
}

interface IActionAutoSave {
    type: ActionType.AutoSave;
    payload: number | boolean;
}

interface IActionConfirmUpdate {
    type: ActionType.ConfirmUpdate;
    payload: boolean;
}

interface IActionFetchStarted {
    type: ActionType.FetchStarted;
}

interface IActionFetchDone {
    type: ActionType.FetchDone;
    payload: IProduct;
}

interface IActionFetchFailure {
    type: ActionType.FetchFailure;
    payload: string;
}

interface IActionSetActiveTab {
    type: ActionType.SetActiveTab;
    payload: number;
}

interface IActionSetDataFields {
    type: ActionType.SetDataFields;
    payload: IGroup[];
}

interface IActionSetEnvWarnModal {
    type: ActionType.SetEnvWarnModal;
    payload: boolean;
}

interface IActionSetFiles {
    type: ActionType.SetFiles;
    payload: { [key: string]: INewFile };
}

interface IActionSetParties {
    type: ActionType.SetParties;
    payload: string[];
}

interface IActionSetTabs {
    type: ActionType.SetTabs;
    payload: Array<TabType>;
}

interface IActionSetTitle {
    type: ActionType.SetTitle;
    payload: string;
}

interface IActionShowSystemInternalId {
    type: ActionType.ShowSystemInternalID;
    payload: boolean;
}

interface IActionUpdate {
    type: ActionType.UpdateProduct;
    payload: IProduct;
}

export type Action =
    | IActionAdvancedMode
    | IActionAutoSave
    | IActionConfirmUpdate
    | IActionSetActiveTab
    | IActionSetDataFields
    | IActionSetEnvWarnModal
    | IActionFetchDone
    | IActionFetchFailure
    | IActionFetchStarted
    | IActionSetFiles
    | IActionSetParties
    | IActionSetTabs
    | IActionSetTitle
    | IActionShowSystemInternalId
    | IActionUpdate;

export const advancedMode = (enable: boolean): IActionAdvancedMode => ({ type: ActionType.AdvancedMode, payload: enable });
export const autosave = (timestamp: number | boolean): IActionAutoSave => ({ type: ActionType.AutoSave, payload: timestamp });
export const request = (): IActionFetchStarted => ({ type: ActionType.FetchStarted });
export const setActiveTab = (value: number): IActionSetActiveTab => ({ type: ActionType.SetActiveTab, payload: value });
export const setDataFields = (data: IGroup[]): IActionSetDataFields => ({ type: ActionType.SetDataFields, payload: data });
export const setEnvWarnModal = (enable: boolean): IActionSetEnvWarnModal => ({ type: ActionType.SetEnvWarnModal, payload: enable });
export const setError = (message: string): IActionFetchFailure => ({ type: ActionType.FetchFailure, payload: message });
export const setFiles = (files: { [key: string]: INewFile }): IActionSetFiles => ({ type: ActionType.SetFiles, payload: files });
export const setParties = (parties: string[]): IActionSetParties => ({ type: ActionType.SetParties, payload: parties });
export const setTabs = (tabs: Array<TabType>): IActionSetTabs => ({ type: ActionType.SetTabs, payload: tabs });
export const setTitle = (title: string): IActionSetTitle => ({ type: ActionType.SetTitle, payload: title });
export const showConfirm = (enable: boolean): IActionConfirmUpdate => ({ type: ActionType.ConfirmUpdate, payload: enable });
export const showSystemInternalID = (enable: boolean): IActionShowSystemInternalId => ({ type: ActionType.ShowSystemInternalID, payload: enable });
export const success = (data: IProduct): IActionFetchDone => ({ type: ActionType.FetchDone, payload: data });
export const update = (values: IProduct): IActionUpdate => ({ type: ActionType.UpdateProduct, payload: values });

export type ProductState = {
    activeTab: number;
    autosave: number | boolean;
    data: IProduct;
    dataFields: IGroup[];
    error: string;
    files: { [key: string]: INewFile };
    isAdvancedMode: boolean;    // Data Fields: For advanced users: display field IDs (system internal IDs)
    isConfirm: boolean;
    isLoading: boolean;
    isSystemInternalID: boolean;
    isValid: boolean; // is Tab valid and can be changed
    openEnvWarnModal: boolean;
    parties: string[]; // fetch all available parties
    tabs: Array<TabType>;
    title: string;
};

export const initialState = {
    activeTab: 0,
    autosave: 0,
    data: null,
    dataFields: null,
    error: null,
    files: {},
    isAdvancedMode: false,
    isConfirm: false,
    isLoading: false,
    isSystemInternalID: false,
    isValid: true,
    openEnvWarnModal: false,
    parties: [],
    tabs: null,
    title: null,
};

export function reducer(state: ProductState = initialState, action: Action): ProductState {
    switch (action.type) {
        case ActionType.AdvancedMode:
            return { ...state, isAdvancedMode: action.payload };
        case ActionType.AutoSave:
            return { ...state, autosave: action.payload };
        case ActionType.ConfirmUpdate:
            return { ...state, isConfirm: action.payload };
        case ActionType.FetchStarted:
            return { ...state, isLoading: true, error: null };
        case ActionType.FetchDone:
            return { ...state, isLoading: false, data: action.payload };
        case ActionType.FetchFailure:
            return { ...state, isLoading: false, error: action.payload };
        case ActionType.SetActiveTab:
            return { ...state, activeTab: action.payload };
        case ActionType.SetDataFields:
            return { ...state, dataFields: action.payload };
        case ActionType.SetEnvWarnModal:
            return { ...state, openEnvWarnModal: action.payload };
        case ActionType.SetFiles:
            return { ...state, files: action.payload };
        case ActionType.SetParties:
            return { ...state, parties: action.payload };
        case ActionType.SetTabs:
            return { ...state, tabs: action.payload };
        case ActionType.SetTitle:
            return { ...state, title: action.payload };
        case ActionType.ShowSystemInternalID:
            return { ...state, isSystemInternalID: action.payload };
        case ActionType.UpdateProduct:
            return { ...state, data: action.payload };
        default:
            return state;
    }
}
