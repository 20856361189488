import React, { forwardRef, useCallback, useContext, useImperativeHandle, useMemo, useState } from "react";
import { useFormik } from "formik";
import { i18n, InputCustom, MessageBox } from "@surelync/common";
import { Box, Button, Card, CardContent, CardHeader, Divider, Hidden, Grid, Typography, useTheme } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { useTranslationNamespaces } from '@blocksure/blocksure-core/dist/src/utilities';
import GlobalContext from "../../../context/global-context";
import { UISchema, UISchemaField } from "./ui-shema.model";
import { DragDropContext } from "react-beautiful-dnd";
import { getPageFieldIndex, reorder } from "./helper";
import { validateUISchema } from "./validate-ui-schema";
import { FieldList, FieldProperties, PageFields } from "./components";
import { AdvancedModeCheckbox } from "../AdvancedModeCheckbox/AdvancedModeCheckbox";
import { ProductContext } from "../../contexts";
import get from "lodash/get";
import isNil from "lodash/isNil";
import useStyles from "../../styles";
import { getTags } from "../TabDataFields/helper";

export const FIELDS = "fields";
export const PAGE_FIELDS = "page-fields-";

type IProps = Record<string, unknown>;

const TabSureApp: React.ForwardRefRenderFunction<Record<string, unknown>, IProps> = (props, ref) => {
    const { isProd } = useContext(GlobalContext);
    const [{ dataFields }] = useContext(ProductContext);
    const [selectedFieldPath, setSelectedFieldPath] = useState<string>("");
    const { t } = useTranslationNamespaces(i18n, ["surebyld", "surelync"]);
    const classes = useStyles();
    const theme = useTheme();

    const FIELDS_LIST = useMemo(() => dataFields ? getTags(dataFields) : [], [dataFields]);

    const formik = useFormik<UISchema & { fields: UISchemaField[] }>({
        initialValues: {
            suffix: "",
            pages: [],
            types: [],
            fields: FIELDS_LIST
        },
        // enableReinitialize: true,
        validate: validateUISchema(t),
        onSubmit: (values) => {
            // console.log(values);
        },
    });

    const { values: { fields, pages, types }, setFieldValue } = formik;

    const handleAddPage = () => {
        setFieldValue("pages", [...pages, { title: "", fields: [] }]);
    }

    const handleAddFieldType = () => {
        // console.log("Add Field Type");
    }

    const handleDefault = (value: string, index?: number) => {
        if (isNil(index)) {
            setFieldValue(`${selectedFieldPath}.fieldDefault`, value);
        } else {
            setFieldValue(`${selectedFieldPath}.properties[${index}].fieldDefault`, value);
        }
    }

    const handleDragEnd = (event) => {

        const { source, destination } = event;

        // dropped outside the list
        if (!destination) {

            // Remove Field back from Pages to the List
            if (source.droppableId.startsWith(PAGE_FIELDS)) {
                const pageIndex = getPageFieldIndex(source.droppableId);
                const field = pages[pageIndex].fields.splice(source.index, 1);
                fields.push(field[0]);
                setFieldValue("fields", [...fields]);
            }

            // Reorder inside Fields List
        } else if (source.droppableId === destination.droppableId && source.droppableId === FIELDS) {

            const sortedItems = reorder(formik.values[source.droppableId], source.index, destination.index);
            setFieldValue(source.droppableId, sortedItems);


            // Reorder inside Page Fields
        } else if (source.droppableId === destination.droppableId && source.droppableId === PAGE_FIELDS) {

            const pageIndex = getPageFieldIndex(source.droppableId);
            const sortedItems = reorder(formik.values.pages[pageIndex].fields, source.index, destination.index);
            const pages = formik.values.pages[pageIndex].fields = sortedItems;
            setFieldValue("pages", [...pages]);

            // Add Field to the Page
        } else if (source.droppableId === FIELDS && destination.droppableId.startsWith(PAGE_FIELDS)) {
            const field = fields.splice(source.index, 1);
            const pageIndex = getPageFieldIndex(destination.droppableId);
            pages[pageIndex].fields.push(field[0]);
            setFieldValue("fields", [...fields]);
            setFieldValue("pages", [...pages]);

            // Remove Field back from Pages to the List
        } else if (source.droppableId.startsWith(PAGE_FIELDS) && destination.droppableId === FIELDS) {
            const pageIndex = getPageFieldIndex(source.droppableId);
            const field = pages[pageIndex].fields.splice(source.index, 1);
            fields.splice(destination.index, 0, field[0]);
            setFieldValue("fields", [...fields]);
            setFieldValue("pages", [...pages]);

            // Move Field from one Page to another Page
        } else if (source.droppableId.startsWith(PAGE_FIELDS) && destination.droppableId.startsWith(PAGE_FIELDS)) {
            const sourcePageIndex = getPageFieldIndex(source.droppableId);
            const destinationPageIndex = getPageFieldIndex(destination.droppableId);
            const field = pages[sourcePageIndex].fields.splice(source.index, 1);
            pages[destinationPageIndex].fields.push(field[0]);
            setFieldValue("pages", [...pages]);
        }
    };

    const handleHidden = (checked: boolean, index?: number) => {
        if (isNil(index)) {
            setFieldValue(`${selectedFieldPath}.fieldHidden`, checked);
        } else {
            setFieldValue(`${selectedFieldPath}.properties[${index}].fieldHidden`, checked);
        }
    }

    const handleFieldClick = useCallback((path: string) => {
        const newValue = path === selectedFieldPath ? "" : path;
        setSelectedFieldPath(newValue);
    }, [selectedFieldPath, formik.values]);

    // call methods from parent
    useImperativeHandle(ref, () => formik);

    if (!dataFields) {
        return (
            <Grid container spacing={3}>
                <Grid item xs={8} xl={6}>
                    <MessageBox message={t("Data Fields required.")} theme={theme} variant="important" />
                </Grid>
            </Grid>
        )
    }

    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <Hidden smDown>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={3}>
                        <Grid item xs={8} xl={6}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Card variant="outlined">
                                        <CardHeader classes={{ root: classes.root, title: classes.title }} title={t("SureApp URL Suffix")} />
                                        <CardContent>
                                            <Grid container spacing={3}>
                                                <Grid item xs={3}>
                                                    <InputCustom
                                                        autoComplete="off"
                                                        error={formik.errors.suffix as string}
                                                        label={t("SureApp URL Suffix")}
                                                        name="suffix"
                                                        readOnly={isProd}
                                                        required
                                                        theme={theme}
                                                        touched={!!formik.touched.suffix}
                                                        value={formik.values.suffix}
                                                        onBlur={formik.handleBlur}
                                                        onChange={formik.handleChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <Grid container alignItems="flex-end" classes={{ root: classes.fullHeight }}>
                                                        <Grid item xs>
                                                            <Typography component="p" variant="body1">{t("Choose what is shown at the end of the SureApp URL.")}</Typography>
                                                            <Typography component="p" variant="body1">{t("We recommend a short word, as shorter URLs are more elegant.")}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Card variant="outlined">
                                        <CardHeader classes={{ root: classes.root, title: classes.title }} title={t("SureApp pages for data entry")} />
                                        <CardContent>

                                            {!!pages.length && (
                                                <Grid container spacing={3}>
                                                    <Grid item xs={3} classes={{ root: classes.py0 }}>
                                                        <Typography paragraph variant="body2">{t("Page title")}</Typography>
                                                    </Grid>
                                                    <Grid item xs={9} classes={{ root: classes.py0 }}>
                                                        <Typography paragraph variant="body2">{t("Fields shown in SureApp page")}</Typography>
                                                    </Grid>
                                                </Grid>
                                            )}

                                            {pages.map((page, indexPage) => (
                                                <PageFields
                                                    divider={indexPage !== pages.length - 1}
                                                    errors={formik.errors}
                                                    index={indexPage}
                                                    key={`page-fields-${indexPage}`}
                                                    page={page}
                                                    readOnly={isProd}
                                                    selectedFieldPath={selectedFieldPath}
                                                    touched={formik.touched}
                                                    onBlur={formik.handleBlur}
                                                    onChange={formik.handleChange}
                                                    onFieldClick={handleFieldClick}
                                                />
                                            ))}

                                            {!isProd ? (
                                                <>
                                                    <br />
                                                    {!!pages.length && <Divider />}
                                                    <Box textAlign="right" mt={1}>
                                                        <Button color="primary" endIcon={<Add />} variant="text" onClick={handleAddPage}>
                                                            {t("add")}
                                                        </Button>
                                                    </Box>
                                                </>
                                            ) : null}

                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Card variant="outlined">
                                        <CardHeader classes={{ root: classes.root, title: classes.title }} title={t("SureApp Quote / Policy /  Renewal Page")} />
                                        <CardContent>
                                            {/* <Grid container spacing={3}>

                                            </Grid> */}
                                            {!isProd ? (
                                                <>
                                                    <br />
                                                    {!!types.length && <Divider />}
                                                    <Box textAlign="right" mt={1}>
                                                        <Button
                                                            color="primary"
                                                            endIcon={<Add />}
                                                            variant="text"
                                                            onClick={handleAddFieldType}
                                                        >
                                                            {t("add")}
                                                        </Button>
                                                    </Box>
                                                </>
                                            ) : null}
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12}>
                                    <Hidden xsUp={!pages.length}>
                                        <AdvancedModeCheckbox />
                                    </Hidden>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Hidden xsUp={!pages.length}>
                            {selectedFieldPath
                                ? <FieldProperties
                                    error={get(formik.errors, selectedFieldPath)}
                                    field={get(formik.values, selectedFieldPath)}
                                    readOnly={isProd}
                                    onClose={() => setSelectedFieldPath("")}
                                    onDefault={handleDefault}
                                    onHidden={handleHidden}
                                />
                                : <FieldList data={fields} />}
                        </Hidden>

                        <Grid item xs={12} />
                    </Grid>
                </form>
            </Hidden>
            <Hidden mdUp>
                <MessageBox message={t("This feature needs a wider screen")} theme={theme} variant="important" />
            </Hidden>
        </DragDropContext>
    );
};

export default forwardRef(TabSureApp);
