import { i18n } from "@surelync/common";

const validatePayment = (product) => {
    const paymentValues = Object.keys(product.payment);
    const errorKeys = {};
    const values = paymentValues.map((value) => {
        if (typeof product.payment[value] === 'object') {
            return product.payment[value];
        }
        return null;
    }).filter(Boolean);
    const allPaymentValues = values.reduce((acc, val) => ({...acc, ...val}));
    const allPaymentValuesKeys = Object.keys(allPaymentValues);
    
    allPaymentValuesKeys.forEach((key) => {
        if (!allPaymentValues[key].payer) {
            errorKeys[`${key}.payer`] = i18n.t("required");
        }
        if (!allPaymentValues[key].payee) {
            errorKeys[`${key}.payee`] = i18n.t("required");
        }
    })
    return errorKeys;
}

export function validateFeesAndRatings(values) {
    const errors: { [key: string]: string | Array<{ [key: string]: string }> } = {};
    const paymentErrors = validatePayment(values);
    return {...errors, ...paymentErrors};
}

