import React from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppSharpIcon from '@material-ui/icons/GetAppSharp';
import PublishIcon from '@material-ui/icons/Publish';
import { useTheme } from "@material-ui/core/styles";
import { useTranslationNamespaces } from '@blocksure/blocksure-core/dist/src/utilities';
import { i18n, IAttachment, INewFile, InputCustom } from "@surelync/common";
import useStyles from "../../styles";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import DocumentIcon from "./DocumentIcon";
import { downloadFile } from "../../../utils";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

interface IProps {
    acceptFiles?: string[];
    attachment: IAttachment;
    disabled?: boolean;
    error?: string | { name: string; file: string };
    files: { [key: string]: INewFile };
    href: string;
    isAttached: boolean;
    isCanBeAttached: boolean;
    name: string;
    margin?: "dense";
    readOnly?: boolean;
    touched?: boolean;
    type: "Email" | "Document" | "Rating" | "Other";
    value: string;
    onCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onFileChange: (e: React.ChangeEvent<any>) => void;
    onRemove: () => void;
}

const Document: React.FC<IProps> = ({
    acceptFiles,
    attachment,
    disabled,
    error,
    href,
    files,
    isAttached,
    isCanBeAttached,
    name,
    margin,
    readOnly,
    touched,
    type,
    value,
    onCheckboxChange,
    onFileChange,
    onRemove,
}) => {
    const theme = useTheme();
    const { t } = useTranslationNamespaces(i18n, ["surebyld", "surelync"]);
    const classes = useStyles();

    const inValidFile = touched && isFileNotUploaded(attachment) && !files[attachment?.location];
    const isNewFile = attachment?.blobHash || files[attachment?.location];
    const browserObjectURL = files[attachment?.location]?.url;

    return (
        <Grid container spacing={3} wrap="nowrap" alignItems="center" className={classes.wrapper}>
            <div className={isNewFile ? classes.lightBG : ""}></div>
            <Grid item className={classes.iconWrapper}>
                <DocumentIcon contentType={attachment.contentType} type={type} />
            </Grid>
            <Grid item xs classes={{ root: classes[margin] }} className={classes.wrapper}>
                <InputCustom
                    disabled={disabled}
                    name={name}
                    readOnly={true}
                    theme={theme}
                    value={value}
                />
                <span className={classes.helpIcon} title={t("sensibleFilename")}>
                    <HelpOutlineIcon fontSize="inherit" />
                </span>
            </Grid>
            <Grid item xs={3} classes={{ root: classes[margin] }} className={classes.z0}>
                <FormControlLabel
                    label={type === "Email" ? t("enable") : t("attachToEmail")}
                    control={
                        <Checkbox
                            checked={isAttached}
                            classes={{ root: classes.noPadding }}
                            color={isAttached ? "primary" : "secondary"}
                            disabled={readOnly || !isCanBeAttached}
                            inputProps={{ "data-testid": `checkbox-document-${name}` } as any}
                            onChange={onCheckboxChange}
                        />
                    }
                />
            </Grid>
            {!readOnly ? (
                <Grid item xs={3} classes={{ root: classes[margin] }}>
                    <Button
                        classes={{
                            label: classes.labelRight
                        }}
                        component="label"
                        disabled={disabled}
                        endIcon={<PublishIcon />}
                        fullWidth
                        style={
                            inValidFile
                                ? {
                                    borderColor: theme.palette.error.main,
                                    color: theme.palette.error.main,
                                }
                                : null
                        }
                        variant="text"
                    >
                        {isFileNotUploaded(attachment) ? (
                            t("upload")
                        ) : (
                            t("replaceFile")
                        )}
                        <input
                            accept={acceptFiles?.join(",")}
                            data-testid="input-file"
                            type="file"
                            style={{ display: "none" }}
                            onChange={onFileChange}
                        />
                    </Button>
                    {inValidFile ? (
                        <Typography color="error" variant="caption">
                            {t("required")}
                        </Typography>
                    ) : null}
                </Grid>
            ) : null}

            <Grid item classes={{ root: classes[margin] }}>
                <IconButton
                    color="primary"
                    disabled={disabled || isFileNotUploaded(attachment)}
                    size="small"
                    onClick={() => downloadFile(browserObjectURL || href, attachment.name)}
                >
                    <GetAppSharpIcon />
                </IconButton>&nbsp;
                {!readOnly ? (
                    <IconButton color="primary" data-testid={`delete-${name}`} disabled={disabled} size="small" onClick={onRemove}>
                        <DeleteIcon />
                    </IconButton>
                ) : null}
            </Grid>
        </Grid>
    );
};

export default Document;

function isFileNotUploaded(attachment: IAttachment) {
    return !attachment?.location; // || attachment.location.startsWith("local_");
}
