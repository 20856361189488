export const optionsFrequency = (t) => {
    return [
        {
            label: t("minute"),
            value: "S",
        },
        {
            label: t("hour"),
            value: "H",
        },
        {
            label: t("day"),
            value: "D",
        },
        {
            label: t("week"),
            value: "W",
        },
        {
            label: t("month"),
            value: "M",
        },
        {
            label: t("year"),
            value: "Y",
        },
    ];
}
