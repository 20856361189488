import * as React from "react";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import LinkButton from "@material-ui/core/Link";
import { Link, useLocation } from "react-router-dom";
import { useTranslationNamespaces } from '@blocksure/blocksure-core/dist/src/utilities';
import { i18n } from "@surelync/common";

interface IBackButtonProps {
    title?: string; // can be Product name
}

export const BackButton: React.FC<IBackButtonProps> = ({ title }) => {
    const location = useLocation<{ from: string; backBtnlabel: string; redirectTo: string }>();
    const { t } = useTranslationNamespaces(i18n, ["surebyld", "surelync"]);

    const renderBackButton = () => {
        const backBtnlabel = location?.state?.backBtnlabel;

        if (backBtnlabel) {
            return backBtnlabel;
        } else if (title) {
            return `${t("backTo")} ${title}`;
        } else {
            return t("back");
        }
    };

    const redirectTo = () => {
        const redirectTo = location?.state?.redirectTo || location?.state?.from;

        // open direct link
        if (redirectTo) {
            return redirectTo;
        } else if (location.pathname.includes("/surebyld")) {
            return "/surebyld";
        } else {
            return "";
        }
    };

    return (
        <LinkButton component={Link} to={redirectTo()} color="primary" variant="body2">
            <ArrowBackIosIcon fontSize="small" style={{ verticalAlign: "middle" }} />
            {renderBackButton()}
        </LinkButton>
    );
};
