export interface IQuestion {
    answerNegative: string;
    answerPositive: string;
    isRequired: boolean;
    text: string;
}

export interface IFooterQuestions {
    questionsQuote: string[];
    questionsMTA: string[];
}

export enum EWidgets {
    EnumWidget = "EnumWidget",
    QuestionWidget = "QuestionWidget",
    StringWidget = "StringWidget",
}

export interface IQuestionWidget {
    answerAllNegative: string;
    answerAllPositive: string;
    isAnswerAll: boolean;
    footer: IFooterQuestions;
    header: string;
    instructionQuote: string;
    instructionMTA: string;
    properties: IQuestion[];
    type: EWidgets.QuestionWidget;
}

export type FieldFormats = "alt-date" | "array" | "array-sum" | "boolean" | "currency" | "email" | "image-array" | "multiline" | "password";
export type FieldTypes = "string" | "integer";

export interface IWidgetBase {
    fieldDefault?: string;   // TODO add different types array, ... etc.
    fieldHidden?: boolean;
    fieldFormat?: FieldFormats;
    fieldName: string;
    fieldType: FieldTypes;
    fieldSystemInternalId: string;
    isFromUnersalSchema: boolean;
    isRequired: boolean;
    type: EWidgets;
}

export interface IEnumWidget extends IWidgetBase {
    enum: string[];
    enumNames?: string[];
    type: EWidgets.EnumWidget;
}

export interface IStringWidget extends IWidgetBase {
    fieldSize: number;
    type: EWidgets.StringWidget;
}

export enum GroupType {
    AdditionalDictionary = "additionalDictionary",
    Address = "address",
    Personal = "personal",
    Questions = "questions",
}

export interface IGroup {
    groupName: string;
    groupSystemInternalId: string;
    isFromUnersalSchema: boolean;
    properties?: IEnumWidget[] | IStringWidget[] | IQuestionWidget;
    type: GroupType;
}
