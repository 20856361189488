import React, { useContext } from "react";
import { i18n } from "@surelync/common";
import { useTranslationNamespaces } from '@blocksure/blocksure-core/dist/src/utilities';
import { Box, Link as LinkButton, Typography } from "@material-ui/core";
import GlobalContext from "../../context/global-context";

const EnvMessage: React.FC = () => {
    const { linkedEnv } = useContext(GlobalContext);
    const { t } = useTranslationNamespaces(i18n, ["surebyld", "surelync"]);

    return (
        <Box mt={3}>
            <Typography paragraph variant="body1">{t("productReadonlyEnv")}</Typography>
            <Typography paragraph variant="body1">
                {t("forEditLoginTo")}&nbsp;
                <LinkButton target="_blank" href={linkedEnv} rel="noopener">
                    {linkedEnv}
                </LinkButton>
            </Typography>
        </Box>
    );
};

export default React.memo(EnvMessage);
