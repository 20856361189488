import React, { useContext } from "react";
import { Card, CardContent, CardHeader, Grid, Typography, useTheme } from "@material-ui/core";
import useStyles from "../../../styles";
import { useTranslationNamespaces } from '@blocksure/blocksure-core/dist/src/utilities';
import { i18n, InputCustom, IProduct } from "@surelync/common";
import TableHeader from "./TableHeader";
import TableRow from "./TableRow";
import { IFormik } from "../../../models";
import GlobalContext from "../../../../context/global-context";
import { handleChangeNumber } from "../utils";

interface IProps {
    formik: IFormik<IProduct>;
}

const MTAFeePayees: React.FC<IProps> = ({ formik }) => {
    const { isProd } = useContext(GlobalContext);
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslationNamespaces(i18n, ["surebyld", "surelync"]);

    return (
        <Grid item sm={12}>
            <Card variant="outlined">
                <CardHeader classes={{ root: classes.root, title: classes.title }} title={t("mtaFeePayees")} />
                <CardContent>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={6}>
                            <Typography align="right" variant="body1">
                                {t("mtaFee")}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <InputCustom
                                autoComplete="off"
                                error={formik.errors.mtaFee as string}
                                name="payment.mtaFee"
                                readOnly={isProd}
                                theme={theme}
                                touched={!!formik.touched.payment?.mtaFee}
                                value={formik.values.payment.mtaFee}
                                onBlur={formik.handleBlur}
                                onChange={handleChangeNumber("payment.mtaFee", formik)}
                            />
                        </Grid>
                        <Grid item xs={4} />
                        <Grid item xs={12}>
                            <Typography align="left" variant="body1">
                                {t("amoutColumnCanBe")}
                            </Typography>
                        </Grid>
                        <TableHeader />
                        <TableRow formik={formik} name={t("platformAdjustmentFee")} property="payment.mtaFeePayees.Platform Adjustment Fee" />
                        <TableRow formik={formik} name={t("brokerAdjustmentFee")} property="payment.mtaFeePayees.Broker Adjustment Fee" />
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
};

export default MTAFeePayees;
