import React, { useContext } from "react";
import { Grid, useTheme } from "@material-ui/core";
import { useTranslationNamespaces } from '@blocksure/blocksure-core/dist/src/utilities';
import { i18n, IProduct, InputCustom } from "@surelync/common";
import { IFormik } from "../../../models";
import GlobalContext from "../../../../context/global-context";

interface IProps {
    formik: IFormik<IProduct>;
    selectedSection: string;
}

const Overview: React.FC<IProps> = ({ formik, selectedSection }) => {
    const { isProd } = useContext(GlobalContext);
    const theme = useTheme();
    const { t } = useTranslationNamespaces(i18n, ["surebyld", "surelync"]);

    return (
        <Grid item xs={12}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <InputCustom
                        autoComplete="off"
                        data-testid="section-name"
                        error={formik.errors.sections?.[selectedSection].name}
                        label={`${t("section")} ${t("name")}*`}
                        name={`sections.${selectedSection}.name`}
                        readOnly={isProd}
                        theme={theme}
                        touched={formik.touched.sections?.[selectedSection]?.name}
                        value={formik.values.sections?.[selectedSection].name}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Overview;
