import * as React from "react";
import { useEffect, useReducer } from "react";
import GlobalContext from "./global-context";
import { defaultState, globalReducer, SET_CLIENT_LOGO, SET_CURRENT_USER, SET_GENERIC_ATTACHMENTS, SET_LINKED_ENV, SET_LOCALE } from "./reducers";
import { i18n, IUser, localeService, namespacedLocalStorage, signInService } from "@surelync/common";
import * as FormatUtils from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";
import { BlobApiClient, ProductApiClient } from "@blocksure/blocksure-core/dist/src/services/api-clients";

type IProps = Record<string, unknown>;

const GlobalState: React.FC<IProps> = ({ children }) => {
    const [globalState, dispatch] = useReducer(globalReducer, defaultState);
    const blobApiClient = new BlobApiClient(namespacedLocalStorage);
    const productApiClient = new ProductApiClient(namespacedLocalStorage);

    useEffect(() => {
        localeService.i18nLoader$.subscribe(() => {
            if (!localeService.i18nReady) return;
            const { locale } = localeService;
            dispatch({ type: SET_LOCALE, payload: locale });
            FormatUtils.setGlobalLocale(locale);
            i18n.loadNamespaces("surebyld");
        });

        const currentUser: IUser = {
            authDetails: signInService.loadAuthDetails(),
            userDetails: signInService.loadUserDetails(),
        };

        dispatch({
            type: SET_CURRENT_USER,
            payload: currentUser,
        });
    }, []);

    useEffect(() => {
        let isMount = true;

        try {
            const getClientLogo = async () => {
                const blob = await blobApiClient.retrievedTagGlobal("client-logo");
                if (!isMount) return;
                dispatch({ type: SET_CLIENT_LOGO, payload: blob });
            };

            getClientLogo();
        } catch (error) {
            // console.error(error);
        }

        return () => {
            isMount = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let isMount = true;

        try {
            const getLinkedEnv = async () => {
                const response = await productApiClient.env();
                // Because (alex|denis|...) are copy of DEV with linked env to "dev.testbroker.co"
                // DEV has linked env "dev.testbroker.co", so set empty value
                const linkedEnv = location.hostname === response.linkedEnv ? "" : response.linkedEnv
                if (!isMount) return;
                dispatch({ type: SET_LINKED_ENV, payload: `https://${linkedEnv}/surelync` });
            };

            getLinkedEnv();
        } catch (error) {
            // console.error(error);
        }

        return () => {
            isMount = false;
        };
    }, []);

    useEffect(() => {
        let isMount = true;

        try {
            const getGenericAttachments = async () => {
                const { party } = signInService.loadAuthDetails();
                const country = FormatUtils.formatPartyCountry(party);
                const { attachments, files, notifications } = await productApiClient.getGenericAttachments(country);
                if (!isMount) return;
                dispatch({ type: SET_GENERIC_ATTACHMENTS, payload: { attachments, files, notifications } });
            };

            getGenericAttachments();
        } catch (error) {
            console.error(error);
        }

        return () => {
            isMount = false;
        };
    }, []);

    const isI18Ready = globalState.locale;

    return (
        <GlobalContext.Provider
            value={{
                ...globalState,
                dispatchGlobal: dispatch,
            }}
        >
            {isI18Ready && children}
        </GlobalContext.Provider>
    );
};

export default GlobalState;
