import React, { forwardRef, Fragment, useCallback, useContext, useEffect, useImperativeHandle, useMemo } from "react";
import { useFormik } from "formik";
import { DELAY_AUTO_SAVE, ETab, ProductContext } from "../../contexts";
import { autosave, update } from "../../reducer";
import Grid from "@material-ui/core/Grid";
import { AutocompleteCustom, i18n, InputCustom, IProduct } from "@surelync/common";
import { Box, Button, Card, CardContent, CardHeader, Checkbox, Divider, FormControlLabel, IconButton, useTheme } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { useTranslationNamespaces } from '@blocksure/blocksure-core/dist/src/utilities';
import useStyles from "../../styles";
import DeleteIcon from "@material-ui/icons/Delete";
import { storeProduct } from "../../../utils";
import { validateCancellations } from "./validate-cancellations";
import GlobalContext from "../../../context/global-context";

type IProps = Record<string, unknown>;

const TabCancellation: React.ForwardRefRenderFunction<Record<string, unknown>, IProps> = (props, ref) => {
    const { isProd, namespacedLocalStorage } = useContext(GlobalContext);
    const [{ data }, dispatch] = useContext(ProductContext);
    const { t } = useTranslationNamespaces(i18n, ["surebyld", "surelync"]);
    const classes = useStyles();
    const theme = useTheme();

    const prepareData = useCallback((values: IProduct): IProduct => {
        const product = { ...values };
        return product;
    }, []);

    const formik = useFormik<IProduct>({
        initialValues: {
            ...data,
        },
        enableReinitialize: true,
        validate: validateCancellations,
        onSubmit: (values) => {
            const product = prepareData(values);
            dispatch(update(product));
        },
    });

    // AutoSave
    useEffect(() => {
        if (!formik.dirty) return;

        const timerId = setTimeout(() => {
            const params = {
                activeTab: ETab.Cancellation,
                product: prepareData(formik.values),
            };
            storeProduct(params);
            dispatch(autosave(Date.now()));
        }, DELAY_AUTO_SAVE);

        return () => clearTimeout(timerId);
    }, [formik.dirty, formik.values, dispatch, namespacedLocalStorage, prepareData]);

    const handleAdd = (propertyName: string) => () => {
        const property = formik.values.cancellation[propertyName];
        formik.setFieldValue(`cancellation.${propertyName}`, [...property, ""]);
    };

    const isSelectedAllParties = Object.keys(formik.values.partiesAndRoles).length === formik.values.cancellation.authorisedParties?.length;

    const optionsParties = useMemo(() => {
        const authorisedParties = formik.values.cancellation.authorisedParties;
        const disable = (party: string) => (authorisedParties ? authorisedParties.indexOf(party) !== -1 : false);

        return Object.entries(formik.values.partiesAndRoles).map(([name, party]: [string, string]) => ({
            label: name,
            value: party,
            disabled: disable(party),
        }));
    }, [formik.values.partiesAndRoles, formik.values.cancellation.authorisedParties]);

    const handleChangeCancelType = (type: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;
        const types = formik.values.cancellation.cancelTypes;
        if (checked) {
            formik.setFieldValue("cancellation.cancelTypes", [...types, type]);
        } else {
            formik.setFieldValue("cancellation.cancelTypes", types.filter((it: string) => it !== type));
        }
    }

    const handleRemove = (propertyName: string, index: number) => () => {
        const property = formik.values.cancellation[propertyName];
        property.splice(index, 1);
        formik.setFieldValue(`cancellation.${propertyName}`, [...property]);
    };

    const isCancelType = (type: string) => formik.values.cancellation.cancelTypes.includes(type);

    // call methods from parent
    useImperativeHandle(ref, () => formik);

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container>
                <Grid item sm={12} md={10} lg={8} xl={6}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Card variant="outlined">
                                <CardHeader classes={{ root: classes.root, title: classes.title }} title={t("reasons")} />
                                <CardContent>
                                    <Grid container spacing={3}>
                                        {formik.values.cancellation.reasons.map((reason: string, idx: number) => (
                                            <Fragment key={`reason-${idx}`}>
                                                <Grid item xs={11} classes={{ root: classes.py0 }}>
                                                    <InputCustom
                                                        autoComplete="off"
                                                        error={formik.errors.cancellation?.reasons?.[idx]}
                                                        name={`cancellation.reasons[${idx}]`}
                                                        readOnly={isProd}
                                                        theme={theme}
                                                        touched={formik.touched.cancellation?.reasons?.[idx]}
                                                        value={reason}
                                                        onBlur={() => formik.setFieldTouched(`cancellation.reasons[${idx}]`)}
                                                        onChange={formik.handleChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={1} classes={{ root: classes.py0 }}>
                                                    {!isProd ? (
                                                        <IconButton
                                                            color="primary"
                                                            data-testid={`delete-reason-${idx}`}
                                                            size="small"
                                                            onClick={handleRemove("reasons", idx)}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    ) : null}
                                                </Grid>
                                            </Fragment>
                                        ))}
                                    </Grid>
                                    {!isProd ? (
                                        <>
                                            <br />
                                            <Divider />
                                            <Box textAlign="right" mt={1}>
                                                <Button color="primary" endIcon={<Add />} variant="text" onClick={handleAdd("reasons")}>
                                                    {t("addReason")}
                                                </Button>
                                            </Box>
                                        </>
                                    ) : null}
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Card variant="outlined">
                                <CardHeader classes={{ root: classes.root, title: classes.title }} title={t("authorisedParties")} />
                                <CardContent>
                                    <Grid container spacing={3}>
                                        {formik.values.cancellation.authorisedParties.map((party: string, idx: number) => (
                                            <Fragment key={`party-&{idx}`}>
                                                <Grid item xs={5} classes={{ root: classes.py0 }}>
                                                    <AutocompleteCustom
                                                        disabled={isProd}
                                                        error={formik.errors.cancellation?.authorisedParties?.[idx]}
                                                        getLabel={false}
                                                        name={`cancellation.authorisedParties[${idx}]`}
                                                        options={optionsParties}
                                                        theme={theme}
                                                        touched={formik.touched.cancellation?.authorisedParties?.[idx]}
                                                        value={optionsParties.find((it) => it.value === party) || null}
                                                        fullWidth
                                                        onChange={(event, value) => formik.setFieldValue(`cancellation.authorisedParties[${idx}]`, value)}
                                                    />
                                                </Grid>
                                                <Grid item xs={1} classes={{ root: classes.py0 }}>
                                                    {!isProd ? (
                                                        <IconButton
                                                            color="primary"
                                                            data-testid={`delete-party-${idx}`}
                                                            size="small"
                                                            onClick={handleRemove("authorisedParties", idx)}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    ) : null}
                                                </Grid>
                                                <Grid item xs={6} />
                                            </Fragment>
                                        ))}
                                    </Grid>
                                    {!isProd ? (
                                        <>
                                            <br />
                                            <Divider />
                                            <Box textAlign="right" mt={1}>
                                                <Button
                                                    color="primary"
                                                    endIcon={<Add />}
                                                    disabled={isSelectedAllParties}
                                                    variant="text"
                                                    onClick={handleAdd("authorisedParties")}
                                                >
                                                    {t("addAuthoriser")}
                                                </Button>
                                            </Box>
                                        </>
                                    ) : null}
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12}>
                            <Card variant="outlined">
                                <CardHeader classes={{ root: classes.root, title: classes.title }} title={t("allowedCancellationTypes")} />
                                <CardContent>
                                    <Grid container spacing={3}>
                                        <Grid item>
                                            <FormControlLabel
                                                label={t("inception")}
                                                control={
                                                    <Checkbox
                                                        checked={isCancelType("Inception")}
                                                        color={isCancelType("Inception") ? "primary" : "secondary"}
                                                        data-testid="type-inception"
                                                        disabled={isProd}
                                                        onChange={handleChangeCancelType("Inception")}
                                                    />
                                                }
                                            />
                                        </Grid>
                                        <Grid item>
                                            <FormControlLabel
                                                label={t("custom")}
                                                control={
                                                    <Checkbox
                                                        checked={isCancelType("Custom")}
                                                        color={isCancelType("Custom") ? "primary" : "secondary"}
                                                        data-testid="type-custom"
                                                        disabled={isProd}
                                                        onChange={handleChangeCancelType("Custom")}
                                                    />
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

export default forwardRef(TabCancellation);
