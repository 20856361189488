import React, { Fragment, useContext } from "react";
import { Box, Button, Card, CardContent, CardHeader, Divider, Grid, IconButton, useTheme } from "@material-ui/core";
import { useTranslationNamespaces } from '@blocksure/blocksure-core/dist/src/utilities';
import { i18n, InputCustom } from "@surelync/common";
import get from "lodash/get";
import { IFormik } from "../../../../models";
import { IDataDictionary } from "../../data-dictionary.model";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import useStyles from "../../../../styles";
import GlobalContext from "../../../../../context/global-context";

interface IProps {
    header: string;
    formik: IFormik<IDataDictionary>;
    path: string;
    propertyName: string;
}

const FooterQuestions: React.FC<IProps> = ({ header, formik, path, propertyName }) => {
    const { isProd } = useContext(GlobalContext);
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslationNamespaces(i18n, ["surebyld", "surelync"]);
    const questions = get(formik.values, `${path}.${propertyName}`) || [];

    const handleAdd = () => {
        formik.setFieldValue(`${path}.${propertyName}`, [...questions, ""], true);
    };

    const handleRemove = (index: number) => () => {
        questions.splice(index, 1);
        formik.setFieldValue(`${path}.${propertyName}`, [...questions], true);
    };

    return (
        <Card variant="outlined">
            <CardHeader classes={{ root: classes.root, title: classes.title }} title={header} />
            <CardContent>
                <Grid container spacing={3} alignItems="center">
                    {questions.map((item, index, arr) => (
                        <Fragment key={`question-${index}-${arr.length}`}>
                            <Grid item xs={11}>
                                <InputCustom
                                    autoComplete="off"
                                    error={get(formik.errors, `${path}.${propertyName}[${index}]`)}
                                    label={`${t("question")} ${index + 1}`}
                                    maxLength={256}
                                    name={`${path}.${propertyName}[${index}]`}
                                    readOnly={isProd}
                                    required
                                    theme={theme}
                                    touched={get(formik.touched, `${path}.${propertyName}[${index}]`)}
                                    value={item}
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                {!isProd ? (
                                    <Box textAlign="right">
                                        <IconButton
                                            color="primary"
                                            data-testid={`delete-footer-question-${index}`}
                                            size="small"
                                            onClick={handleRemove(index)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Box>
                                ) : null}
                            </Grid>
                        </Fragment>
                    ))}
                    {!isProd ? (
                        <>
                            {questions.length ? (
                                <Grid item xs={12}>
                                    <Divider />
                                </Grid>
                            ) : null}
                            <Grid item xs={12}>
                                <Box textAlign="right">
                                    <Button endIcon={<AddIcon />} variant="outlined" onClick={handleAdd}>
                                        {t("addQuestion")}
                                    </Button>
                                </Box>
                            </Grid>
                        </>
                    ) : null}
                </Grid>
            </CardContent>
        </Card>
    );
};

export default React.memo(FooterQuestions);
