import React, { Fragment, useContext } from "react";
import { Box, Button, Card, CardContent, CardHeader, Grid, IconButton, Typography, useTheme } from "@material-ui/core";
import { useTranslationNamespaces } from '@blocksure/blocksure-core/dist/src/utilities';
import { i18n, InputCustom, IProduct } from "@surelync/common";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import clsx from "clsx";
import { IFormik } from "../../../models";
import useStyles from "../../../styles";
import GlobalContext from "../../../../context/global-context";

interface IProps {
    formik: IFormik<IProduct>;
}

const PartiesAndRoles: React.FC<IProps> = ({ formik }) => {
    const { isProd } = useContext(GlobalContext);
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslationNamespaces(i18n, ["surebyld", "surelync"]);

    const partiesAndRoles = Object.entries(formik.values.partiesAndRoles).map(([name, party]: [string, string]) => ({ name, party }));

    const handleAdd = () => {
        const result = {};
        partiesAndRoles.push({ name: "", party: "" });
        partiesAndRoles.forEach((it) => (result[it.name] = it.party));
        formik.setFieldValue("partiesAndRoles", result);
    };

    const handleChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name: property, value } = event.currentTarget;
        const result = {};

        // if 'Insurer' already exists you can not add 'Insurer2'
        const newName = (name: string) => (result[name] ? `${name}_` : name);

        partiesAndRoles[index][property] = value;
        partiesAndRoles.forEach((it) => (result[newName(it.name)] = it.party));
        formik.setFieldValue("partiesAndRoles", result);
        formik.setFieldTouched("partiesAndRoles", true, true);
    };

    const handleRemove = (index: number) => () => {
        const result = {};
        partiesAndRoles.forEach((it, idx) => (idx !== index ? (result[it.name] = it.party) : null));
        formik.setFieldValue("partiesAndRoles", result);
        formik.setFieldTouched("partiesAndRoles", true, true);
    };

    const getError = (index: number, property: string): string | null => {
        const { partiesAndRoles } = formik.errors;
        return partiesAndRoles && partiesAndRoles[index] ? partiesAndRoles[index][property] : null;
    };

    const renderAddButton = () => {
        return (
            <Box textAlign="right">
                <Button endIcon={<AddIcon />} variant="text" onClick={handleAdd}>
                    {t("addParty")}
                </Button>
            </Box>
        );
    };

    return (
        <Grid item sm={12}>
            <Card variant="outlined">
                <CardHeader
                    classes={{
                        root: clsx(classes.root, formik.errors.partiesAndRoles ? classes.error : null),
                        title: classes.title,
                    }}
                    title={t("partiesAndRoles")}
                />
                <CardContent>
                    <Grid container spacing={3}>
                        {formik.errors.partiesAndRoles && !Array.isArray(formik.errors.partiesAndRoles) ? (
                            <Grid item xs={4} classes={{ root: classes.py0 }}>
                                <Typography color="error" variant="body2">
                                    {formik.errors.partiesAndRoles}
                                </Typography>
                            </Grid>
                        ) : null}

                        {partiesAndRoles.map((partyRole, index) => (
                            <Fragment key={`${index}`}>
                                <Grid item xs={3} classes={{ root: classes.py0 }}>
                                    <InputCustom
                                        autoComplete="off"
                                        error={getError(index, "name")}
                                        name="name"
                                        readOnly={isProd}
                                        theme={theme}
                                        touched={true}
                                        value={partyRole.name}
                                        onBlur={handleChange(index)}
                                        onChange={handleChange(index)}
                                    />
                                </Grid>
                                <Grid item xs={5} classes={{ root: classes.py0 }}>
                                    <InputCustom
                                        autoComplete="off"
                                        error={getError(index, "party")}
                                        name="party"
                                        readOnly={isProd}
                                        theme={theme}
                                        touched={true}
                                        value={partyRole.party}
                                        onBlur={handleChange(index)}
                                        onChange={handleChange(index)}
                                    />
                                </Grid>
                                <Grid item xs={1} classes={{ root: classes.py0 }}>
                                    {!isProd ? (
                                        <IconButton
                                            color="primary"
                                            data-testid={`delete-${partyRole.name}`}
                                            size="small"
                                            onClick={handleRemove(index)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    ) : null}
                                </Grid>

                                <Grid item xs={3} classes={{ root: classes.py0 }}>
                                    {!isProd && index === 0 ? renderAddButton() : null}
                                </Grid>
                            </Fragment>
                        ))}
                        {!isProd && partiesAndRoles.length === 0 ? (
                            <Grid item xs={12} classes={{ root: classes.py0 }}>
                                {renderAddButton()}
                            </Grid>
                        ) : null}
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
};

export default PartiesAndRoles;
