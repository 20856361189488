import React, { useCallback, useContext, useEffect, useReducer, useRef, useState } from "react";
import { Box, Button, Grid, Snackbar, Tab, Tabs, Typography, useTheme } from "@material-ui/core";
import { i18n, getCountries, IProduct, localeService, MessageBox, useParties, PageContainer } from "@surelync/common";
import { ProductApiClient } from "@blocksure/blocksure-core/dist/src/services/api-clients/";
import { useTranslationNamespaces } from '@blocksure/blocksure-core/dist/src/utilities';
import {generateErrorMessage} from '@blocksure/blocksure-core/dist/src/utilities/ErrorHandler';
import GlobalContext from "../context/global-context";
import { BackButton } from "../components";
import { BannerClientLogo } from "../components/BannerClientLogo/BannerClientLogo";
import { useHistory, useParams } from "react-router-dom";
import {
    autosave,
    initialState,
    reducer,
    request,
    setActiveTab,
    setEnvWarnModal,
    setError,
    setFiles,
    setParties,
    setTabs,
    setTitle,
    showConfirm,
    success,
    TabType
} from "./reducer";
import { AUTO_SAVE_KEY, ETab, ProductContext, TABS } from "./contexts";
import { DateTime } from "luxon";
import { ConfirmDialog } from "./components/ConfirmDialog/ConfirmDialog";
import cloneDeep from "lodash/cloneDeep";
import { TabCancellation, TabDataFields, TabDocuments, TabFeesAndRatings, TabPanel, TabSettings } from "./components";
import TabSections from "./components/TabSections/TabSections";
import { Description, Save } from "@material-ui/icons";
import { prepareAttachments, processFiles } from "../utils/helper";
import * as FormatUtils from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";
import { getDefaultProduct } from "./models/default-product.model";
import { loadDraftProduct, validateRenewals } from "../utils";
import { v4 as uuidv4 } from "uuid";
import EnvWarnDialog from "../components/EnvWarnDialog/EnvWarnDialog";
import ErrorsTabsDialog from "../components/ErrorsTabsDialog/ErrorsTabsDialog";
import TabSureApp from "./components/TabSureApp/TabSureApp";

const _formatDate = (date, format) => {
    if (!date) return '--';
    return date ? FormatUtils.renderFormatDate(date, format) : '';
};

const EditProductPage: React.FC = () => {
    const { currentUser, isProd, namespacedLocalStorage, genericAttachments, productForCopy } = useContext(GlobalContext);
    const { id, version } = useParams<{ id: string; version: string }>();
    const history = useHistory();
    const formRef = useRef(null);
    const [state, dispatch] = useReducer(reducer, initialState);
    const parties = useParties(namespacedLocalStorage);
    const theme = useTheme();
    const { t } = useTranslationNamespaces(i18n, ["surebyld", "surelync"]);
    const { activeTab, data: product, error, isConfirm, isLoading, openEnvWarnModal, tabs, title } = state;

    // do not need these values at the reducer for pass to the child components.
    const [errorsTabs, setErrorsTabs] = useState<Array<string[]>>(null);
    const [showAutoSave, setShowAutoSave] = useState(false);

    useEffect(() => {
        if (!id) {
            return;
        }
        const title = id !== "new" ? t("product") : t("createProduct");
        dispatch(setTitle(title));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    useEffect(() => {
        if (!parties) return;
        dispatch(setParties(parties));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parties]);

    useEffect(() => {
        if (!id || !genericAttachments) {
            return;
        }
        let isMount = true;
        const fetchData = async () => {
            const autosaveData = loadDraftProduct(id !== "new" ? id : version);
            const productApiClient = new ProductApiClient(namespacedLocalStorage);
            const tabs: TabType[] = TABS.map((it) => ({ id: it, title: t(it) }));

            dispatch(request());
            try {
                let product: IProduct;

                if (id === "copy") {

                    // If page was reloaded - temporary product will be lost
                    if (!productForCopy) {
                        history.push("/surebyld");
                        return;
                    }
                    productForCopy.name += ` - ${t("copy")}`;
                    product = productForCopy;
                    dispatch(setTitle(`${t("product")} - ${product.name}`));

                } else if (id !== "new") {

                    try {
                        product = await productApiClient.getProduct(id, false, version);
                        if (!isMount) return;
                    } catch (e) {
                        // throw e;
                    }

                    // If it is Temporary product and don't exists at the DB
                    if (autosaveData && !product) {
                        product = autosaveData.product;

                    // Check does remote Product has new version?
                    // If yes - we could continue modify it locally.
                    } else if (autosaveData) {
                        if (autosaveData.product.version === product.version) {
                            product = autosaveData.product;
                            handleTabChange(null, TABS.indexOf(autosaveData.activeTab));
                            setTimeout(() => dispatch(autosave(true)), 500);
                        } else {
                            const dateStr = _formatDate(product.lastEditedAt, 'MMMM dd yyyy');
                            const timeStr = _formatDate(product.lastEditedAt, 'HH:MM');
                            const { lastEditedBy } = product;
                            const params = { lastEditedBy, timeStr, dateStr };
                            const confirmed = window.confirm(`${t("versionsClash1", params)} \n\n${t("versionsClash2", params)}`);
                            if (confirmed) {
                                namespacedLocalStorage.removeItem(`${AUTO_SAVE_KEY}_${product.id}`);
                            }
                        }
                    }
                    dispatch(setTitle(`${t("product")} - ${product.name}`));

                    // Open new Product for editting
                } else if (autosaveData) {
                    product = autosaveData.product;
                    handleTabChange(null, TABS.indexOf(autosaveData.activeTab));
                    setTimeout(() => dispatch(autosave(true)), 500);

                } else if (version) {
                    history.push("/surebyld/404");

                    // Create New Product flow
                } else {
                    const { party } = currentUser.authDetails;
                    const country = FormatUtils.formatPartyCountry(party);
                    const countries = await getCountries();
                    const language = localeService.getLanguage();
                    const countryOption = countries.find((it) => it.value === country);
                    const currency = countryOption ? countryOption.currency : "";

                    product = getDefaultProduct(t);
                    product.country = country;
                    product.language = language;
                    product = { ...product, ...cloneDeep(genericAttachments.attachments), notifications: [...genericAttachments.notifications] };
                    const cachedFiles = await processFiles(product, [...genericAttachments.files]);
                    dispatch(setFiles({ ...cachedFiles }));
                    if (currency) {
                        product.payment.currency = currency;
                    }
                }

                dispatch(setTabs(tabs));
                dispatch(success(product));
            } catch (error) {
                if (!isMount) return;
                dispatch(setError(generateErrorMessage(error)));
                console.warn("error", error.message);
            }
        };

        fetchData();

        return () => {
            isMount = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, version, genericAttachments]);

    useEffect(() => {
        if (!state.autosave) {
            return;
        }
        setShowAutoSave(true);
    }, [state.autosave]);

    // The "Update" button must not be visible on the Sections tab when zero sections are defined
    const isSectionsNotEmpty = activeTab !== TABS.indexOf(ETab.Sections) || formRef.current?.values.sectionDisplayOrder?.length;
    const isVisibleUpdateBtn = !isProd && isSectionsNotEmpty;

    const handleTabChange = useCallback((event: React.ChangeEvent<unknown>, value: number) => {

        if (!formRef.current) {
            dispatch(setActiveTab(value));
            return;
        }
        formRef.current.submitForm();

        if (formRef.current.isValid) {
            dispatch(setActiveTab(value));
        }
    }, [activeTab, namespacedLocalStorage]);

    const handleUpdate = useCallback(() => {
        if (formRef.current.dirty) {
            formRef.current.submitForm();
        }

        if (!formRef.current.isValid) {
            return;
        }

        // Renewals -- validation of Summary tab vs Documents tab
        const errorsForTabs = validateRenewals(formRef.current.values, t);
        if (errorsForTabs) {
            setErrorsTabs(errorsForTabs);
            return;
        }

        dispatch(showConfirm(true));
    }, []);

    const handleConfirm = async (isConfirm: boolean) => {
        dispatch(showConfirm(false));

        if (!isConfirm) {
            return;
        }

        const newProduct: IProduct = cloneDeep(product);
        const { dynamicDocumentTemplates, hiddenAttachments, staticAttachments } = newProduct;

        if (!newProduct.id) {
            newProduct.id = uuidv4(); // `prd_${uuidv4()}`;
            newProduct.state = "New";
            newProduct.created = DateTime.utc().toISO();
            newProduct.ts = newProduct.created;                      // !!! FIXME
            newProduct.notary = "C=GB,L=London,O=BlocksureNotary";   // !!! FIXME
            newProduct.blocksureParty = "C=GB,L=London,O=Blocksure"; // !!! FIXME
            newProduct.paymentGateway = "C=GB,L=London,O=Blocksure,PG=Stripe";     // !!! FIXME
        } else {
            newProduct.state = "New";
        }

        delete newProduct.idDraft;
        newProduct.dynamicDocumentTemplates = await prepareAttachments(dynamicDocumentTemplates, state.files);
        newProduct.staticAttachments = await prepareAttachments(staticAttachments, state.files);
        newProduct.hiddenAttachments = await prepareAttachments(hiddenAttachments, state.files);

        dispatch(request());
        try {
            const productApiClient = new ProductApiClient(namespacedLocalStorage);
            const response = await productApiClient.createUpdateProduct(newProduct, Object.values(state.files));
            dispatch(success(response));
            namespacedLocalStorage.removeItem(`${AUTO_SAVE_KEY}_${newProduct.id || newProduct.idDraft}`);
            history.push(`/surebyld/${response.id}`);
        } catch (err) {
            dispatch(setError(generateErrorMessage(err)));
        }
    };

    return (
        <ProductContext.Provider value={[state, dispatch]}>
            <PageContainer fetching={isLoading} theme={theme} title={title}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Grid container alignItems="flex-end">
                            <Grid item xs={12} sm={8} md={5}>
                                <Box mb={1}>
                                    <BackButton />
                                </Box>
                                <Typography variant="h5">{title}</Typography>
                                {!["copy", "new"].includes(id) ? (
                                    <Typography color="textPrimary" variant="body2">
                                        {`${t("id")} - ${id}`}
                                    </Typography>
                                ) : null}
                            </Grid>
                            <Grid item xs={12} sm={4} md={2}>
                                <Box textAlign={{ xs: "right", md: "center" }}>
                                    <BannerClientLogo />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={8} md={5} />
                        </Grid>
                    </Grid>

                    {product ? (
                        <Grid item xs={12}>
                            <Box mb={2}>
                                <Tabs
                                    aria-label="tabs"
                                    indicatorColor="primary"
                                    scrollButtons="auto"
                                    value={activeTab}
                                    variant="scrollable"
                                    onChange={handleTabChange}
                                >
                                    {tabs && tabs.map((option) => <Tab label={option.title} key={option.id} />)}
                                </Tabs>
                            </Box>
                        </Grid>
                    ) : null}

                    {error && (
                        <Grid item xs={12} md={8} data-testid="product-error">
                            <MessageBox message={error} theme={theme} variant="error" onClose={() => dispatch(setError(null))} />
                        </Grid>
                    )}

                    {product ? (
                        <>
                            <Grid item xs={12}>
                                <TabPanel value={activeTab} index={TABS.indexOf(ETab.Settings)} component={<TabSettings ref={formRef} />} />
                                <TabPanel value={activeTab} index={TABS.indexOf(ETab.FeesAndRatings)} component={<TabFeesAndRatings ref={formRef} />} />
                                <TabPanel value={activeTab} index={TABS.indexOf(ETab.Documents)} component={<TabDocuments ref={formRef} />} />
                                <TabPanel value={activeTab} index={TABS.indexOf(ETab.Cancellation)} component={<TabCancellation ref={formRef} />} />
                                <TabPanel value={activeTab} index={TABS.indexOf(ETab.Sections)} component={<TabSections ref={formRef} />} />
                                <TabPanel value={activeTab} index={TABS.indexOf(ETab.DataFields)} component={<TabDataFields ref={formRef} />} />
                                <TabPanel value={activeTab} index={TABS.indexOf(ETab.SureApp)} component={<TabSureApp ref={formRef} />} />
                            </Grid>

                            <Grid item sm={12} md={10} lg={8} xl={6}>
                                <Box textAlign="right">
                                    {isProd ? (
                                        <Button color="primary" variant="outlined" onClick={() => dispatch(setEnvWarnModal(true))}>
                                            {t("edit")}
                                        </Button>
                                    ) : null}
                                    {isVisibleUpdateBtn ? (
                                        <Button color="primary" variant="contained" onClick={handleUpdate}>
                                            {product.id ? t("update") : t("createProduct")}
                                        </Button>
                                    ) : null}

                                </Box>
                            </Grid>
                        </>
                    ) : null}
                </Grid>
            </PageContainer>
            <ConfirmDialog message={`${t("changesPending")} ${t("sure2updateProduct")}`} open={isConfirm} onClose={handleConfirm} />
            <EnvWarnDialog open={openEnvWarnModal} onClose={() => dispatch(setEnvWarnModal(false))} />
            <ErrorsTabsDialog open={Boolean(errorsTabs?.length)} messages={errorsTabs?.[0]} onClose={() => setErrorsTabs(null)} />
            <Snackbar
                open={showAutoSave}
                autoHideDuration={3000}
                message={state.autosave === true ? `${t("lastSaved", { time: FormatUtils.renderDateTime(state.data?.ts) })}` : t("autosaved")}
                action={state.autosave === true ? <Description fontSize="small" /> : <Save fontSize="small" />}
                onClose={() => setShowAutoSave(false)}
            />
        </ProductContext.Provider>
    );
};

export default EditProductPage;
