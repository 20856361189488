import React from 'react';
import { Chip } from '@material-ui/core';

interface Props {
    label: string;
    selected?: boolean;
    tooltip: string;
    onClick?: () => void;
}

export const Tag: React.FC<Props> = React.memo(({ label, selected, tooltip, onClick }) => {
    return (
        <Chip
            clickable
            color="primary"
            label={label}
            size="small"
            title={tooltip}
            variant={selected ? "default" : "outlined"}
            onClick={onClick}
        />
    );
});
