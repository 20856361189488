import React, { Fragment, useCallback, useContext, useMemo } from "react";
import { Box, Button, Card, CardContent, CardHeader, Divider, Grid, IconButton, useTheme } from "@material-ui/core";
import { useTranslationNamespaces } from '@blocksure/blocksure-core/dist/src/utilities';
import { i18n, IProduct, AutocompleteCustom } from "@surelync/common";
import { IFormik } from "../../../models";
import DeleteIcon from "@material-ui/icons/Delete";
import { Add } from "@material-ui/icons";
import GlobalContext from "../../../../context/global-context";
import useStyles from "../../../styles";
import get from "lodash/get";

interface IProps {
    formik: IFormik<IProduct>;
    selectedSection: string;
}

const VisibleTo: React.FC<IProps> = ({ formik, selectedSection }) => {
    const { isProd } = useContext(GlobalContext);
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslationNamespaces(i18n, ["surebyld", "surelync"]);

    const handleAddVisibleTo = () => {
        const { parties } = formik.values.sections[selectedSection];
        const newParties = parties ? [...parties, ""] : [""];
        formik.setFieldValue(`sections.${selectedSection}.parties`, newParties);
    };

    const handleChangeVisibleTo = useCallback((index) => (event: React.ChangeEvent, value: string) => {
        event.preventDefault();
        formik.setFieldValue(`sections.${selectedSection}.parties[${index}]`, value);
    }, [selectedSection, formik.values.sections]);

    const handleRemoveVisible = (index: number) => () => {
        const { parties } = formik.values.sections[selectedSection];

        parties.splice(index, 1);
        formik.setFieldValue(`sections.${selectedSection}.parties`, [...parties]);
    };

    const optionsParties = useMemo(() => {
        const { parties } = formik.values.sections[selectedSection];
        const disable = (party: string) => (parties ? parties.indexOf(party) !== -1 : false);

        return Object.entries(formik.values.partiesAndRoles).map(([name, party]: [string, string]) => ({
            label: name,
            value: party,
            disabled: disable(party),
        }));
    }, [selectedSection, formik.values.partiesAndRoles, formik.values.sections]);

    const isSelectedAllParties = Object.keys(formik.values.partiesAndRoles).length === formik.values.sections?.[selectedSection]?.parties?.length;

    return (
        <Grid item sm={12}>
            <Card variant="outlined">
                <CardHeader classes={{ root: classes.root, title: classes.title }} title={t("visibleTo")} />
                <CardContent>
                    <Grid container spacing={3}>
                        {formik.values.sections?.[selectedSection]?.parties?.map((party, index) => (
                            <Fragment key={`party-${index}`}>
                                <Grid item xs={5} classes={{ root: classes.py0 }}>
                                    <AutocompleteCustom
                                        disabled={isProd}
                                        error={get(formik.errors, `sections.${selectedSection}.parties[${index}]`)}
                                        getLabel={false}
                                        name="name"
                                        options={optionsParties}
                                        theme={theme}
                                        touched={get(formik.touched, `sections.${selectedSection}.parties[${index}]`)}
                                        value={optionsParties.find((it) => it.value === party) || null}
                                        fullWidth
                                        onChange={handleChangeVisibleTo(index)}
                                    />
                                </Grid>
                                <Grid item xs={1} classes={{ root: classes.py0 }}>
                                    {!isProd ? (
                                        <IconButton
                                            color="primary"
                                            data-testid={`delete-party-${index}`}
                                            size="small"
                                            onClick={handleRemoveVisible(index)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    ) : null}
                                </Grid>
                                <Grid item xs={6} />
                            </Fragment>
                        ))}
                    </Grid>
                    {!isProd ? (
                        <>
                            <br />
                            <Divider />
                            <Box textAlign="right" mt={1}>
                                <Button
                                    color="primary"
                                    disabled={isSelectedAllParties}
                                    endIcon={<Add />}
                                    variant="text"
                                    onClick={handleAddVisibleTo}
                                >
                                    {t("newVisible")}
                                </Button>
                            </Box>
                        </>
                    ) : null}
                </CardContent>
            </Card>
        </Grid>
    );
};

export default React.memo(VisibleTo);
