import { i18n, IProduct } from "@surelync/common";
import { JSONSchema7 } from "json-schema";
import { showSystemInternalID } from "../../reducer";
import { EWidgets, GroupType, IEnumWidget, IStringWidget, IGroup, FieldFormats, FieldTypes } from "./group.model";
import camelCase from "lodash/camelCase";
import { IFormik } from "../../models";
import { IDataDictionary } from "./data-dictionary.model";

const personalAlterNames = ["policyholder", "aboutYou", "yourDetails", "moreAbout"];

export function getDictionaryTag(product: IProduct): string {
    // BlobApi Tag must be lowercase and must be all alphanumeric or one of [., -].
    // e.g. that return error [product-data-dictionary-prd_seaHhh7TzcizYZwyJ3k5H-draft-version-8]
    const { id, idDraft, version } = product;
    const storeKey = id || idDraft || "";
    const productId = storeKey.toString().replace("prd_", "prd-").toLowerCase();
    return `product-data-dictionary-${productId}-draft-version-${version}`;
}

export function getNotManadatoryGroups(def: Record<string, any>): IGroup[] {
    const prefixes = new Map<GroupType, boolean>();
    Object.keys(def).forEach((path) => {
        const isFromUnersalSchema = !!personalAlterNames.find((prefix) => path.startsWith(`${prefix}.`));
        if (!isFromUnersalSchema) {
            const newPrefix = path.split(".")[0] as GroupType;
            prefixes.set(newPrefix, true);
        }
    });

    return Array.from(prefixes).map(([prefix]) => prefix).map((prefix) => {

        const properties = [];
        Object.entries(def).forEach(([path, defField]) => {
            if (path.startsWith(`${prefix}.`)) {
                const property = path.replace(`${prefix}.`, "");
                // TODO isRequired do not exist in def.json.
                // Use submissionSchema.json for it
                const newField = FactoryWidget(defField, property, false, false);
                properties.push(newField);
            }
        })

        return {
            isFromUnersalSchema: false,
            groupName: prefix,
            groupSystemInternalId: prefix,
            properties: properties as any,
            type: GroupType.AdditionalDictionary,
        };
    });
}

export function getUniversalFields(universalPolicyholderSchema: JSONSchema7, def: Record<string, any>, names: string[]): IGroup {

    let result: IGroup | null;
    const prefixes = new Map<string, boolean>();
    const processedFields = [];

    names.forEach((property: GroupType) => {
        const group = universalPolicyholderSchema.properties[property] as JSONSchema7;
        const entries = Object.entries(group.properties);
        const properties = entries.map(([name, obj]: [string, JSONSchema7]) => {
            const { prefix, value: defField }: any = getFieldFromDefJSON(def, property, name) || {};
            const field = { ...obj, ...defField };
            if (prefix) {
                prefixes.set(prefix, true);
                processedFields.push(name);
            }
            return FactoryWidget(field, getSystemInternalId(property, name), group.required.includes(name), true)
        });

        if (!result) {
            const groupSystemInternalId = prefixes.size ? Array.from(prefixes)[0][0] : property;
            result = {
                isFromUnersalSchema: true,
                groupName: def[groupSystemInternalId]?.title || property,
                groupSystemInternalId,
                properties: properties as any,
                type: property
            };
        } else {
            (result.properties as any).push(...properties);
        }
    });

    // Add all unprocessed fields from def.json as Adittional into the Group
    Object.entries(def).forEach(([path, defField]) => {
        prefixes.forEach((value, prefix) => {
            if (path.startsWith(`${prefix}.`) && !path.replace(`${prefix}.`, "").includes(".")) {
                const processed = processedFields.find((property) => path === `${prefix}.${property}`);
                if (!processed) {
                    const name = path.replace(`${prefix}.`, "");
                    const field = FactoryWidget(defField, name, false, false);
                    (result.properties as any).push(field);
                }
            }
        })
    });
    return result;
}

export type DefJSONSchema7 = JSONSchema7 & { enumNames: string[], size: number };
function FactoryWidget(field: DefJSONSchema7, systemInternalId: string, isReguired: boolean, isFromUnersalSchema: boolean): IStringWidget | IEnumWidget {

    if (field.enum) {
        return {
            fieldDefault: <string> field.default,
            enum: <string[]> field.enum,
            enumNames: field.enumNames,
            fieldFormat: <FieldFormats> field.format,
            fieldName: field.title || i18n.t(systemInternalId),
            fieldSystemInternalId: systemInternalId,
            fieldType: <FieldTypes> field.type,
            isFromUnersalSchema,
            isRequired: isReguired,
            type: EWidgets.EnumWidget
        }
    } else {
        return {
            fieldDefault: <string> field.default,
            fieldFormat: <FieldFormats> field.format,
            fieldName: field.title  || i18n.t(systemInternalId),
            fieldSystemInternalId: systemInternalId,
            fieldSize: field.size || 10,
            fieldType: <FieldTypes> field.type,
            isFromUnersalSchema,
            isRequired: isReguired,
            type: EWidgets.StringWidget,
        }
    }
}

function getFieldFromDefJSON(def: JSONSchema7, property: GroupType, name: string) {
    let result = null;
    personalAlterNames.forEach((prefix) => {
        if (result) {
            return;
        }
        if (def[`${prefix}.${property}.${name}`]) {
            result = {
                prefix: `${prefix}.${property}`,
                value: def[`${prefix}.${property}.${name}`]
            }
        } else if (def[`${prefix}.${name}`]) {
            result = {
                prefix: `${prefix}`,
                value: def[`${prefix}.${name}`]
            }
        }
    });
    return result;
}

export const optionsType = [
    { label: "boolean", value: "boolean" },
    { label: "date", value: "alt-date" },
    { label: "email", value: "email" },
    { label: "integer", value: "integer" },
    { label: "text", value: "string" },
    { label: "phone", value: "string" },
]

export const isReadOnly = (isProd: boolean, section: IEnumWidget | IStringWidget): boolean => (isProd || section.isFromUnersalSchema);

export const handleFocus = (value: string, touched: boolean, dispatch) => () => {
    if (value && !touched) {
        dispatch(showSystemInternalID(true));
    }
};

export const getCountry = (countries: {value: string, label: string}[], key: string): string => countries?.find(country => country.value === key)?.label || "";

// Data blocks that should not be split up. This property should be set for Addresses and Questions,
// but not person records as these data entry fields can be separated across different data entry pages.
export function getTags(groups: IGroup[]) {
    const result = [];
    groups.forEach(group => {
        if (group.type === GroupType.Address || group.type === GroupType.Questions) {
            result.push(group);
        } else {
            const properties = (group.properties as any)?.properties || group.properties;
            result.push(...properties);
        }
    });
    return result;
}

export function autoGenerateSystemId(formik: IFormik<IDataDictionary>, property: string, systemId: string, name: string ) {
    if (!systemId && name) {
        const value = camelCase(name.replace(/[^a-z ]/gi, ""));
        formik.setFieldValue(property, value, true);
    }
}

function getSystemInternalId(groupName: GroupType, fieldName: string): string {
    if (["personal", "contact"].includes(groupName)) {
        return `policyholder.person.${fieldName}`;
    }

    return fieldName;
}
