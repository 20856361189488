import React, { useCallback, useContext } from "react";
import { Box, Button, Grid } from "@material-ui/core";
import { useTranslationNamespaces } from '@blocksure/blocksure-core/dist/src/utilities';
import { i18n } from "@surelync/common";
import { IFormik } from "../../../../models";
import Add from "@material-ui/icons/Add";
import get from "lodash/get";
import { IDataDictionary } from "../../data-dictionary.model";
import { EWidgets, IEnumWidget, IQuestionWidget, IStringWidget } from "../../group.model";
import GlobalContext from "../../../../../context/global-context";
import StringWidgets from "../StringWidgets/StringWidgets";
import EnumWidgets from "../EnumWidgets/EnumWidgets";
import { ProductContext } from "../../../../contexts";
import { autoGenerateSystemId } from "../../helper";

interface IProps {
    countryLabel: string;
    formik: IFormik<IDataDictionary>;
    property: string;
}

const DataFields: React.FC<IProps> = ({ countryLabel, formik, property }) => {
    const { isProd } = useContext(GlobalContext);
    const [{ isAdvancedMode }] = useContext(ProductContext);
    const { t } = useTranslationNamespaces(i18n, ["surebyld", "surelync"]);

    const group = get(formik.values, property);
    const getPath = (index: number) => `${property}.properties[${index}]`;

    const handleAdd = (event: React.MouseEvent) => {
        const newProperty: IStringWidget = {
            isFromUnersalSchema: false,
            fieldName: "",
            fieldSize: 10,
            fieldType: "string",
            fieldSystemInternalId: "",
            isRequired: true,
            type: EWidgets.StringWidget,
        };
        const properties = group.properties ? [...group.properties, newProperty] : [newProperty];
        formik.setFieldValue(`${property}.properties`, properties, true);
    };

    const handleAutoGenerateSystemID = useCallback((index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const property = `${getPath(index)}.fieldSystemInternalId`;
        const { fieldName, fieldSystemInternalId } = group.properties[index];
        formik.handleBlur(event);
        autoGenerateSystemId(formik, property, fieldSystemInternalId, fieldName);
    }, [formik, group])

    const handleChangeDropdown = useCallback((index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue(`${getPath(index)}.enum`, event.target.checked ? [""] : null, true);
        formik.setFieldValue(`${getPath(index)}.type`, event.target.checked ? EWidgets.EnumWidget : EWidgets.StringWidget, true);
        if (group.properties[index].enumNames) {
            formik.setFieldValue(`${getPath(index)}.enumNames`, event.target.checked ? [] : null, true);
        }
    }, [group]);

    const handleRemove = useCallback((index: number) => () => {
        group.properties.splice(index, 1);
        formik.setFieldValue(property, group, true);
    }, [group]);

    const handleChangeRequired = useCallback((index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue(`${getPath(index)}.isRequired`, event.target.checked, true);
    }, [group]);

    const renderWidgets = () => {
        if (Array.isArray(group.properties)) {
            return (group.properties as any).map((section: IStringWidget | IEnumWidget | IQuestionWidget, index: number) => {
                switch (section.type) {
                    case EWidgets.EnumWidget:
                        return (
                            <EnumWidgets
                                countryLabel={countryLabel}
                                isAdvancedMode={isAdvancedMode}
                                formik={formik}
                                key={index}
                                section={section}
                                property={`${property}.properties[${index}]`}
                                onAutoGenerateSystemID={handleAutoGenerateSystemID(index)}
                                onDropdown={handleChangeDropdown(index)}
                                onRemove={handleRemove(index)}
                                onRequired={handleChangeRequired(index)}
                            />
                        );

                    case EWidgets.StringWidget:
                        return (
                            <StringWidgets
                                countryLabel={countryLabel}
                                isAdvancedMode={isAdvancedMode}
                                formik={formik}
                                key={index}
                                section={section}
                                property={`${property}.properties[${index}]`}
                                onAutoGenerateSystemID={handleAutoGenerateSystemID(index)}
                                onDropdown={handleChangeDropdown(index)}
                                onRemove={handleRemove(index)}
                                onRequired={handleChangeRequired(index)}
                            />
                        );
                    default:
                        console.warn("Unsupported widget");
                }
            });
        } else {
            return null;
        }
    };

    return (
        <>
            <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        {renderWidgets()}
                    </Grid>
                    {!isProd ? (
                        <Grid item xs={12}>
                            <Box textAlign="right">
                                <Button endIcon={<Add />} variant="outlined" onClick={handleAdd}>
                                    {t("addField")}
                                </Button>
                            </Box>
                        </Grid>
                    ) : null}
                </Grid>
            </Grid>
        </>
    );
};

export default React.memo(DataFields);
