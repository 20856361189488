import React, { useRef, useState } from "react";
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, FormControlLabel, Typography } from "@material-ui/core";
import { useTranslationNamespaces } from '@blocksure/blocksure-core/dist/src/utilities';
import { EAttachmentTransaction, i18n } from "@surelync/common";
import camelCase from "lodash/camelCase";
import useStyles from "../../../styles";

const transactionNames = Object.keys(EAttachmentTransaction);

interface IProps {
    open: boolean;
    onClose: (event?: React.ChangeEvent<HTMLInputElement>, transactions?: EAttachmentTransaction[]) => void;
}

export const DialogTransactions: React.FC<IProps> = ({ open, onClose }) => {
    const classes = useStyles();
    const [state, setState] = useState<{ [key in EAttachmentTransaction]: boolean }>();
    const { t } = useTranslationNamespaces(i18n, ["surebyld", "surelync"]);
    const inputEl = useRef(null);

    const handleCancel = () => {
        setState(null);
        onClose();
    }

    const handleChange = (transaction: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const newState = { ...state };
        newState[transaction] = event.target.checked;
        setState(newState);
    }

    const handleOk = () => {
        inputEl.current.click();
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const transactions = Object.keys(state).map((it) => EAttachmentTransaction[it]);
        setState(null);
        onClose(event, transactions);
    }

    return (
        <Dialog data-testid="modal-transactions" maxWidth="xs" aria-labelledby="info-dialog-title" open={open} onClose={() => onClose()}>
            <DialogContent dividers>
                <Typography paragraph>{t("selectAllTransaction")}</Typography>
                {transactionNames.map((transactionName) => (
                    <div key={transactionName}>
                        <FormControlLabel
                            label={t(camelCase(transactionName))}
                            control={
                                <Box pl={1}>
                                    <Checkbox
                                        checked={state?.[transactionName] || false}
                                        classes={{ root: classes.noPadding }}
                                        color={state?.[transactionName] ? "primary" : "secondary"}
                                        inputProps={{ "data-testid": `checkbox-transaction-${transactionName}` } as any}
                                        onChange={handleChange(transactionName)}
                                    />
                                </Box>
                            }
                        />
                    </div>
                ))}
                <input
                    ref={inputEl}
                    data-testid="modal-transactions-input-file"
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="secondary">
                    {t("cancel")}
                </Button>
                <Button autoFocus onClick={handleOk} color="primary" data-testid="modal-transactions-ok-btn">
                    {t("ok")}
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default React.memo(DialogTransactions);
