import React from "react";
import { i18n } from "@surelync/common";
import { useTranslationNamespaces } from '@blocksure/blocksure-core/dist/src/utilities';
import { Button, Dialog, DialogActions, DialogContent } from "@material-ui/core";
import EnvMessage from "./EnvMessage";

interface IProps {
    open: boolean;
    onClose: () => void;
}

const EnvWarnDialog: React.FC<IProps> = ({ open, onClose }) => {
    const { t } = useTranslationNamespaces(i18n, ["surebyld", "surelync"]);

    return (
        <Dialog maxWidth="xs" aria-labelledby="info-dialog-title" open={open} onClose={onClose}>
            <DialogContent dividers>
                <EnvMessage  />
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={onClose} color="primary" variant="contained">
                    {t("ok")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default React.memo(EnvWarnDialog);
