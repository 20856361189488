import React, { useContext } from 'react';
import { Divider, Grid, useTheme } from '@material-ui/core';
import { Draggable, Droppable } from "react-beautiful-dnd";
import { PAGE_FIELDS } from '../TabSureApp';
import { Page, UISchema } from '../ui-shema.model';
import { InputCustom } from '@surelync/common';
import get from "lodash/get";
import { FormikErrors, FormikTouched } from 'formik';
import { ProductContext } from '../../../contexts';
import { Tag } from './Tag';
import useStyles from "../../../styles";
import { getName, getSystemInternalId } from '../helper';
import clsx from 'clsx';

interface Props {
    divider: boolean;
    errors: FormikErrors<UISchema>;
    index: number;
    page: Page;
    readOnly: boolean;
    selectedFieldPath: string;
    touched: FormikTouched<UISchema>;
    onBlur: (event: React.ChangeEvent<HTMLElement>) => void;
    onChange: (event: React.ChangeEvent<HTMLElement>) => void;
    onFieldClick: (path: string) => void;
}

export const PageFields: React.FC<Props> = React.memo(({ divider, errors, index, page, readOnly, selectedFieldPath, touched, onBlur, onChange, onFieldClick }) => {
    const [{ isAdvancedMode }] = useContext(ProductContext);
    const classes = useStyles();
    const theme = useTheme();

    return (
        <Grid container spacing={3} key={`page-${index}`}>
            <Grid item xs={3}>
                <InputCustom
                    autoComplete="off"
                    error={get(errors, `pages[${index}].title`)}
                    name={`pages[${index}].title`}
                    readOnly={readOnly}
                    required
                    theme={theme}
                    touched={get(touched, `pages[${index}].title`)}
                    value={page.title}
                    onBlur={onBlur}
                    onChange={onChange}
                />
            </Grid>
            <Grid item xs={9}>
                <Droppable droppableId={`${PAGE_FIELDS}${index}`} isDropDisabled={!!selectedFieldPath}>
                    {(provided, snapshot) => (
                        <div
                            className={clsx([classes.wrapperPageFields, snapshot.isDraggingOver ? classes.dropOver : classes.droppable])}
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                        >
                            {page.fields.map((field, indexField) => (
                                <Draggable key={`page-${index}-field-${indexField}`} draggableId={`page-${index}-field-${indexField}`} index={indexField} isDragDisabled={!!selectedFieldPath}>
                                    {(provided) => (
                                        <span {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                            <Tag
                                                label={isAdvancedMode ? getSystemInternalId(field) : getName(field)}
                                                selected={selectedFieldPath === getPath(index, indexField)}
                                                tooltip={isAdvancedMode ? getName(field) : getSystemInternalId(field)}
                                                onClick={() => onFieldClick(getPath(index, indexField))}
                                            />
                                        </span>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </Grid>
            {divider && (
                <Grid item xs={12}>
                    <Divider />
                </Grid>
            )}
        </Grid>
    );
});

const getPath = (indexPage: number, indexField: number) => `pages[${indexPage}].fields[${indexField}]`;
