import React, { forwardRef, useCallback, useContext, useEffect, useImperativeHandle } from "react";
import { useFormik } from "formik";
import { DELAY_AUTO_SAVE, ETab, ProductContext } from "../../contexts";
import { autosave, update } from "../../reducer";
import Grid from "@material-ui/core/Grid";
import MTAFeePayees from "./components/MTAFeePayees";
import CancellationFeePayees from "./components/CancellationFeePayees";
import AdditionalCharges from "./components/AdditionalCharges";
import Ratings from "./components/Ratings";
import { createAttachmentMetadata, getAttachmentByFileName, RATINGS_FILE_NAME, storeProduct } from "../../../utils";
import omit from "lodash/omit";
import GlobalContext from "../../../context/global-context";
import { validateFeesAndRatings } from "./validate-fees-and-ratings";

type IProps = Record<string, unknown>;

const TabFeesAndRatings: React.ForwardRefRenderFunction<Record<string, unknown>, IProps> = (props, ref) => {
    const { namespacedLocalStorage } = useContext(GlobalContext);
    const [{ data, files }, dispatch] = useContext(ProductContext);

    const prepareData = useCallback(
        (values) => {
            const allAttachments = data.hiddenAttachments.filter((it) => it.name !== RATINGS_FILE_NAME);
            const product = omit(values, "attachmentRatings");

            if (values.attachmentRatings) {
                allAttachments.push(values.attachmentRatings);
            }

            // name can be edited after attaching new file
            product.hiddenAttachments = allAttachments.map((attachment) => {
                const newFile = files[attachment?.location];
                if (newFile) {
                    newFile.name = RATINGS_FILE_NAME;
                    return {
                        ...attachment,
                        ...createAttachmentMetadata(newFile),
                        name: RATINGS_FILE_NAME,
                    };
                } else {
                    return attachment;
                }
            });

            return product;
        },
        [data.hiddenAttachments, files]
    );

    const formik = useFormik({
        initialValues: {
            ...data,
            attachmentRatings: getAttachmentByFileName(data.hiddenAttachments, RATINGS_FILE_NAME),
        },
        enableReinitialize: true,
        validate: validateFeesAndRatings,
        onSubmit: (values) => {
            const product = prepareData(values);
            dispatch(update(product));
        },
    });

    // AutoSave
    useEffect(() => {
        if (!formik.dirty || Object.keys(formik.errors).length) return;

        const timerId = setTimeout(() => {
            const params = {
                activeTab: ETab.FeesAndRatings,
                product: prepareData(formik.values),
            };
            const savedProduct = storeProduct(params);
            dispatch(update(savedProduct));
            dispatch(autosave(Date.now()));
        }, DELAY_AUTO_SAVE);

        return () => clearTimeout(timerId);
    }, [formik.dirty, formik.values, dispatch, namespacedLocalStorage, prepareData]);

    // call methods from parent
    useImperativeHandle(ref, () => formik);

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container>
                <Grid item sm={12} md={10} lg={8} xl={6}>
                    <Grid container spacing={1}>
                        <MTAFeePayees formik={formik} />
                        <CancellationFeePayees formik={formik} />
                        <AdditionalCharges formik={formik} />
                        <Ratings formik={formik} />
                    </Grid>
                </Grid>
            </Grid>
            <button data-testid="btn-submit" style={{display: "none"}} onClick={() => prepareData(formik.values)}>
                using for tests
            </button>
        </form>
    );
};

export default forwardRef(TabFeesAndRatings);
