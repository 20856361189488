import React from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import GetAppSharpIcon from "@material-ui/icons/GetAppSharp";
import PublishIcon from "@material-ui/icons/Publish";
import DeleteIcon from "@material-ui/icons/Delete";
import { useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { IAttachment, INewFile, InputCustom } from "@surelync/common";
import useStyles from "../../styles";
import { downloadFile } from "../../../utils";

interface IProps {
    acceptFiles?: string[];
    attachment: IAttachment;
    disabled?: boolean;
    error: string | { name: string; file: string };
    files: { [key: string]: INewFile };
    helpText?: string | JSX.Element;
    href: string;
    name: string;
    margin?: "dense";
    readOnly?: boolean;
    touched: boolean;
    value: string;
    onBlur: (e: React.FocusEvent<any>) => void;
    onChange: (e: React.ChangeEvent<any>) => void;
    onFileChange: (e: React.ChangeEvent<any>) => void;
    onRemove: () => void;
}

const Attachment: React.FC<IProps> = ({
    acceptFiles,
    attachment,
    disabled,
    error,
    helpText,
    href,
    files,
    name,
    margin,
    readOnly,
    touched,
    value,
    onBlur,
    onChange,
    onFileChange,
    onRemove,
}) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const classes = useStyles();

    const inValidFile = touched && isFileNotUploaded(attachment) && !files[attachment?.location];
    const fileName = (attachment?.blobHash ? attachment.name : files[attachment?.location]?.file.name) || "";

    return (
        <Grid container spacing={3} wrap="nowrap">
            <Grid item classes={{ root: classes[margin] }}>
                <DescriptionOutlinedIcon color="primary" />
            </Grid>
            <Grid item xs classes={{ root: classes[margin] }}>
                <InputCustom
                    autoComplete="off"
                    disabled={disabled}
                    error={typeof error === "string" ? error : error?.name}
                    name={name}
                    readOnly={readOnly}
                    theme={theme}
                    touched={touched}
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                />
                {helpText}
            </Grid>
            {!readOnly ? (
                <Grid item xs={3} classes={{ root: classes[margin] }}>
                    <Button
                        style={
                            inValidFile
                                ? {
                                    borderColor: theme.palette.error.main,
                                    color: theme.palette.error.main,
                                }
                                : null
                        }
                        component="label"
                        disabled={disabled}
                        endIcon={<PublishIcon />}
                        fullWidth
                        variant="text"
                    >
                        {attachment?.blobHash || files[attachment?.location] ? (
                            <Typography variant="caption">{formatFileName(fileName)}</Typography>
                        ) : isFileNotUploaded(attachment) ? (
                            t("upload")
                        ) : (
                            t("replaceFile")
                        )}
                        <input
                            accept={acceptFiles?.join(",")}
                            data-testid="input-file"
                            type="file"
                            style={{ display: "none" }}
                            onChange={onFileChange}
                        />
                    </Button>
                    {inValidFile ? (
                        <Typography color="error" variant="caption">
                            {t("required")}
                        </Typography>
                    ) : null}
                </Grid>
            ) : null}

            <Grid item classes={{ root: classes[margin] }}>
                <IconButton
                    color="primary"
                    disabled={disabled || isFileNotUploaded(attachment) }
                    size="small"
                    onClick={() => downloadFile(href, attachment.name)}
                >
                    <GetAppSharpIcon />
                </IconButton>
            </Grid>
            {!readOnly ? (
                <Grid item classes={{ root: classes[margin] }}>
                    <IconButton color="primary" data-testid={`delete-${name}`} disabled={disabled} size="small" onClick={onRemove}>
                        <DeleteIcon />
                    </IconButton>
                </Grid>
            ) : null}
        </Grid>
    );
};

export default Attachment;

function isFileNotUploaded(attachment: IAttachment) {
    return !attachment?.location; // || attachment.location.startsWith("local_");
}

function formatFileName(name: string) {
    return name.length > 15 ? `${name.substring(0, 15)}...` : name;
}
