import React from "react";
import Table from '@material-ui/core/Table';
import { i18n, IAuthDetails } from "@surelync/common";
import { IHistoryResponse } from "./history.model";
import { TableBody, TableCell, TableRow } from "@material-ui/core";
import * as FormatUtils from '@blocksure/blocksure-core/dist/src/utilities/FormatUtils';
import Indicator from "../@Products/components/Indicator";

export const setDataHistory = (records: IHistoryResponse[], authDetails: IAuthDetails) => {

    const userParty = authDetails.party.toUpperCase();
    const userIdentity = authDetails.identityType;

    return records.map((item) => {
        const approvals = Object.values(item.updatedProduct.approvals);
        const productApprovals = checkApprovals(approvals, userParty);
        return {
            change: renderItems(item),
            productId: item.updatedProduct.id,
            version: item.updatedProduct.version,
            status: i18n.t(item.updatedProduct.state.toLowerCase()),
            modifiedBy: renderTableCellForX500Name(productApprovals.mostRecentApproval.party),
            participants: renderApprovalTable(item, approvals, 'party'),
            approvalStatus: renderApprovalTable(item, approvals, 'status'),
            comments: renderApprovalTable(item, approvals, 'comments'),
        }
    });
}

function checkApprovals(approval, userParty) {
    const approved = approval.filter(it => it.status === 'Approved');
    const isPendingApproval = approval.filter(it => it.party.toUpperCase() === userParty && it.status === 'Pending').length !== 0;
    const mostRecentApproval = approved.reduce((min, it) => it.ts > min.ts ? it : min, approved[0]);
    return {approvals: approved.length, isPendingApproval, mostRecentApproval};
}

function renderItems(value: IHistoryResponse) {
    return value.fields.length > 5 && value.updatedProduct.version === 0
        ? i18n.t("productHasBeenCreated")
        : (
            <Table size="small">
                <TableBody>
                    {value.fields.map(({ path, oldValue, newValue }) => (
                        <TableRow key={oldValue + newValue}>
                            <TableCell>{path}</TableCell>
                            <TableCell>{oldValue}</TableCell>
                            <TableCell>{newValue}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        )
}

function renderApprovalTable(delta, approvals, fieldName) {
    return (
        <p>
            {delta.updatedProduct.approvals && approvals.map(partyApproval => renderApprovals(partyApproval, fieldName, delta.name))}
        </p>
    );
}

function renderApprovals(approval, fieldName, name) {
    // const comment = approval[value] ? <OverlayTrigger overlay={<Tooltip id="tooltip"> View comment</Tooltip>}>
    //     <button href="#" style={color} onClick={() => this.viewComment(name, approval)}>
    //         <i className="fa fa-commenting-o" aria-hidden="true" /></button>
    // </OverlayTrigger> : '-';
    let tableCellOrIndicator;

    switch (fieldName) {
        case 'comments' :
            const comment = approval[fieldName] ? approval.comments : "-";
            tableCellOrIndicator = <span>{comment}</span>
            break;

        case 'status' :
            tableCellOrIndicator = <Indicator variant={approval[fieldName]} />
            break;

        case 'party' :
            const x500Name = approval[fieldName]
            tableCellOrIndicator = renderTableCellForX500Name(x500Name)
    }
    return (
        <span key={approval.party}>{tableCellOrIndicator}<br/></span>
    );
}

function renderTableCellForX500Name(x500Name) {
    const partyDisplayName = FormatUtils.formatPartyName(x500Name)
    return <span title={x500Name}>{partyDisplayName}</span>
}
