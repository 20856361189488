import { lighten, makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) => ({
    disabled: {
        opacity: 0.5,
    },
    droppable: {
        border: "2px dashed transparent",
    },
    dropOver: {
        border: `2px dashed ${lighten(theme.palette.primary.main, 0.8)}`,
        backgroundColor: lighten(theme.palette.primary.main, 0.95)
    },
    error: {
        color: theme.palette.error.main,
    },
    helpIcon: {
        cursor: "pointer",
        position: "absolute",
        right: 16,
        top: 18,
        fontSize: 16,
        opacity: 0.5,
        "&:hover": {
            opacity: 1
        }
    },
    iconWrapper: {
        display: "flex",
        zIndex: 0
    },
    fullHeight: {
        height: "100%"
    },
    label: {
        fontWeight: 300,
    },
    labelRight: {
        justifyContent: "end"
    },
    lightBG: {
        backgroundColor: lighten(theme.palette.primary.main, 0.95),
        height: 40,
        width: "100%",
        position: "absolute",
    },
    dense: {
        padding: "6px 12px !important",
    },
    noPadding: {
        padding: 0,
    },
    noPaddingTop: {
        paddingTop: "0 !important",
    },
    py0: {
        paddingTop: "0 !important",
        paddingBottom: "0 !important",
    },
    my0: {
        marginTop: "0 !important",
        marginBottom: "0 !important",
    },
    requiredCard: {
        backgroundColor: lighten(theme.palette.primary.main, 0.9),
    },
    root: {
        background: lighten(theme.palette.primary.main, 0.85),
        padding: 4,
    },
    sm: {
        minWidth: 300,
    },
    subSection: {
        padding: 8,
        backgroundColor: lighten(theme.palette.primary.main, 0.96),
        margin: 0,
        "& input, .selectcustom-MuiSelect-root": {
            backgroundColor: "white",
        },
    },
    title: {
        textAlign: "center",
        fontSize: theme.typography.body2.fontSize,
    },
    wrapper: {
        position: "relative",
    },
    wrapperFields: {
        overflow: "hidden",
        maxHeight: "70vh",
        overflowY: "auto"
    },
    wrapperList: {
        display: "flex",
        flexDirection: "column",
        gap: 5,
    },
    wrapperPageFields: {
        display: "flex",
        flexWrap: "wrap",
        gap: 5,
        overflow: "hidden",
        minHeight: 65,
    },
    z0: {
        zIndex: 0
    }
}));
