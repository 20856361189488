type StopFunc = () => void;
export function showProgress(): StopFunc {
    const { style } = document.body;
    style.cursor = "progress";
    return () => style.cursor = "default";
}

export function showFakeProgress(milliseconds = 10000) {
    const stop = showProgress();
    setTimeout(() => stop(), milliseconds);
}
