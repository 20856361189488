import React, { useContext, useMemo } from "react";
import { Card, CardContent, CardHeader, Grid, Typography, useTheme } from "@material-ui/core";
import { useTranslationNamespaces } from '@blocksure/blocksure-core/dist/src/utilities';
import { ERatingTypes, i18n, InputCustom, IProduct, SelectCustom } from "@surelync/common";
import { IFormik } from "../../../models";
import useStyles from "../../../styles";
import GlobalContext from "../../../../context/global-context";

interface IProps {
    formik: IFormik<IProduct>;
    selectedSection: string;
}

const Rating: React.FC<IProps> = ({ formik, selectedSection }) => {
    const { isProd } = useContext(GlobalContext);
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslationNamespaces(i18n, ["surebyld", "surelync"]);

    const optionsParties = useMemo(() => {
        const parties = Object.keys(formik.values.partiesAndRoles).sort();
        return [...parties].map((it) => ({ label: it, value: formik.values.partiesAndRoles[it] }));
        // eslint-disable-next-line
    }, [formik.values.partiesAndRoles]);

    const { Fixed } = ERatingTypes;
    const typeOptions = useMemo(() => (Object.keys(ERatingTypes).map((key) => ({
        label: t(key.toLocaleLowerCase()),
        value: key
    }))), [t]);
    
    const rating = formik.values.sections[selectedSection].rating?.type;
    const isFixed = useMemo(() => (rating === Fixed), [rating, Fixed]);

    const renderFixedFields = () => {
        if (!isFixed) return null;
        return (
            <>
                <Grid item xs={6} classes={{ root: classes.py0 }}>
                    <Typography align="right" variant="body2">
                        {t("premium")}
                    </Typography>
                </Grid>
                <Grid item xs={6} classes={{ root: classes.py0 }}>
                    <InputCustom
                        autoComplete="off"
                        name={`sections[${selectedSection}].rating.params.premium`}
                        readOnly={isProd}
                        theme={theme}
                        value={formik.values.sections[selectedSection].rating?.params?.premium || ""}
                        onChange={formik.handleChange}
                    />
                </Grid>
                <Grid item xs={6} classes={{ root: classes.py0 }}>
                    <Typography align="right" variant="body2">
                        {t("sumInsured")}
                    </Typography>
                </Grid>
                <Grid item xs={6} classes={{ root: classes.py0 }}>
                    <InputCustom
                        autoComplete="off"
                        data-testid="section-sum-insured"
                        name={`sections.${selectedSection}.rating.params.sumInsured`}
                        readOnly={isProd}
                        theme={theme}
                        value={formik.values.sections[selectedSection].rating?.params?.sumInsured || ""}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                    />
                </Grid>
            </>
        )
    }

    return (
        <>
            <Grid item sm={12}>
                <Card variant="outlined">
                    <CardHeader classes={{ root: classes.root, title: classes.title }} title={t("rating")} />
                    <CardContent>
                        <Grid container spacing={3} alignItems="center">
                            <Grid item xs={6} classes={{ root: classes.py0 }}>
                                <Typography align="right" variant="body2">
                                    {t("parties")}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} classes={{ root: classes.py0 }}>
                                <SelectCustom
                                    name={`sections[${selectedSection}].rating.party`}
                                    options={optionsParties}
                                    readOnly={isProd}
                                    theme={theme}
                                    value={formik.values.sections[selectedSection].rating?.party || ""}
                                    onChange={formik.handleChange}
                                />
                            </Grid>
                            <Grid item xs={6} classes={{ root: classes.py0 }}>
                                <Typography align="right" variant="body2">
                                    {t("type")}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} classes={{ root: classes.py0 }}>
                                <SelectCustom
                                    name={`sections[${selectedSection}].rating.type`}
                                    options={typeOptions}
                                    readOnly={isProd}
                                    theme={theme}
                                    value={rating || ""}
                                    onChange={formik.handleChange}
                                />
                            </Grid>
                            {renderFixedFields()}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </>
    );
};

export default Rating;
