import React from "react";
import { lighten, makeStyles } from "@material-ui/core/styles";
import { i18n, EApprovalStatus } from "@surelync/common";
import { useTranslationNamespaces } from '@blocksure/blocksure-core/dist/src/utilities';
import clsx from "clsx";

enum EStatus {
    Approved = "approved",
    AwaitingApp = "awaitingApp",
    inProd = "inProd",
    NotRequested = "notRequested",
    Pending = "pending",
    Rejected = "rejected",
}

const useStyles = makeStyles((theme) => ({
    root: {
        whiteSpace: "nowrap",
    },
    pending: {
        backgroundColor: lighten(theme.palette.primary.main, 0.8),
        padding: 8,
    },
}));

const Indicator: React.FC<{ variant?: EApprovalStatus }> = ({ variant }) => {
    const classes = useStyles();
    const { t } = useTranslationNamespaces(i18n, ["surebyld", "surelync"]);
    const cls = variant === EApprovalStatus.Pending || variant === EApprovalStatus.AwaitingYourApproval ? classes.pending : "";

    if (!variant) {
        return null;
    }

    return (
        <span className={clsx([classes.root, cls])}>
            {t(EStatus[variant])}
        </span>
    );
};

export default React.memo(Indicator);
