import React from "react";
import { Route, Router, Switch } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createGenerateClassName, StylesProvider, ThemeProvider } from "@material-ui/core/styles";
import { EIdentityType, history, i18n, isFeatureEnabled, theme, PageNotFoundPage } from "@surelync/common";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import { Layout } from "./components/Layout/Layout";
import GlobalState from "./context/GlobalState";
import ProductsPage from "./@Products/ProductsPage";
import EditProductPage from "./@EditProductPage/EditProductPage";
import ProductVersionListPage from "./@ProductVersionList/ProductVersionListPage";

const ALL_IDENTITIES = [EIdentityType.Blocksure, EIdentityType.Broker, EIdentityType.Insurer, EIdentityType.MGA];

function App() {

    if (!isFeatureEnabled("surebyld")) {
        history.push("/");
        return null;
    }

    return (
        <I18nextProvider i18n={i18n}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Layout>
                    <Router history={history}>
                        <Switch>
                            <Route path="/surebyld/404" component={() => <PageNotFoundPage theme={theme} />} />
                            <PrivateRoute component={ProductsPage} exact identityTypes={ALL_IDENTITIES} path="/surebyld" />,
                            <PrivateRoute component={EditProductPage} exact identityTypes={ALL_IDENTITIES} path="/surebyld/:id" />,
                            <PrivateRoute component={ProductVersionListPage} identityTypes={ALL_IDENTITIES} path="/surebyld/:id/versions" />,
                            <PrivateRoute component={EditProductPage} identityTypes={ALL_IDENTITIES} path="/surebyld/:id/:version" />
                            <Route path="*" component={PageNotFoundPage} />
                        </Switch>
                    </Router>
                </Layout>
            </ThemeProvider>
        </I18nextProvider>
    );
}

const generateClassName = createGenerateClassName({
    productionPrefix: "surelync",
    seed: "surebyld",
});

export default function Root() {
    // Fix: Application unexpected behavior on clicking browser history back/forward buttons
    if (`/surelync${history.location.pathname}` !== window.location.pathname) {
        history.replace(window.location.pathname.replace(`/surelync`, ""));
    }

    return (
        <StylesProvider generateClassName={generateClassName}>
            <GlobalState>
                <App />
            </GlobalState>
        </StylesProvider>
    );
}
