import React, { useContext, useMemo } from "react";
import { Grid, Typography, useTheme } from "@material-ui/core";
import { InputCustom, IProduct, SelectCustom } from "@surelync/common";
import get from "lodash/get";
import { IFormik } from "../../../models";
import useStyles from "../../../styles";
import GlobalContext from "../../../../context/global-context";
import { handleChangeNumber } from "../utils";

interface ITableRow {
    property: string;
    formik: IFormik<IProduct>;
    name: string;
}

const TableRow: React.FC<ITableRow> = ({ property, formik, name }) => {
    const { isProd } = useContext(GlobalContext);
    const classes = useStyles();
    const theme = useTheme();

    const optionsParties = useMemo(() => {
        const parties = Object.keys(formik.values.partiesAndRoles).sort();
        return ["Blocksure", ...parties].map((it) => ({ label: it, value: it }));
        // eslint-disable-next-line
    }, [formik.values.partiesAndRoles]);

    const generateError = (key) => {
        const value = property.split(".").pop();
        return Object.keys(formik.errors).find(name => name.includes(`${value}.${key}`));
    }

    return (
        <>
            <Grid item xs={4} classes={{ root: classes.py0 }}>
                <Typography align="right" variant="body1">
                    {name}
                </Typography>
            </Grid>
            <Grid item xs={2} classes={{ root: classes.py0 }}>
                <InputCustom
                    autoComplete="off"
                    name={`${property}.amount`}
                    readOnly={isProd}
                    theme={theme}
                    touched={!!get(formik.touched, `${property}.amount`)}
                    value={get(formik.values, `${property}.amount`) || ""}
                    onBlur={formik.handleBlur}
                    onChange={handleChangeNumber(`${property}.amount`, formik)}
                />
            </Grid>
            <Grid item xs={2} classes={{ root: classes.py0 }}>
                <SelectCustom
                    error={get(formik.errors, generateError('payer'))}
                    name={`${property}.payer`}
                    options={optionsParties}
                    readOnly={isProd}
                    theme={theme}
                    touched
                    value={get(formik.values, `${property}.payer`) || ""}
                    onChange={formik.handleChange}
                />
            </Grid>
            <Grid item xs={2} classes={{ root: classes.py0 }}>
                <SelectCustom
                    error={get(formik.errors, generateError('payee'))}
                    name={`${property}.payee`}
                    options={optionsParties}
                    readOnly={isProd}
                    theme={theme}
                    touched
                    value={get(formik.values, `${property}.payee`) || ""}
                    onChange={formik.handleChange}
                />
            </Grid>
        </>
    );
};

export default TableRow;
