import React from "react";
import { i18n } from "@surelync/common";
import { useTranslationNamespaces } from '@blocksure/blocksure-core/dist/src/utilities';
import { Button, Dialog, DialogActions, DialogContent, Typography } from "@material-ui/core";

interface IProps {
    messages: string[];
    open: boolean;
    onClose: () => void;
}

const ErrorsTabsDialog: React.FC<IProps> = ({ messages, open, onClose }) => {
    const { t } = useTranslationNamespaces(i18n, ["surebyld", "surelync"]);

    return (
        <Dialog maxWidth="sm" aria-labelledby="info-dialog-title" open={open} onClose={onClose}>
            <DialogContent dividers>
                {messages?.map((line, index) => (
                    <Typography key={index} paragraph variant="body1">{line}</Typography>
                ))}
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={onClose} color="primary" variant="contained">
                    {t("ok")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default React.memo(ErrorsTabsDialog);
