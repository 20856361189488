import { i18n, IProduct } from "@surelync/common";

export function validateCancellations(values: IProduct): Partial<IProduct> {
    const errors: Partial<IProduct> = {};
    const errorAuthorisedParties = [];
    const errorReasons = [];

    values.cancellation.authorisedParties.forEach((part: string, index: number) => {
        if (!part) {
            errorAuthorisedParties[index] = i18n.t("required");
        }
    });

    values.cancellation.reasons.forEach((reason: string, index: number) => {
        if (!reason.trim()) {
            errorReasons[index] = i18n.t("required");
        }
    });

    if (errorAuthorisedParties.length || errorReasons.length) {
        errors.cancellation = {};
    }

    if (errorAuthorisedParties.length) {
        errors.cancellation.authorisedParties = errorAuthorisedParties;
    }

    if (errorReasons.length) {
        errors.cancellation.reasons = errorReasons;
    }

    return errors;
}
