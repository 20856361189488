import React, { useCallback, useContext, useEffect, useMemo, useReducer, useRef } from "react";
import { useTranslationNamespaces } from "@blocksure/blocksure-core/dist/src/utilities";
import { Box, Button, Grid, Tab, Tabs, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { ProductApiClient } from "@blocksure/blocksure-core/dist/src/services/api-clients/";
import { i18n, IProduct, ITableData, MessageBox, signInService, TableContainer, getCountries, EApprovalStatus, PageContainer, RecordsNotFound } from "@surelync/common";
import {generateErrorMessage} from "@blocksure/blocksure-core/dist/src/utilities/ErrorHandler";
import { getApprovalStatus, getDefaultConfig, handleUnderDevelopment, setDataProduct } from "./helper";
import GlobalContext from "../context/global-context";
import { ActionParams, DeleteParams, SelectParams, getHeaderColumnsI18, RedirectParams } from "./columns.config";
import { BannerClientLogo } from "../components/BannerClientLogo/BannerClientLogo";
import { Link, useHistory } from "react-router-dom";
import Add from "@material-ui/icons/Add";
import CloudUpload from "@material-ui/icons/CloudUpload";
import { downloadFile, showFakeProgress, showProgress } from "../utils";
import { ConfirmDialog } from "../@EditProductPage/components";
import { AUTO_SAVE_KEY } from "../@EditProductPage/contexts";
import { EDisplayMode, TABS_BLOCKSURE_STAFF, TABS_NOT_BLOCKSURE_STAFF } from "./constants";
import { failure, initialState, ProductState, reducer, request, resetActions, setActiveTab, setDeleteProduct, setEnvWarnModal, setMessage, setTabs, success } from "./reducer";
import EnvMessage from "../components/EnvWarnDialog/EnvMessage";
import EnvWarnDialog from "../components/EnvWarnDialog/EnvWarnDialog";
import { SET_PRODUCT_FOR_COPY } from "../context/reducers";

const ProductsPage: React.FC = () => {
    const { currentUser, isProd, namespacedLocalStorage, dispatchGlobal, ...otherGlobal} = useContext(GlobalContext);
    const [state, dispatch] = useReducer(reducer, initialState);
    const { t } = useTranslationNamespaces(i18n, ["surebyld", "surelync"]);
    const history = useHistory();
    const theme = useTheme();
    const stateRef = useRef<ProductState & {linkedEnv: string}>(null);
    const productApiClient = useMemo(() => new ProductApiClient(namespacedLocalStorage), []);

    const { activeTab, data, deleteProduct, error, isLoading, message, openEnvWarnModal, tabs, total } = state;
    const displayMode = tabs?.[activeTab]?.id;
    const linkedEnv = displayMode === EDisplayMode.ReadyForRelease && otherGlobal.linkedEnv;

    stateRef.current = {
        ...state,
        linkedEnv
    };

    useEffect(() => {
        const tabs = (currentUser.userDetails.email.endsWith("@blocksure.com") ? TABS_BLOCKSURE_STAFF : TABS_NOT_BLOCKSURE_STAFF)
            .map((it) => ({ id: it, title: t(it) }));
        const defaultTab = tabs.findIndex((it) => it.id === EDisplayMode.Active);
        dispatch(setTabs(tabs));
        // Note: the first tab to be displayed (default tab when first arriving on "Products" page) should be "Active" tab
        dispatch(setActiveTab(defaultTab));
    }, []);

    const handleApprove = useCallback(async (productId: string) => {
        const params = { status: EApprovalStatus.Approved, message: "" };
        const stopProgress = showProgress();
        try {
            const product: IProduct = await productApiClient.productAction(productId, params);
            const approvalStatus = getApprovalStatus(product.approvals, currentUser.authDetails.party);
            const newData = stateRef.current.data.map((it) => (it.id === productId ? { ...it, approvalStatus } : it));
            dispatch(success({ data: newData, total }));
        } catch (error) {
            dispatch(failure(generateErrorMessage(error)));
            console.error(`Unable to ${params.status} product`, error);
        }
        stopProgress();
    }, [data, total]);

    const handleActions = useCallback((params: ActionParams) => {
        dispatch(resetActions());
        switch (params.type) {
            case "approve": {
                const { productId } = params as SelectParams;
                handleApprove(productId);
                break;
            }
            case "copy": {
                const { productId } = params as SelectParams;
                handleCopy(productId);
                break;
            }
            case "delete": {
                const { localStorageKey, name } = params as DeleteParams;
                dispatch(setDeleteProduct({ localStorageKey, name }));
                break;
            }
            case "edit": {
                const { link } = params as RedirectParams;
                handleEdit(link);
                break;
            }
            case "export": {
                const { productId } = params as SelectParams;
                handleExport(productId);
                break;
            }
            case "promoteFromLinkedEnv": {
                const { productId } = params as SelectParams;
                handlePromoteFromLinkedEnv(productId);
                break;
            }
            default:
                console.warn(params.type, "- Unimplemented action");
        }
    }, [data]);

    const handleCopy = useCallback(async (productId: string) => {
        const stopProgress = showProgress();
        try {
            const response = await productApiClient.clone(productId);
            dispatchGlobal({ type: SET_PRODUCT_FOR_COPY, payload: response});
            history.push(handleRedirect("surebyld/copy"));
        } catch (error) {
            dispatch(failure(generateErrorMessage(error)));
            console.error(error);
        }
        stopProgress();
    }, [data]);

    const handleConfirmDelete = useCallback(
        (enable: boolean) => {
            if (enable) {
                namespacedLocalStorage.removeItem(`${AUTO_SAVE_KEY}_${deleteProduct.localStorageKey}`);
                const newData = data.filter((it) => it.localStorageKey !== deleteProduct.localStorageKey);
                dispatch(success({ data: newData, total: total - 1 }));
            }
            dispatch(setDeleteProduct(null));
        },
        [data, deleteProduct, namespacedLocalStorage, total]
    );

    const handleEdit = useCallback((link: string) => {
        const { activeTab, linkedEnv, tabs } = stateRef.current;
        const displayMode = tabs[activeTab].id;
        // In the PROD show modal "Products cannot be edited in this environment".
        if (isProd && displayMode === EDisplayMode.Active) {
            dispatch(setEnvWarnModal(true));
        //Browser launches a new tab, at Linked Env, with SureByld URL of that product
        } else if (linkedEnv) {
            window.open(link, "_blank");
        } else {
            history.push(handleRedirect(link));
        }
    }, [activeTab]);

    const handleExport = useCallback(async (productId: string) => {
        const product: ITableData = stateRef.current.data.find((row) => row.id === productId);
        showFakeProgress();
        try {
            const url = await productApiClient.getProductZipUrl(productId, product.version);
            downloadFile(url, `${product.name}.zip`);
        } catch (error) {
            dispatch(failure(generateErrorMessage(error)));
            console.error(error);
        }
    }, [data]);

    const handleImportProduct = async (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        try {
            const file: File = event.target.files[0];
            const importedPolicy = await productApiClient.importZip(file, file.name);
            const countries = await getCountries();
            const row = setDataProduct([importedPolicy], currentUser.authDetails.party, countries, linkedEnv);
            // Add imported policy to the top of the table
            dispatch(success({ data: [...row, ...stateRef.current.data], total: total + 1 }));

        } catch (error) {
            dispatch(failure(generateErrorMessage(error)));
            console.error(error);
        }
    }

    const handlePromoteFromLinkedEnv = useCallback(async (productId: string) => {
        const product: ITableData = stateRef.current.data.find((row) => row.id === productId);
        try {
            await productApiClient.promoteFromLinkedEnv(productId, product.version);
            dispatch(setMessage(t("promoteSuccess", { productName: (product.name as string).toUpperCase() })));
        } catch (error) {
            dispatch(failure(generateErrorMessage(error)));
            console.error(error);
        }
    }, [data]);

    const handleRedirect = (pathname: string) => {
        return { pathname, state: { from: "/surebyld", backBtnlabel: t("products") } };
    };

    const handleTabChange = useCallback((event: React.ChangeEvent<unknown>, value: number) => {
        const { activeTab, tabs } = stateRef.current;
        const displayMode: EDisplayMode = tabs[value].id;

        event.preventDefault();

        if (value === activeTab) {
            return;
        }

        dispatch(setActiveTab(value));

        // Products cannot be edited in this environment.
        if (isProd && displayMode === EDisplayMode.UnderDevelopment) {
            dispatch(failure("")); // clean error
            return;
        }

        dispatch(request());
        setTimeout(() => fetchData(), 0);

    }, [activeTab, isProd]);

    const fetchData = useCallback(async (rowsPerPage = 10, page = 0) => {
        const { activeTab, linkedEnv, tabs } = stateRef.current;
        const displayMode: EDisplayMode = tabs[activeTab].id;

        dispatch(request());

        try {
            let products: IProduct[];
            let total: number;

            // Show draft and pending Products
            if (displayMode === EDisplayMode.UnderDevelopment) {

                const result = await handleUnderDevelopment(isProd, rowsPerPage, page);
                products = result.products;
                total = result.total;

            // Show not pending Products from linked ENV.
            } else if (displayMode === EDisplayMode.ReadyForRelease) {
                const response: { items: IProduct[], total: number } = await productApiClient.linked(getDefaultConfig(), rowsPerPage, page);
                products = response.items;
                total = response.total;

            // Show not pending Products
            } else if (displayMode === EDisplayMode.Active) {
                const response: { items: IProduct[], total: number } = await productApiClient.search(getDefaultConfig(), rowsPerPage, page);
                products = response.items;
                total = response.total;
            } else {
                console.warn("Not implemented yet");
            }
            const countries = await getCountries();
            const data = setDataProduct(products, currentUser.authDetails.party, countries, linkedEnv);
            dispatch(success({ data, total }));
        } catch (error) {
            dispatch(failure(generateErrorMessage(error)));
            console.warn("error", error.message);
        }
    }, [activeTab]);

    const headerColumns = useMemo(() => {
        if (displayMode === EDisplayMode.UnderDevelopment && isProd) {
            return null;
        }
        return getHeaderColumnsI18(t, linkedEnv, handleActions, handleRedirect);
    }, [displayMode, isProd, linkedEnv]);

    const renderContent = () => {

        // Products cannot be edited in this environment.
        if (isProd && displayMode === EDisplayMode.UnderDevelopment) {
            return <EnvMessage />;
        } else {
            return (
                <>
                    {tabs && (
                        <Grid item xs={12}>
                            <TableContainer theme={theme} columns={headerColumns} data={data} fetchData={fetchData} loading={isLoading} total={total} />
                        </Grid>
                    )}
                    {data?.length === 0 && (
                        <Grid item xs={12}>
                            <RecordsNotFound theme={theme} />
                        </Grid>
                    )}
                </>
            )
        }
    }

    return (
        <PageContainer theme={theme} title={t("products")}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Grid container alignItems="flex-end">
                        <Grid item xs={12} sm={8} md={5}>
                            <Typography variant="h5">{t("products")}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                            <Box textAlign={{ xs: "right", md: "center" }}>
                                <BannerClientLogo />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8} md={5} />
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs>
                            <Box mb={2}>
                                <Tabs
                                    aria-label="tabs"
                                    indicatorColor="primary"
                                    scrollButtons="auto"
                                    value={activeTab}
                                    variant="scrollable"
                                    onChange={handleTabChange}
                                >
                                    {tabs && tabs.map((option) => <Tab disabled={isLoading} label={option.title} key={option.id} />)}
                                </Tabs>
                            </Box>
                        </Grid>
                        {!isProd && signInService.hasPermission(["PRODUCT_EDIT"]) && (
                            <Grid item>
                                <Button color="primary" component="label" endIcon={<CloudUpload />} variant="text">
                                    {t("importProduct")}
                                    <input
                                        accept="application/zip,application/x-zip-compressed"
                                        data-testid="import-file"
                                        type="file"
                                        style={{ display: "none" }}
                                        onChange={handleImportProduct}
                                    />
                                </Button>
                                <Button component={Link} color="primary" endIcon={<Add />} variant="text" to="/surebyld/new">
                                    {t("createProduct")}
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Grid>

                {error && (
                    <Grid item xs={12} md={8} data-testid="product-error">
                        <MessageBox message={error} theme={theme} variant="error" onClose={() => dispatch(resetActions())} />
                    </Grid>
                )}
                {message && (
                    <Grid item xs={12} md={8} data-testid="product-message">
                        <MessageBox message={message} theme={theme} variant="important" onClose={() => dispatch(resetActions())} />
                    </Grid>
                )}
                {renderContent()}
            </Grid>
            <ConfirmDialog
                message={t("rUSureDeleteProduct", { name: deleteProduct?.name.toUpperCase() })}
                open={!!deleteProduct}
                onClose={handleConfirmDelete}
            />
            <EnvWarnDialog open={openEnvWarnModal} onClose={() => dispatch(setEnvWarnModal(false))} />
        </PageContainer>
    );
};

export default ProductsPage;
