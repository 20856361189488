import React from "react";
import { Link } from "react-router-dom";
import { CellInfo } from "react-table";
import * as FormatUtils from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";
import IconButton from "@material-ui/core/IconButton";
import HistoryIcon from "@material-ui/icons/History";
import LinkButton from "@material-ui/core/Link";
import ActionsMenu from "./components/ActionsMenu";
import Indicator from "./components/Indicator";

type ActionType = "approve" | "copy" | "close" | "delete" | "edit" | "export" | "promoteFromLinkedEnv";
export type DeleteParams = { localStorageKey: string, name: string, type: ActionType };
export type SelectParams = { productId: string, type: ActionType };
export type RedirectParams = { link: string, type: ActionType };
export type ActionParams = DeleteParams | RedirectParams | SelectParams;
type ActionHandler = (params: ActionParams) => any;
type RedirectHandler = (path: string) => any;

type HeaderColumnsI18 = (t: (s: string) => string, linkedEnv: string, onAction: ActionHandler, onRedirect: RedirectHandler) => Array<any>;
export const getHeaderColumnsI18: HeaderColumnsI18 = (t, linkedEnv, onAction, onRedirect) => [
    {
        Header: () => t("lastUpdated"),
        accessor: "ts",
        size: "medium",
        sortMethod: FormatUtils.compareDates,
        Cell: (row: CellInfo) => FormatUtils.renderDateTime(row.value),
    },
    {
        Header: () => t("name"),
        accessor: "name",
        size: "extraMedium",
        Cell: (row: CellInfo) => linkedEnv ? (
            <LinkButton target="_blank" rel="noopener" href={`${row.row.original.link}`}>
                {row.value}
            </LinkButton>
        ) : (
            <LinkButton component={Link} to={onRedirect(row.row.original.link)}>
                {row.value}
            </LinkButton>
        ),
    },
    {
        Header: () => t("owner"),
        accessor: "owner",
        size: "small",
    },
    // {
    //     Header: () => t("lineOfBusiness"),
    //     accessor: "lineOfBusiness",
    //     size: "medium",
    // },
    {
        Header: () => t("country"),
        accessor: "country",
        size: "medium",
    },
    // {
    //     Header: () => t("partner"),
    //     accessor: "partner",
    //     size: "small",
    // },
    {
        Header: () => t("history"),
        accessor: "history",
        size: "small",
        Cell: (row: CellInfo) => (row.row?.original.status !== "Draft" ? (
            <IconButton
                component={Link}
                to={{
                    pathname: `/surebyld/${row.row?.original.id}/versions`,
                    state: { from: "/surebyld", backBtnlabel: t("products") }
                }}
            >
                <HistoryIcon fontSize="small" />
            </IconButton>
        ) : "—"),
    },
    {
        Header: () => t("approvalStatus"),
        accessor: "approvalStatus",
        size: "medium",
        Cell: (row: CellInfo) => <Indicator variant={row.value} />,
    },
    {
        Header: () => t("actions"),
        accessor: "actions",
        size: "small",
        Cell: (row: CellInfo) => (
            <ActionsMenu
                data={row.row?.original}
                onAction={onAction}
            />
        ),
    },
];
