import React, { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { useFormik } from "formik";
import { DELAY_AUTO_SAVE, ETab, MAX_TAB_NAME, ProductContext } from "../../contexts";
import { autosave, update } from "../../reducer";
import Grid from "@material-ui/core/Grid";
import { i18n, IProduct } from "@surelync/common";
import { Box, Button, IconButton, Tab, Tabs, Typography } from "@material-ui/core";
import VisibleTo from "./components/VisibleTo";
import Rating from "./components/Rating";
import Distribution from "./components/Distribution";
import Overview from "./components/Overview";
import useStyle from "../../styles";
import { Add, Delete, FileCopy } from "@material-ui/icons";
import { useTranslationNamespaces } from '@blocksure/blocksure-core/dist/src/utilities';
import { ConfirmDialog } from "../ConfirmDialog/ConfirmDialog";
import cloneDeep from "lodash/cloneDeep";
import GlobalContext from "../../../context/global-context";
import { storeProduct } from "../../../utils";
import { defaultPremiumSplit } from "../../models/default-product.model";
import set from "lodash/set";

type IProps = Record<string, unknown>;

const TabSections: React.ForwardRefRenderFunction<Record<string, unknown>, IProps> = (props, ref) => {
    const { isProd, namespacedLocalStorage } = useContext(GlobalContext);
    const [{ data }, dispatch] = useContext(ProductContext);
    const [selectedSection, setSelectedSection] = useState(data.sectionDisplayOrder[0]);
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const classes = useStyle();
    const { t } = useTranslationNamespaces(i18n, ["surebyld", "surelync"]);

    const prepareData = useCallback((values: IProduct): IProduct => {
        const product = { ...values };
        return product;
    }, []);

    const formik = useFormik<IProduct>({
        initialValues: {
            ...data,
        },
        enableReinitialize: true,
        validate: (values: IProduct) => {
            const errors: Partial<IProduct> = {};

            Object.entries(values.sections).forEach(([name, section]) => {
                section.parties?.forEach((party, index) => {
                    if (!party) {
                        set(errors, `sections.${name}.parties[${index}]`, t("required"));
                    }
                });
            });
            return errors;
        },
        onSubmit: async (values) => {
            const product = prepareData(values);
            dispatch(update(product));
        },
    });

    // AutoSave
    useEffect(() => {
        if (!formik.dirty) return;

        const timerId = setTimeout(() => {
            const params = {
                activeTab: ETab.Sections,
                product: prepareData(formik.values),
            };
            const savedProduct = storeProduct(params);
            dispatch(update(savedProduct));
            dispatch(autosave(Date.now()));
        }, DELAY_AUTO_SAVE);

        return () => clearTimeout(timerId);
    }, [formik.dirty, formik.values, dispatch, namespacedLocalStorage, prepareData]);

    const sectionNames = useMemo(() => {
        return formik.values.sectionDisplayOrder.map((it) => formik.values.sections[it].name);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.sections, formik.values.sectionDisplayOrder.length]);

    const getNewSectionName = () => {
        let count = formik.values.sectionDisplayOrder.length;
        let newProperty = `section${count}`;
        // if section with that name exists - select new name
        while (formik.values.sections[newProperty]) {
            count++;
            newProperty = `section${count}`;
        }
        return {
            count,
            property: newProperty,
        };
    };

    const handleAddSection = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation();
            event.preventDefault();

            const params = getNewSectionName();
            const newSections = {
                ...formik.values.sections,
                [params.property]: {
                    attachments: [],
                    idRange: null,
                    issues: {},
                    name: `${t("name")} ${params.count}`,
                    parties: null,
                    payment: { premiumSplits: defaultPremiumSplit },
                    ratings: null,
                },
            };
            formik.setFieldValue("sections", newSections, true);
            formik.setFieldValue("sectionDisplayOrder", [...formik.values.sectionDisplayOrder, params.property], true);
            setSelectedSection(params.property);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [formik, selectedSection]
    );

    const handleDeleteSection = useCallback(
        (enable: boolean) => {
            setOpenConfirmDelete(false);
            if (!enable) {
                return;
            }
            const { sectionDisplayOrder } = formik.values;
            const newSections = sectionDisplayOrder.filter((it) => it !== selectedSection);
            delete formik.values.sections[selectedSection];
            formik.setFieldValue("sections", { ...formik.values.sections }, true);
            formik.setFieldValue("sectionDisplayOrder", [...newSections], true);
            setSelectedSection(newSections[0]);
        },
        [formik, selectedSection]
    );

    const handleDuplicate = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation();
            event.preventDefault();

            const section = cloneDeep(formik.values.sections[selectedSection]);
            const params = getNewSectionName();
            const preffix = ` (${t("copy")})`;
            const newSections = {
                ...formik.values.sections,
                [params.property]: {
                    ...section,
                    name: `${section.name.replace(preffix, "")}${preffix}`,
                },
            };
            formik.setFieldValue("sections", newSections, true);
            formik.setFieldValue("sectionDisplayOrder", [...formik.values.sectionDisplayOrder, params.property], true);
            setSelectedSection(params.property);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [formik, selectedSection]
    );

    const handleSectionClick = useCallback(
        (event: React.ChangeEvent<unknown>, value: number) => {
            event.stopPropagation();
            event.preventDefault();

            if (formik.errors.sections) return;
            const selectedSection = formik.values.sectionDisplayOrder[value];
            setSelectedSection(selectedSection);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [formik.values.sections, selectedSection, formik.errors]
    );

    const renderLabel = (section: string) => {
        const { name } = formik.values.sections[section];
        const displayName = name.length > MAX_TAB_NAME ? `${name.substring(0, MAX_TAB_NAME)}...` : name;

        if (section === selectedSection) {
            return (
                <Grid container spacing={1} alignItems="center" wrap="nowrap">
                    {!isProd ? (
                        <>
                            <Grid item>
                                <IconButton color="primary" size="small" title={t("duplicateSection")} onClick={handleDuplicate}>
                                    <FileCopy />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <IconButton color="primary" size="small" title={t("deleteSection")} onClick={() => setOpenConfirmDelete(true)}>
                                    <Delete />
                                </IconButton>
                            </Grid>
                        </>
                    ) : null}
                    <Grid item>
                        <Box px={2}>
                            <Typography color="textPrimary" variant="caption">
                                {displayName}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            );
        } else {
            return displayName;
        }
    };

    // call methods from parent
    useImperativeHandle(ref, () => formik);

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container style={{ marginTop: -24 }}>
                <Grid item sm={12} md={10} lg={8} xl={6}>
                    <Grid container alignItems="center" wrap="nowrap">
                        <Grid item xs={9}>
                            {sectionNames.length ? (
                                <Tabs
                                    aria-label="tabs"
                                    className="sub"
                                    indicatorColor="primary"
                                    scrollButtons="auto"
                                    value={formik.values.sectionDisplayOrder.indexOf(selectedSection)}
                                    variant="scrollable"
                                    onChange={handleSectionClick}
                                >
                                    {formik.values.sectionDisplayOrder.map((section, index) => (
                                        <Tab className="sub-tab" component="span" label={renderLabel(section)} key={`tab-${section}-${index}`} />
                                    ))}
                                </Tabs>
                            ) : null}
                        </Grid>
                        <Grid item xs={3}>
                            {!isProd ? (
                                <Box textAlign="right">
                                    <Button endIcon={<Add />} variant="text" onClick={handleAddSection}>
                                        {t("addSection")}
                                    </Button>
                                </Box>
                            ) : null}
                        </Grid>
                    </Grid>
                    {selectedSection && (
                        <Grid container spacing={1} className={classes.subSection}>
                            <Overview formik={formik} selectedSection={selectedSection} />
                            <VisibleTo formik={formik} selectedSection={selectedSection} />
                            <Rating formik={formik} selectedSection={selectedSection} />
                            <Distribution formik={formik} selectedSection={selectedSection} />
                        </Grid>
                    )}
                </Grid>
            </Grid>
            <ConfirmDialog
                message={t("rUSureDeleteSection", { name: formik.values.sections[selectedSection]?.name.toUpperCase() })}
                open={openConfirmDelete}
                onClose={handleDeleteSection}
            />
        </form>
    );
};

export default forwardRef(TabSections);
