import React from "react";
import { Grid, Typography } from "@material-ui/core";
import useStyles from "../../../styles";
import { useTranslationNamespaces } from '@blocksure/blocksure-core/dist/src/utilities';
import { i18n } from "@surelync/common";

const TableHeader = () => {
    const classes = useStyles();
    const { t } = useTranslationNamespaces(i18n, ["surebyld", "surelync"]);

    return (
        <>
            <Grid item xs={4} classes={{ root: classes.py0 }}>
                <Typography align="right" component="p" variant="caption">
                    {t("name")}
                </Typography>
            </Grid>
            <Grid item xs={2} classes={{ root: classes.py0 }}>
                <Typography align="center" component="p" variant="caption">
                    {t("amount")}
                </Typography>
            </Grid>
            <Grid item xs={2} classes={{ root: classes.py0 }}>
                <Typography align="center" component="p" variant="caption">
                    {t("payer")}
                </Typography>
            </Grid>
            <Grid item xs={2} classes={{ root: classes.py0 }}>
                <Typography align="center" component="p" variant="caption">
                    {t("payee")}
                </Typography>
            </Grid>
        </>
    );
};

export default TableHeader;
