import React, { useCallback, useContext } from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { i18n } from '@surelync/common';
import { useTranslationNamespaces } from '@blocksure/blocksure-core/dist/src/utilities';
import { ProductContext } from '../../contexts';
import { advancedMode } from '../../reducer';

export const AdvancedModeCheckbox: React.FC = React.memo(() => {
    const [{ isAdvancedMode }, dispatch] = useContext(ProductContext);
    const { t } = useTranslationNamespaces(i18n, ["surebyld", "surelync"]);

    const handleEnableAdvancedMode = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(advancedMode(event.target.checked));
    }, [isAdvancedMode])

    return (
        <FormControlLabel
            label={t("advancedUsers")}
            control={
                <Checkbox
                    checked={isAdvancedMode}
                    color={isAdvancedMode ? "primary" : "secondary"}
                    onChange={handleEnableAdvancedMode}
                />
            }
        />
    );
});
