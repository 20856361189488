import React, { Fragment, useContext, useMemo } from "react";
import { Box, Button, Card, CardContent, CardHeader, Divider, Grid, IconButton, Typography, useTheme } from "@material-ui/core";
import { useTranslationNamespaces } from '@blocksure/blocksure-core/dist/src/utilities';
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import { i18n, InputCustom, IProduct, SelectCustom } from "@surelync/common";
import { IFormik } from "../../../models";
import useStyles from "../../../styles";
import GlobalContext from "../../../../context/global-context";
import { optionsFrequency } from "../constants";
import { applyDenomination, applyNumber, parseDenomination, parseNumber } from "./helpers";

interface IProps {
    formik: IFormik<IProduct>;
}

const TermsAndFrequencies: React.FC<IProps> = ({ formik }) => {
    const { isProd } = useContext(GlobalContext);
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslationNamespaces(i18n, ["surebyld", "surelync"]);

    const handleAdd = (propertyName: string) => {
        const property = formik.values[propertyName];
        property.push("");
        formik.setFieldValue(propertyName, [...property]);
    };

    const handleChangeNumber = (propertyName: string, index?: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.currentTarget;
        const property = formik.values[propertyName];
        const propertyValue = index !== undefined ? property[index] : property;
        const newNumber = applyNumber(propertyValue, value);

        // if is array
        if (index !== undefined) {
            property[index] = newNumber;
            formik.setFieldValue(propertyName, [...property]);
        } else {
            formik.setFieldValue(propertyName, newNumber);
        }

        formik.setFieldTouched(propertyName, true, true);
    };

    const handleChangeDenomination = (propertyName: string, index?: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event) {
            return;
        }

        const { value: newDenomination } = event.target;
        const property = formik.values[propertyName];
        const propertyValue = index !== undefined ? property[index] : property;
        const newValue = applyDenomination(propertyValue, newDenomination);

        // if is array
        if (index !== undefined) {
            property[index] = newValue;
            formik.setFieldValue(propertyName, [...property]);
        } else {
            formik.setFieldValue(propertyName, newValue);
        }
        formik.setFieldTouched(propertyName, true, true);
    };

    const handleRemove = (propertyName: string, index: number) => () => {
        const property = formik.values[propertyName];
        property.splice(index, 1);
        formik.setFieldValue(propertyName, [...property]);
        formik.setFieldTouched(propertyName, true, true);
    };

    const getError = (index: number): string | null => {
        const { validPayFrequencies } = formik.errors;
        return validPayFrequencies && validPayFrequencies[index] ? validPayFrequencies[index] : null;
    };

    const optionsPaymentFrequency = useMemo(
        () => [
            {
                label: t("hour"),
                value: "PT1H",
            },
            {
                label: t("day"),
                value: "P1D",
            },
            {
                label: t("week"),
                value: "P1W",
            },
            {
                label: t("month"),
                value: "P1M",
            },
            {
                label: t("year"),
                value: "P1Y",
            },
        ],
        []
    );

    return (
        <Grid item sm={12}>
            <Card variant="outlined">
                <CardHeader classes={{ root: classes.root, title: classes.title }} title={t("termsAndFrequencies")} />
                <CardContent>
                    <Grid container spacing={3}>
                        {formik.errors.validPayFrequencies && !Array.isArray(formik.errors.validPayFrequencies) ? (
                            <>
                                <Grid item xs={6} classes={{ root: classes.py0 }}>
                                    <Typography color="error" variant="body1">
                                        {t("validPayFrequencies")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} classes={{ root: classes.py0 }}>
                                    <Typography color="error" variant="body2">
                                        {formik.errors.validPayFrequencies}
                                    </Typography>
                                </Grid>
                            </>
                        ) : null}
                        {formik.values.validPayFrequencies.map((it, index) => (
                            <Fragment key={index}>
                                <Grid item xs={6} classes={{ root: classes.py0 }}>
                                    {index === 0 && (
                                        <Typography align="right" variant="body1">
                                            {t("validPayFrequencies")}
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid item xs={3} classes={{ root: classes.py0 }}>
                                    <SelectCustom
                                        data-testid={`payment-frequency-${index}`}
                                        error={getError(index)}
                                        name={`validPayFrequencies[${index}]`}
                                        options={optionsPaymentFrequency}
                                        readOnly={isProd}
                                        theme={theme}
                                        touched={formik.touched.validPayFrequencies}
                                        value={formik.values.validPayFrequencies[index]}
                                        onChange={formik.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={1} classes={{ root: classes.py0 }}>
                                    {!isProd ? (
                                        <IconButton
                                            color="primary"
                                            data-testid={`delete-valid-pay-freq-${index}`}
                                            size="small"
                                            onClick={handleRemove("validPayFrequencies", index)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    ) : null}
                                </Grid>
                                {!isProd && index === 0 ? (
                                    <Grid item xs={2} classes={{ root: classes.py0 }}>
                                        <Box textAlign="right">
                                            <Button endIcon={<AddIcon />} variant="text" onClick={() => handleAdd("validPayFrequencies")}>
                                                {t("add")}
                                            </Button>
                                        </Box>
                                    </Grid>
                                ) : null}
                            </Fragment>
                        ))}

                        <Grid item xs={12}>
                            <Divider />
                        </Grid>

                        {formik.errors.validPolicyTerms && !Array.isArray(formik.errors.validPolicyTerms) ? (
                            <>
                                <Grid item xs={6} classes={{ root: classes.py0 }}>
                                    <Typography color="error" variant="body1">
                                        {t("validPolicyTerms")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} classes={{ root: classes.py0 }}>
                                    <Typography color="error" variant="body2">
                                        {formik.errors.validPolicyTerms}
                                    </Typography>
                                </Grid>
                            </>
                        ) : null}
                        {formik.values.validPolicyTerms.map((it, index) => (
                            <Fragment key={index}>
                                <Grid item xs={6} classes={{ root: classes.py0 }}>
                                    {index === 0 && (
                                        <Typography align="right" variant="body1">
                                            {t("validPolicyTerms")}
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid item xs={1} classes={{ root: classes.py0 }}>
                                    <InputCustom
                                        autoComplete="off"
                                        data-testid={`policy-number-${index}`}
                                        error={getError(index)}
                                        readOnly={isProd}
                                        theme={theme}
                                        touched={formik.touched.validPolicyTerms}
                                        value={parseNumber(it)}
                                        onBlur={() => formik.setFieldTouched("validPolicyTerms", true, true)}
                                        onChange={handleChangeNumber("validPolicyTerms", index)}
                                    />
                                </Grid>
                                <Grid item xs={2} classes={{ root: classes.py0 }}>
                                    <SelectCustom
                                        error={getError(index)}
                                        options={optionsFrequency(t)}
                                        readOnly={isProd}
                                        theme={theme}
                                        touched={formik.touched.validPolicyTerms}
                                        value={parseDenomination(it)}
                                        onBlur={() => formik.setFieldTouched("validPolicyTerms", true, true)}
                                        onChange={handleChangeDenomination("validPolicyTerms", index)}
                                    />
                                </Grid>
                                <Grid item xs={1} classes={{ root: classes.py0 }}>
                                    {!isProd ? (
                                        <IconButton
                                            color="primary"
                                            data-testid={`delete-${index}`}
                                            size="small"
                                            onClick={handleRemove("validPolicyTerms", index)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    ) : null}
                                </Grid>
                                {!isProd && index === 0 ? (
                                    <Grid item xs={2} classes={{ root: classes.py0 }}>
                                        <Box textAlign="right">
                                            <Button endIcon={<AddIcon />} variant="text" onClick={() => handleAdd("validPolicyTerms")}>
                                                {t("add")}
                                            </Button>
                                        </Box>
                                    </Grid>
                                ) : null}
                            </Fragment>
                        ))}

                        <Grid item xs={12}>
                            <Divider />
                        </Grid>

                        <Grid item xs={6} classes={{ root: classes.py0 }}>
                            <Typography align="right" variant="body1">
                                {t("coolingOffPeriod")}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} classes={{ root: classes.py0 }}>
                            <InputCustom
                                autoComplete="off"
                                data-testid="cooling-off-period-freq"
                                error={formik.errors.coolingOffPeriod}
                                readOnly={isProd}
                                theme={theme}
                                touched={formik.touched.coolingOffPeriod}
                                value={parseNumber(formik.values.coolingOffPeriod)}
                                onBlur={() => formik.setFieldTouched("coolingOffPeriod", true, true)}
                                onChange={handleChangeNumber("coolingOffPeriod")}
                            />
                        </Grid>
                        <Grid item xs={2} classes={{ root: classes.py0 }}>
                            <SelectCustom
                                data-testid="cooling-off-period-denomination"
                                error={formik.errors.coolingOffPeriod}
                                options={optionsFrequency(t)}
                                readOnly={isProd}
                                theme={theme}
                                touched={formik.touched.coolingOffPeriod}
                                value={parseDenomination(formik.values.coolingOffPeriod)}
                                onBlur={() => formik.setFieldTouched("coolingOffPeriod", true, true)}
                                onChange={handleChangeDenomination("coolingOffPeriod")}
                            />
                        </Grid>
                        <Grid item xs={3} classes={{ root: classes.py0 }} />
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>

                        <Grid item xs={6} classes={{ root: classes.py0 }}>
                            <Typography align="right" variant="body1">
                                {t("quoteValidity")}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} classes={{ root: classes.py0 }}>
                            <InputCustom
                                autoComplete="off"
                                error={formik.errors.quoteValidity}
                                readOnly={isProd}
                                theme={theme}
                                touched={formik.touched.quoteValidity}
                                value={parseNumber(formik.values.quoteValidity)}
                                onBlur={() => formik.setFieldTouched("quoteValidity", true, true)}
                                onChange={handleChangeNumber("quoteValidity")}
                            />
                        </Grid>
                        <Grid item xs={2} classes={{ root: classes.py0 }}>
                            <SelectCustom
                                error={formik.errors.quoteValidity}
                                options={optionsFrequency(t)}
                                readOnly={isProd}
                                theme={theme}
                                touched={formik.touched.quoteValidity}
                                value={parseDenomination(formik.values.quoteValidity)}
                                onBlur={() => formik.setFieldTouched("quoteValidity", true, true)}
                                onChange={handleChangeDenomination("quoteValidity")}
                            />
                        </Grid>
                        <Grid item xs={3} classes={{ root: classes.py0 }} />
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
};

export default TermsAndFrequencies;
