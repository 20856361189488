import React, { useContext } from "react";
import { Box, Card, CardContent, Checkbox, FormControlLabel, Grid, Hidden, IconButton, Typography, useTheme } from "@material-ui/core";
import { useTranslationNamespaces } from '@blocksure/blocksure-core/dist/src/utilities';
import { IFormik } from "../../../../models";
import get from "lodash/get";
import { IDataDictionary } from "../../data-dictionary.model";
import { i18n, InputCustom, SelectCustom } from "@surelync/common";
import { IStringWidget } from "../../group.model";
import DeleteIcon from "@material-ui/icons/Delete";
import useStyles from "../../../../styles";
import GlobalContext from "../../../../../context/global-context";
import { handleFocus, isReadOnly, optionsType } from "../../helper";
import { ProductContext } from "../../../../contexts";

interface IProps {
    countryLabel: string;
    isAdvancedMode: boolean;
    formik: IFormik<IDataDictionary>;
    property: string;
    section: IStringWidget;
    onAutoGenerateSystemID: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onDropdown: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onRemove: () => void;
    onRequired: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const StringWidgets: React.FC<IProps> = ({ countryLabel, isAdvancedMode, formik, property, section, onAutoGenerateSystemID, onDropdown, onRemove, onRequired }) => {
    const { isProd } = useContext(GlobalContext);
    const [, dispatch] = useContext(ProductContext);
    const classes = useStyles();
    const { t } = useTranslationNamespaces(i18n, ["surebyld", "surelync"]);
    const theme = useTheme();

    const getPropPath = (name: string) => `${property}.${name}`;
    const readOnly = isReadOnly(isProd, section);

    return (
        <Grid container spacing={1} key={`section-${property}`}>
            <Grid item xs={12}>
                <Card variant="outlined" classes={{ root: readOnly ? classes.requiredCard : null }}>
                    <CardContent>
                        {readOnly ? <Box mb={3}>
                            <Grid item xs={12}>
                                <Typography variant="caption">{t("fieldRestriction", { country: countryLabel })}</Typography>
                            </Grid>
                        </Box> : null}
                        <Grid container spacing={3} alignItems="flex-end">
                            <Grid item xs={11}>
                                <InputCustom
                                    autoComplete="off"
                                    error={get(formik.errors, getPropPath("fieldName"))}
                                    label={`${t("field")} ${t("name")} (${t("displayedToAllParties")})`}
                                    name={getPropPath("fieldName")}
                                    readOnly={isProd}
                                    required
                                    theme={theme}
                                    touched={get(formik.touched, getPropPath("fieldName"))}
                                    value={section.fieldName}
                                    onBlur={onAutoGenerateSystemID}
                                    onChange={formik.handleChange}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                {!isProd ? (
                                    <Box textAlign="right">
                                        <IconButton
                                            color="primary"
                                            data-testid={`delete-${property}`}
                                            disabled={section.isFromUnersalSchema}
                                            size="small"
                                            onClick={onRemove}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Box>
                                ) : null}
                            </Grid>
                            <Hidden xsUp={!isAdvancedMode}>
                                <Grid item xs={6}>
                                    <InputCustom
                                        autoComplete="off"
                                        error={get(formik.errors, getPropPath("fieldSystemInternalId"))}
                                        label={`${t("field")} ${t("id")} (${t("systemInternal")} ${t("id")})`}
                                        name={getPropPath("fieldSystemInternalId")}
                                        readOnly={readOnly}
                                        required
                                        theme={theme}
                                        touched={get(formik.touched, getPropPath("fieldSystemInternalId"))}
                                        value={section.fieldSystemInternalId}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        onFocus={readOnly ? null : handleFocus(section.fieldSystemInternalId, get(formik.touched, getPropPath("fieldSystemInternalId")), dispatch)}
                                    />
                                </Grid>
                            </Hidden>
                            <Grid item xs={3}>
                                <SelectCustom
                                    name={getPropPath("type")}
                                    label={t("fieldType")}
                                    options={optionsType.map(({label, value}) => ({label: t(label), value}))}
                                    readOnly={readOnly}
                                    required
                                    theme={theme}
                                    value={section.fieldType}
                                    onChange={formik.handleChange}
                                />
                            </Grid>
                            {section.fieldType === "string" ? (
                                <Grid item xs={2}>
                                    <InputCustom
                                        autoComplete="off"
                                        error={get(formik.errors, getPropPath("fieldSize"))}
                                        label={t("size")}
                                        name={getPropPath("fieldSize")}
                                        readOnly={readOnly}
                                        required
                                        theme={theme}
                                        touched={get(formik.touched, getPropPath("fieldSize"))}
                                        type="number"
                                        value={section.fieldSize?.toString() || "10"}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                    />
                                </Grid>
                            ) : (isAdvancedMode ? <Grid item xs={2} /> : null)}

                            <Grid item xs={3} classes={{ root: classes.py0 }}>
                                <Box pb={1}>
                                    <FormControlLabel
                                        label={t("required")}
                                        disabled={readOnly}
                                        control={
                                            <Checkbox
                                                checked={section.isRequired}
                                                color={section.isRequired ? "primary" : "secondary"}
                                                onChange={onRequired}
                                            />
                                        }
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={3} classes={{ root: classes.py0 }}>
                                <Box pb={1}>
                                    <FormControlLabel
                                        label={t("dropdown")}
                                        disabled={readOnly}
                                        control={
                                            <Checkbox
                                                checked={false}
                                                color="secondary"
                                                onChange={onDropdown}
                                            />
                                        }
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default React.memo(StringWidgets);
