import React, { useContext } from "react";
import { Box, Card, CardContent, CardHeader, Grid, Link, Typography } from "@material-ui/core";
import { useTranslationNamespaces } from '@blocksure/blocksure-core/dist/src/utilities';
import { i18n, IProduct } from "@surelync/common";
import { generateConfluenceSpace, generateConfluenceLink } from '@blocksure/blocksure-core/dist/src/utilities/generateConfluenceData';
import { IFormik } from "../../../models";
import Attachment from "../../Attachment/Attachment";
import { ProductContext } from "../../../contexts";
import { getLocationPath, processAttachment, RATINGS_FILE_NAME, removeAttachment } from "../../../../utils/helper";
import useStyles from "../../../styles";
import GlobalContext from "../../../../context/global-context";

const PROPERTY_NAME = "attachmentRatings";

interface IProps {
    formik: IFormik<IProduct>;
}

const Ratings: React.FC<IProps> = ({ formik }) => {
    const { isProd, locale } = useContext(GlobalContext);
    const [{ data, files }, dispatch] = useContext(ProductContext);
    const classes = useStyles();
    const { t } = useTranslationNamespaces(i18n, ["surebyld", "surelync"]);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        processAttachment(event.target.files[0], formik, dispatch, PROPERTY_NAME, RATINGS_FILE_NAME, files);
    };

    const handleRemoveAttachment = () => {
        removeAttachment(formik, dispatch, files, PROPERTY_NAME);
    };

    const value = generateConfluenceSpace(locale);

    return (
        <Grid item sm={12}>
            <Card variant="outlined">
                <CardHeader classes={{ root: classes.root, title: classes.title }} title={t("ratingSpreadsheet")} />
                <CardContent>
                    <Attachment
                        acceptFiles={["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
                        attachment={formik.values[PROPERTY_NAME]}
                        error={formik.errors[PROPERTY_NAME] as string}
                        files={files}
                        helpText={(
                            <Box mt={2}>
                                <Typography align="left" variant="body1">
                                    {t("youMayDownloadRatingSheet")}
                                </Typography>
                                <Link target="_blank" href={generateConfluenceLink(value)} rel="noopener">{t("helpCompletingRating")}</Link>
                            </Box>
                        )}
                        href={getLocationPath(data?.id, formik.values[PROPERTY_NAME]?.location)}
                        name={formik.values[PROPERTY_NAME]?.name}
                        readOnly={isProd}
                        touched={!!formik.touched[PROPERTY_NAME]}
                        value={formik.values[PROPERTY_NAME]?.name || ""}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        onFileChange={handleFileChange}
                        onRemove={handleRemoveAttachment}
                    />
                </CardContent>
            </Card>
        </Grid>
    );
};

export default Ratings;
