import { ITableData } from "@surelync/common";
import { EDisplayMode } from "./constants";

export type TabType<T> = { id: T; title: string };

enum ActionType {
    FetchStarted,
    FetchDone,
    FetchFailure,
    ResetActions,
    SetActiveTab,
    SetEnvWarnModal,
    SetDeleteProduct,
    SetMessage,
    SetTabs,
}

interface IActionFetchStarted {
    type: ActionType.FetchStarted;
}

interface IActionFetchDone {
    type: ActionType.FetchDone;
    payload: { data: ITableData[], total: number };
}

interface IActionFetchFailure {
    type: ActionType.FetchFailure;
    payload: string;
}

interface IActionResetActions {
    type: ActionType.ResetActions;
}

interface IActionSetEnvWarnModal {
    type: ActionType.SetEnvWarnModal;
    payload: boolean;
}

interface IActionSetActiveTab {
    type: ActionType.SetActiveTab;
    payload: number;
}

interface IActionSetDeleteProduct {
    type: ActionType.SetDeleteProduct;
    payload: IDeletable;
}

interface IActionSetMessage {
    type: ActionType.SetMessage;
    payload: string;
}

interface IActionSetTabs {
    type: ActionType.SetTabs;
    payload: Array<TabType<EDisplayMode>>;
}

export interface IDeletable { localStorageKey: string; name: string }

export type Action =
    | IActionFetchDone
    | IActionFetchFailure
    | IActionFetchStarted
    | IActionResetActions
    | IActionSetEnvWarnModal
    | IActionSetActiveTab
    | IActionSetDeleteProduct
    | IActionSetMessage
    | IActionSetTabs;

export const request = (): IActionFetchStarted => ({ type: ActionType.FetchStarted });
export const failure = (message: string): IActionFetchFailure => ({ type: ActionType.FetchFailure, payload: message });
export const success = (payload: { data: ITableData[], total: number }): IActionFetchDone => ({ type: ActionType.FetchDone, payload });

export const setActiveTab = (value: number): IActionSetActiveTab => ({ type: ActionType.SetActiveTab, payload: value });
export const setDeleteProduct = (value: IDeletable): IActionSetDeleteProduct => ({ type: ActionType.SetDeleteProduct, payload: value });
export const setEnvWarnModal = (enable: boolean): IActionSetEnvWarnModal => ({ type: ActionType.SetEnvWarnModal, payload: enable });
export const setMessage = (value: string): IActionSetMessage => ({ type: ActionType.SetMessage, payload: value });
export const setTabs = (tabs: Array<TabType<EDisplayMode>>): IActionSetTabs => ({ type: ActionType.SetTabs, payload: tabs });
export const resetActions = (): IActionResetActions => ({ type: ActionType.ResetActions });

export type ProductState = {
    activeTab: number;
    data: ITableData[];
    deleteProduct: IDeletable;
    error: string;
    isLoading: boolean;
    message: string;
    openEnvWarnModal: boolean;
    tabs: Array<TabType<EDisplayMode>>;
    total: number;
};

export const initialState: ProductState = {
    activeTab: null,
    data: null,
    deleteProduct: null,
    error: null,
    isLoading: false,
    message: null,
    openEnvWarnModal: false,
    tabs: null,
    total: 0,
};

export function reducer(state: ProductState = initialState, action: Action): ProductState {
    switch (action.type) {
        case ActionType.FetchStarted:
            return { ...state, data: null, error: null, isLoading: true, message: null };
        case ActionType.FetchDone:
            return { ...state, isLoading: false, data: action.payload.data, total: action.payload.total };
        case ActionType.FetchFailure:
            return { ...state, isLoading: false, error: action.payload };
        case ActionType.ResetActions:
            return { ...state, error: null, message: null };
        case ActionType.SetActiveTab:
            return { ...state, activeTab: action.payload };
        case ActionType.SetEnvWarnModal:
            return { ...state, openEnvWarnModal: action.payload };
        case ActionType.SetDeleteProduct:
            return { ...state, deleteProduct: action.payload, error: null, message: null };
        case ActionType.SetMessage:
            return { ...state, message: action.payload };
        case ActionType.SetTabs:
            return { ...state, tabs: action.payload };
        default:
            return state;
    }
}
