import React, { useContext, useState } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Grid } from "@material-ui/core";
import { ActionParams } from "../columns.config";
import { EApprovalStatus, i18n, isEnvProdLike, isEnvUATLike } from "@surelync/common";
import { useTranslationNamespaces } from '@blocksure/blocksure-core/dist/src/utilities';
import GlobalContext from "../../context/global-context";
import useStyles from "../styles";

interface IProps {
    data: {[key: string]: string};
    onAction: (params: ActionParams) => void;
}

const ActionsMenu: React.FC<IProps> = ({data, onAction}) => {
    const { linkedEnv } = useContext(GlobalContext);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const { t } = useTranslationNamespaces(i18n, ["surebyld", "surelync"]);
    const classes = useStyles();

    const { approvalStatus, id: productId, link, localStorageKey, name, status } = data;
    const isDraft = status === "Draft";
    const items = [];

    let key = Date.now();

    const handleClick = (params: ActionParams) => {
        setAnchorEl(null);
        onAction(params);
    }

    if (approvalStatus === EApprovalStatus.AwaitingYourApproval) {
        items.push(<MenuItem key={key++} onClick={() => handleClick({ productId, type: "approve" })}>{t("approve")}</MenuItem>);

        // Active Product menu items
    } else if (productId && !isDraft) {
        items.push(<MenuItem key={key++} onClick={() => handleClick({ productId, type: "copy" })}>{t("copy")}</MenuItem>);
        items.push(<MenuItem key={key++} onClick={() => handleClick({ link, type: "edit" })}>{t("edit")}</MenuItem>);
        items.push(<MenuItem key={key++} onClick={() => handleClick({ productId, type: "export" })}>{t("export")}</MenuItem>);

        // Show "Promote To Next Level" option if linked env exists and it is not a PROD
        if (linkedEnv && !isEnvProdLike() && approvalStatus === EApprovalStatus.Approved) {
            items.push(
                <MenuItem key={key++} onClick={() => handleClick({ productId, type: "promoteFromLinkedEnv" })}>
                    {isEnvUATLike() ? t("promoteToProduction") : t("promoteToNextLevel")}
                </MenuItem>
            );
        }

    // Draft Product menu items
    } else if (isDraft) {
        items.push(<MenuItem key={key++} onClick={() => handleClick({ localStorageKey, name, type: "delete" })}>{t("delete")}</MenuItem>);
    }

    return (
        <Grid item>
            <Button
                aria-controls="actions-menu"
                aria-haspopup="true"
                classes={{root: classes.actionBtn}}
                variant="text"
                onClick={(event) => setAnchorEl(event.currentTarget)}
            >
                {t("actions")} <ArrowDropDownIcon />
            </Button>
            <Menu
                id="select-action"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                onClose={() => setAnchorEl(null)}
            >
                {items}
            </Menu>
        </Grid>
    );
};

export default React.memo(ActionsMenu);
