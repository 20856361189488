import React, { useContext } from 'react';
import { Grid } from '@material-ui/core';
import { Draggable, Droppable } from "react-beautiful-dnd";
import { FIELDS } from '../TabSureApp';
import { ProductContext } from '../../../contexts';
import { Tag } from './Tag';
import useStyles from "../../../styles";
import { UISchemaField } from '../ui-shema.model';
import { getName, getSystemInternalId } from '../helper';

interface Props {
    data: UISchemaField[];
}

export const FieldList: React.FC<Props> = React.memo(({ data }) => {
    const [{ isAdvancedMode }] = useContext(ProductContext);
    const classes = useStyles();

    return (
        <Droppable droppableId={FIELDS}>
            {(provided, snapshot) => (
                <Grid
                    item
                    xs={4}
                    xl={6}
                    classes={{ root: snapshot.isDraggingOver ? classes.dropOver : classes.droppable }}
                    className={classes.wrapperFields}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                >
                    <div className={classes.wrapperList}>
                        {data.map((field, index) => (
                            <Draggable draggableId={`field-${index}`} index={index} key={`field-${index}`}>
                                {(provided) => (
                                    <span ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                        <Tag
                                            label={isAdvancedMode ? getSystemInternalId(field) : getName(field)}
                                            tooltip={isAdvancedMode ? getName(field) : getSystemInternalId(field)}
                                        />
                                    </span>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                </Grid>
            )}
        </Droppable>
    );
});
