import React, { forwardRef, useCallback, useContext, useEffect, useImperativeHandle } from "react";
import { useTheme } from "@material-ui/core/styles";
import { useTranslationNamespaces } from '@blocksure/blocksure-core/dist/src/utilities';
import { IAttachment, InputCustom, IProduct, i18n } from "@surelync/common";
import { useFormik } from "formik";
import { DELAY_AUTO_SAVE, ETab, ProductContext } from "../../contexts";
import { autosave, setTitle, update } from "../../reducer";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import useStyles from "../../styles";
import omit from "lodash/omit";
import Attachment from "../Attachment/Attachment";
import {
    createAttachmentMetadata,
    getAttachmentByFileName,
    getLocationPath,
    LOGO_FILE_NAME,
    processAttachment,
    removeAttachment,
    storeProduct,
} from "../../../utils";
import IDSettings from "./components/IDSettings";
import Summary from "./components/Summary";
import PartiesAndRoles from "./components/PartiesAndRoles";
import TermsAndFrequencies from "./components/TermsAndFrequencies";
import RenewalOffsets from "./components/RenewalOffsets";
import validateProduct from "../../../utils/validate-product";
import GlobalContext from "../../../context/global-context";
import { useParams } from "react-router-dom";
import ContactsIcon from '@material-ui/icons/Contacts';
import { InputAdornment } from "@material-ui/core";

type IProductWithLogo = IProduct & { attachmentLogo: IAttachment };

type IProps = Record<string, unknown>

const TabSummary: React.ForwardRefRenderFunction<Record<string, unknown>, IProps> = (props, ref) => {
    const { isProd } = useContext(GlobalContext);
    const [{ data, files }, dispatch] = useContext(ProductContext);
    const { id } = useParams<{ id: string; version: string }>();
    const { t } = useTranslationNamespaces(i18n, ["surebyld", "surelync"]);
    const theme = useTheme();
    const classes = useStyles();

    const prepareData = useCallback(
        (values: IProductWithLogo): IProduct => {
            const product = omit(values, "attachmentLogo");
            // name can be edited after attaching new file
            const staticAttachments = data.staticAttachments.filter((it) => it.name !== LOGO_FILE_NAME);
            if (values.attachmentLogo) {
                staticAttachments.push(values.attachmentLogo);
            }
            product.staticAttachments = staticAttachments.map((attachment) => {
                const newFile = files[attachment?.location];
                if (newFile) {
                    newFile.name = LOGO_FILE_NAME;
                    return {
                        ...attachment,
                        ...createAttachmentMetadata(newFile),
                        name: LOGO_FILE_NAME,
                    };
                } else {
                    return attachment;
                }
            });
            return product;
        },
        [data.staticAttachments, files]
    );

    const formik = useFormik<IProductWithLogo>({
        initialValues: {
            ...data,
            attachmentLogo: getAttachmentByFileName(data.staticAttachments, LOGO_FILE_NAME),
        },
        enableReinitialize: true,
        validate: validateProduct,
        onSubmit: (values) => {
            const product: IProduct = prepareData(values);
            dispatch(update(product));
        },
    });

    useEffect(() => {
        const prefix = id !== "new" ? t("product") : t("createProduct");
        dispatch(setTitle(`${prefix} - ${formik.values.name}`));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.name]);

    // AutoSave
    useEffect(() => {
        if (!formik.dirty) return;

        const timerId = setTimeout(() => {
            const params = {
                activeTab: ETab.Settings,
                product: prepareData(formik.values),
            };
            const savedProduct = storeProduct(params);
            dispatch(update(savedProduct));
            dispatch(autosave(Date.now()));
        }, DELAY_AUTO_SAVE);

        return () => clearTimeout(timerId);
    }, [formik.dirty, formik.values, dispatch, prepareData]);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        processAttachment(event.target.files[0], formik, dispatch, "attachmentLogo", LOGO_FILE_NAME, files);
    };

    const handleRemoveAttachment = () => {
        removeAttachment(formik, dispatch, files, "attachmentLogo");
    };

    // call methods from parent
    useImperativeHandle(ref, () => formik);

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container>
                <Grid item sm={12} md={10} lg={8} xl={6}>
                    <Grid container spacing={1}>
                        <Grid item sm={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <InputCustom
                                        autoComplete="off"
                                        data-testid="name"
                                        error={formik.errors.name as string}
                                        label={t("productName")}
                                        name="name"
                                        readOnly={isProd}
                                        required
                                        theme={theme}
                                        touched={!!formik.touched.name}
                                        value={formik.values.name}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputCustom
                                        autoComplete="off"
                                        data-testid="description"
                                        error={formik.errors.description as string}
                                        label={t("descriptionSureByld")}
                                        maxLength={1000}
                                        multiline
                                        name="description"
                                        readOnly={isProd}
                                        minRows={2}
                                        maxRows={2}
                                        theme={theme}
                                        touched={!!formik.touched.description}
                                        value={formik.values.description}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputCustom
                                        autoComplete="off"
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <ContactsIcon color="disabled" />
                                            </InputAdornment>
                                        }
                                        error={formik.errors.owner as string}
                                        label={t("productOwner")}
                                        name="owner"
                                        readOnly={isProd}
                                        required
                                        theme={theme}
                                        touched={!!formik.touched.owner}
                                        value={formik.values.owner}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sm={12}>
                            <Card variant="outlined">
                                <CardHeader classes={{ root: classes.root, title: classes.title }} title={t("productLogo")} />
                                <CardContent>
                                    <Attachment
                                        acceptFiles={["image/png"]}
                                        attachment={formik.values.attachmentLogo}
                                        error={formik.errors.attachmentLogo as string}
                                        files={files}
                                        href={getLocationPath(data?.id, formik.values.attachmentLogo?.location)}
                                        name={formik.values.attachmentLogo?.name}
                                        readOnly={isProd}
                                        touched={!!formik.touched.attachmentLogo}
                                        value={formik.values.attachmentLogo?.name || ""}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        onFileChange={handleFileChange}
                                        onRemove={handleRemoveAttachment}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>

                        <Summary formik={formik} />
                        <IDSettings formik={formik} />
                        <PartiesAndRoles formik={formik} />
                        <TermsAndFrequencies formik={formik} />
                        <RenewalOffsets formik={formik} />
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

export default forwardRef(TabSummary);
