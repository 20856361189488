import { IProduct, namespacedLocalStorage } from "@surelync/common";
import { DateTime } from "luxon";
import { AUTO_SAVE_KEY } from "../@EditProductPage/contexts";
import { IDraftProduct } from "../@EditProductPage/models/draft-product.model";

export function storeProduct(params: IDraftProduct): IProduct {
    const { product } = params;

    product.state = "Draft";
    product.ts = DateTime.utc().toISO();

    let storeKey: string;
    if (product.id) {
        storeKey = product.id;
    } else if (product.idDraft) {
        storeKey = product.idDraft.toString();
    } else {
        product.idDraft = new Date(product.ts).getTime();
        storeKey = product.idDraft.toString();
    }

    namespacedLocalStorage.setItem(`${AUTO_SAVE_KEY}_${storeKey}`, JSON.stringify(params));
    return product;
}

export function loadDraftProduct(id: string): IDraftProduct | null {
    const result = namespacedLocalStorage.getItem(`${AUTO_SAVE_KEY}_${id}`);
    if (result) {
        try {
            return JSON.parse(result);
        } catch (err) {
            return null;
        }
    }
}

export function loadDraftProducts(): IProduct[] | null {
    return Object.keys(localStorage).reduce((arr, key) => {
        if (key.includes(AUTO_SAVE_KEY)) {
            const item = localStorage.getItem(key);
            const savedDate = JSON.parse(item);
            arr.push(savedDate.product);
        }
        return arr;
    }, []);
}
