import { EAttachmentTransaction, i18n, IAttachment, IProduct } from "@surelync/common";
import { LOGO_FILE_NAME } from "./helper";
import camelCase from "lodash/camelCase";

export default function validateProduct(values: IProduct & { attachmentLogo: IAttachment }) {
    const errors: { [key: string]: string | Array<{ [key: string]: string }> | Array<string[]> } = {};

    if (!values.name.trim()) {
        errors.name = i18n.t("required");
    }

    if (!values.owner.trim()) {
        errors.owner = i18n.t("required");
    }

    if (values.attachmentLogo && values.attachmentLogo.name !== LOGO_FILE_NAME) {
        errors.attachmentLogo = "Invalid name. Expected 'logo.png'";
    }

    if (!values.insuranceType) {
        errors.insuranceType = i18n.t("required");
    }

    if (!values.country) {
        errors.country = i18n.t("required");
    }

    if (!values.payment.currency) {
        errors.currency = i18n.t("required");
    }

    if (!values.language) {
        errors.language = i18n.t("required");
    }

    if (!values.refundMode) {
        errors.refundMode = i18n.t("required");
    }

    if (!values.idRanges.policies.start) {
        errors.policiesStart = i18n.t("required");
    }

    if (!values.idRanges.policies.end) {
        errors.policiesEnd = i18n.t("required");
    }

    if (!values.idRanges.claims.start) {
        errors.claimsStart = i18n.t("required");
    }

    if (!values.idRanges.claims.end) {
        errors.claimsEnd = i18n.t("required");
    }

    const partiesAndRoles = Object.entries(values.partiesAndRoles).map(([name, party]: [string, string]) => ({ name, party }));
    if (!partiesAndRoles || !partiesAndRoles.length) {
        errors.partiesAndRoles = i18n.t("required");
    } else {
        const result = [];
        partiesAndRoles.forEach((it, index) => {
            let name: string;
            let party: string;
            if (!it.name.trim()) {
                name = i18n.t("required");
            }
            if (!it.party.trim()) {
                party = i18n.t("required");
            }
            if (name || party) {
                result[index] = { name, party };
            }
        });

        if (result.length) {
            errors.partiesAndRoles = result;
        }
    }

    if (!values.validPayFrequencies || !values.validPayFrequencies.length) {
        errors.validPayFrequencies = i18n.t("required");
    } else {
        const result = [];
        values.validPayFrequencies.forEach((it, index) => {
            if (!it.trim()) {
                result[index] = i18n.t("required");
            }
        });
        if (result.length) {
            errors.validPayFrequencies = result;
        }
    }

    return errors;
}

export function validateRenewals(values: IProduct, t): Array<string[]> | null {
    const errors = [];
    const transactions = [EAttachmentTransaction.RenewalInvite, EAttachmentTransaction.PreRenewalReminder, EAttachmentTransaction.LapseWarning];

    values.notifications.forEach((notification, index) => {
        if (transactions.includes(notification.transaction)) {
            const { offsets } = notification;
            // if exists empty values
            if (!offsets || offsets.filter((it) => Boolean(it)).length !== offsets.length ) {
                errors.push([t("renewalsValidationLine1"), t("renewalsValidationLine2", { fieldName: t(camelCase(notification.transaction)) })]);
            }
        }
    });

    return errors.length ? errors : null;
}
