import { IAttachment, INewFile, IProduct, namespacedLocalStorage } from "@surelync/common";
import { ProductApiClient } from "@blocksure/blocksure-core/dist/src/services/api-clients";
import BlobApiClient from "@blocksure/blocksure-core/dist/src/services/api-clients/BlobApiClient";
import { setFiles } from "../@EditProductPage/reducer";

export const LOGO_FILE_NAME = "logo.png";
export const RATINGS_FILE_NAME = "rating.xlsm";

export function downloadFile(url: string, fileName: string) {
    const link = document.createElement("a");
    link.setAttribute("download", fileName);
    link.href = url;
    link.click();
}

export function getAttachmentByFileName(attachments: IAttachment[], fileName: string): IAttachment {
    return attachments.find((it) => it && it.name === fileName) || null;
}

const productApiClient = new ProductApiClient();
const appName = namespacedLocalStorage.getItem("app-name");

export function getLocationPath(id: string, location: string): string {
    return `${productApiClient.basepath}${id}/${location}?app-name=${appName}`;
}

export function createAttachmentMetadata(fileInfo: INewFile) {
    return { description: fileInfo.file.name, contentType: fileInfo.type };
}

export async function dowloadBlobs(hashes: string[]) {
    const blobApiClient = new BlobApiClient(namespacedLocalStorage);
    const options = { responseType: "blob" };
    const paths = hashes.map((hash) => blobApiClient.retrievedHash(hash, options));
    return Promise.all(paths);
}

export async function prepareAttachments(attachments: IAttachment[], files: { [key: string]: INewFile }) {
    // if page was reloaded Files could be load from BlobAPI
    // Remove "location" property and ProducrAPI will replace or add new file
    const blobHashes = [];
    attachments.forEach((it) => {
        if (it.location.startsWith("local")) {
            if (!files[it.location]) {
                blobHashes.push(it.blobHash);
            }
        }
    });

    const blobs = blobHashes.length ? await dowloadBlobs(blobHashes) : [];

    attachments.forEach((attachment: IAttachment) => {
        if (attachment.blobHash && !files[attachment.location]) {
            const index = blobHashes.indexOf(attachment.blobHash);
            const { contentType: type } = attachment;
            files[attachment.blobHash] = {
                file: new File([blobs[index]], attachment.name, { type }),
                type,
            } as any;
        }
        if (attachment.location.startsWith("local")) {
            delete attachment.location;
        }
        delete attachment.blobHash;
    });

    return attachments.filter((it) => it.name);
}

export function processAttachments(attachments: IAttachment[], files: { [key: string]: INewFile }) {
    return attachments.map((attachment) => {
        const newFile = files[attachment?.location];
        if (newFile) {
            newFile.name = attachment.name;
            return { ...attachment, ...createAttachmentMetadata(newFile) };
        } else {
            return attachment;
        }
    });
}

export function removeAttachment(formik, dispatch, files, propertyName) {
    delete files[formik.values[propertyName]?.location];
    dispatch(setFiles({ ...files }));
    formik.setFieldValue(propertyName, { name: "", location: "" });
    formik.setFieldTouched(propertyName, true, true);

    // TODO also delete Blob
}

export async function processAttachment(file: File, formik, dispatch, propertyName: string, name: string, files: { [key: string]: INewFile }) {
    const { blobHash, location, type, url } = await uploadFile(file);
    formik.setFieldValue(propertyName, { name, location, blobHash, type, url });
    formik.setFieldTouched(propertyName, true, true);
    dispatch(setFiles({ ...files, [location]: { file, location, type, url } }));
}

let timestamp = Date.now();

export function uploadFile(file: File): Promise<INewFile & { blobHash: string }> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        const addFileToState = async (file: File) => {
            const url = window.URL.createObjectURL(file);
            const type = file.type ? file.type : "text/html";
            const id = `local_${timestamp++}`;
            const blobApiClient = new BlobApiClient(namespacedLocalStorage);
            const headers = { "content-type": type };
            try {
                const res = await blobApiClient.setBlob(file, { headers });
                resolve({ blobHash: res.hash, file, location: id, type, url });
            } catch (err) {
                console.error(err);
                reject(err);
            }
        };
        const handleLoaded = () => {
            reader.removeEventListener("load", handleLoaded);
            addFileToState(file)
        };

        reader.addEventListener("load", handleLoaded, false);
        reader.readAsBinaryString(file);

        // TODO also delete previous Blob if exists
    });
}

export function processFiles(product: IProduct, files: File[]): Promise<{ [id: string]: INewFile }> {
    return new Promise((resolve) => {
        const requests = files.map((it: File) => uploadFile(it));
        Promise.all(requests)
            .then((results) => {
                const cachedFiles = {};
                results.forEach((res: INewFile & { blobHash: string }, index: number) => {
                    const { blobHash, location, type, url } = res;
                    const file = files[index];
                    cachedFiles[location] = { file, location, type, url };
                    [...product.dynamicDocumentTemplates, ...product.hiddenAttachments, ...product.staticAttachments].forEach((attachment) => {
                        if (attachment.name === file.name) {
                            attachment.blobHash = blobHash;
                            attachment.location = location;
                        }
                    });
                });
                resolve(cachedFiles)
            });
    });
}
