import { UISchema } from "./ui-shema.model";
import set from "lodash/set";
import { FormikErrors } from "formik";
import { IGroup, IWidgetBase } from "../TabDataFields/group.model";

export const validateUISchema = (t: (key: string) => string) => (values: UISchema): FormikErrors<UISchema> => {
    const errors: FormikErrors<UISchema> = {};
    const { pages, suffix } = values;

    if (!suffix.trim()) {
        errors.suffix = t("required");
    }

    pages.forEach((page, index) => {
        if (!page.title.trim()) {
            set(errors, `pages[${index}].title`, t("required"));
        }

        page.fields.forEach((field, indexField) => {
            const properties: IWidgetBase[] = (<IGroup>field).properties as any;
            if (properties) {
                properties.forEach((f, indexProperty) => {
                    if (f.isRequired && f.fieldHidden && !f.fieldDefault?.trim()) {
                        set(
                            errors,
                            `pages[${index}].fields[${indexField}].properties[${indexProperty}].fieldDefault`,
                            t("A mandatory field cannot be set to hidden without a default value.")
                        );
                    }
                });
            }
        });
    });

    return errors;
}
