import React from 'react';
import { Box, Button, Divider, Grid, Slide, Typography } from '@material-ui/core';
import { UISchemaField } from '../../ui-shema.model';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useTranslationNamespaces } from '@blocksure/blocksure-core/dist/src/utilities';
import { i18n } from '@surelync/common';
import { getName, getSystemInternalId } from '../../helper';
import { EWidgets, GroupType } from '../../../TabDataFields/group.model';
import { WidgetProperties } from './components/WidgetProperties';
import { AddressProperties } from './components/AddressProperties';

interface Props {
    field: UISchemaField;
    error?: string;
    readOnly: boolean;
    onClose: () => void;
    onDefault: (value: string, index?: number) => void;
    onHidden: (checked: boolean, index?: number) => void;
}

export const FieldProperties: React.FC<Props> = ({ error, field, readOnly, onClose, onDefault, onHidden }) => {
    const { t } = useTranslationNamespaces(i18n, ["surebyld", "surelync"]);

    const renderField = (field: UISchemaField) => {
        if (field.type === GroupType.Address) {
            return (
                <AddressProperties
                    errors={error}
                    field={field}
                    readOnly={readOnly}
                    onDefault={onDefault}
                    onHidden={onHidden}
                />
            );
        } else if (field.type === EWidgets.StringWidget || field.type === EWidgets.EnumWidget) {
            return (
                <WidgetProperties
                    error={error}
                    field={field}
                    readOnly={readOnly}
                    onDefault={onDefault}
                    onHidden={onHidden}
                />
            );
        } else /* if (field.type === EWidgets.QuestionWidget)*/ {
            return <Typography variant="caption">QuestionWidget don't implemented yet</Typography>
        }
    }

    return (
        <Grid item xs={4} xl={6}>
            <Slide direction="left" in={true} mountOnEnter unmountOnExit>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Box textAlign="right">
                            <Button color="primary" size="small" endIcon={<ChevronRightIcon />} variant="text" onClick={onClose}>
                                {t("Show Fields")}
                            </Button>
                        </Box>
                        <Typography variant="subtitle2">{getName(field)}</Typography>
                        <Typography variant="body1">{getSystemInternalId(field)}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        {renderField(field)}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography component="p" variant="caption">{t("Hidden fields must have a default value set. In most other cases no default value is required.")}</Typography>
                        <Typography component="p" variant="caption">{t("In most other cases no default value is required.")}</Typography>
                    </Grid>
                </Grid>
            </Slide>
        </Grid>
    );
};
