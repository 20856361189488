export enum EActiveTabKey {
    activeProductTab = "activeProductTab"
}

export enum EDisplayMode {
    Active = "active",
    ReadyForRelease = "ready4Release",
    UnderDevelopment = "underDevelopment",
}

export const TABS_BLOCKSURE_STAFF = [EDisplayMode.UnderDevelopment, EDisplayMode.ReadyForRelease, EDisplayMode.Active];
export const TABS_NOT_BLOCKSURE_STAFF = [EDisplayMode.UnderDevelopment, EDisplayMode.Active];
