import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, useTheme } from "@material-ui/core";
import { useTranslationNamespaces } from '@blocksure/blocksure-core/dist/src/utilities';
import { i18n, InputCustom } from "@surelync/common";

interface IProps {
    name: string;
    open: boolean;
    onClose: (values: { distribution: string; payee: string } | null) => void;
}

export const AddRowDialog: React.FC<IProps> = ({ open, name, onClose }) => {
    const [distribution, setDistribution] = useState(name);
    const [payee, setPayee] = useState("");
    const { t } = useTranslationNamespaces(i18n, ["surebyld", "surelync"]);
    const theme = useTheme();

    const handleCancel = () => {
        onClose(null);
        setDistribution(name);
        setPayee("");
    };
    const handleOk = () => {
        onClose({
            distribution: distribution.trim(),
            payee: payee.trim(),
        });
        setDistribution(name);
        setPayee("");
    };

    const handleClose = (event, reason) => {
        if (reason === "backdropClick" || reason === "escapeKeyDown") {
            return false;
        }
        handleCancel();
    };

    return (
        <Dialog maxWidth="sm" aria-labelledby="confirmation-dialog-title" open={open} onClose={handleClose}>
            <DialogTitle id="confirm-dialog-title">{t("addRow")}</DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <InputCustom
                            autoComplete="off"
                            name="distribution"
                            maxLength={50}
                            placeholder={`${t("e.g.")} ${t("ipt")}`}
                            theme={theme}
                            value={distribution}
                            onChange={(e) => setDistribution(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputCustom
                            autoComplete="off"
                            name="payee"
                            maxLength={50}
                            placeholder={`${t("e.g.")} ${t("ipt")}`}
                            theme={theme}
                            value={payee}
                            onChange={(e) => setPayee(e.target.value)}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="secondary">
                    {t("cancel")}
                </Button>
                <Button autoFocus onClick={handleOk} color="primary">
                    {t("ok")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
