import React from "react";
import { Action, ProductState } from "./reducer";

export const ProductContext = React.createContext<[ProductState, React.Dispatch<Action>]>(null);

export const AUTO_SAVE_KEY = "SureByld.autosave";

export const DELAY_AUTO_SAVE = 5000;

export const MAX_TAB_NAME = 20;

export enum ETab {
    Settings = "summary",
    FeesAndRatings = "feesAndRatings",
    Documents = "documents",
    Cancellation = "cancellation",
    Sections = "sections",
    DataFields = "dataFields",
    Questions = "questions",
    SureApp = "SureApp"
}

export const TABS = [ETab.Settings, ETab.FeesAndRatings, ETab.Documents, ETab.Cancellation, ETab.Sections, ETab.DataFields, ETab.SureApp];
