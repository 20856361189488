import React from 'react';
import { Checkbox, FormControlLabel, Grid, useTheme } from '@material-ui/core';
import { IWidgetBase } from '../../../../TabDataFields/group.model';
import { i18n, InputCustom } from '@surelync/common';
import { useTranslationNamespaces } from '@blocksure/blocksure-core/dist/src/utilities';

interface Props {
    error: string;
    field: IWidgetBase;
    readOnly: boolean;
    onDefault: (value: string) => void;
    onHidden: (checked: boolean) => void;
}

export const WidgetProperties: React.FC<Props> = React.memo(({ error, field, readOnly, onDefault, onHidden }) => {
    const { t } = useTranslationNamespaces(i18n, ["surebyld", "surelync"]);
    const theme = useTheme();

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <FormControlLabel
                    label={t("hidden")}
                    control={
                        <Checkbox
                            checked={field.fieldHidden || false}
                            color={field.fieldHidden ? "primary" : "secondary"}
                            readOnly={readOnly}
                            onChange={(event) => onHidden(event.target.checked)}
                        />
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <InputCustom
                    autoComplete="off"
                    error={error}
                    label={t("Default Value")}
                    readOnly={readOnly}
                    placeholder={t("Leave default value blank for the significant majority of your fields")}
                    theme={theme}
                    touched={true}
                    value={field.fieldDefault || ""}
                    onChange={(event) => onDefault(event.target.value)}
                />
            </Grid>
        </Grid>
    );
});
