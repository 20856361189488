import React from "react";
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import TableChartIcon from '@material-ui/icons/TableChart';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import CodeOutlinedIcon from '@material-ui/icons/CodeOutlined';

interface IProps {
    contentType: string;
    type: string;
}

const DocumentIcon: React.FC<IProps> = ({ contentType, type }) => {
    switch (type) {
        case "Document":
            return <DescriptionOutlinedIcon color="primary" />;
        case "Email":
            return <EmailOutlinedIcon color="primary" />;
        case "Rating":
            return <TableChartIcon color="primary" />;
        default: {
            if (["image/jpg", "image/png", "image/gif"].includes(contentType)) {
                return <ImageOutlinedIcon color="primary" />
            } else if (["text/css"].includes(contentType)) {
                return <CodeOutlinedIcon color="primary" />
            } else {
                return <InsertDriveFileOutlinedIcon color="primary" />
            }
        }
    }
}

export default DocumentIcon;
