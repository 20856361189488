import React, { Fragment, useContext, useState } from "react";
import { Box, Button, Card, CardContent, CardHeader, Divider, Grid, IconButton, useTheme } from "@material-ui/core";
import useStyles from "../../../styles";
import { useTranslationNamespaces } from '@blocksure/blocksure-core/dist/src/utilities';
import DeleteIcon from "@material-ui/icons/Delete";
import TableHeader from "./TableHeader";
import TableRow from "./TableRow";
import { i18n, IProduct, PromptDialog } from "@surelync/common";
import { IFormik } from "../../../models";
import AddIcon from "@material-ui/icons/Add";
import GlobalContext from "../../../../context/global-context";

interface IProps {
    formik: IFormik<IProduct>;
}

const AdditionalCharges: React.FC<IProps> = ({ formik }) => {
    const { isProd } = useContext(GlobalContext);
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslationNamespaces(i18n, ["surebyld", "surelync"]);
    const [openPrompt, setOpenPromt] = useState(false);

    const additionals = Object.keys(formik.values.payment.additionalCharges);

    const handleNew = (value: string) => {
        const { additionalCharges } = formik.values.payment;

        setOpenPromt(false);
        if (value && !additionalCharges[value]) {
            formik.setFieldValue("payment.additionalCharges", { ...additionalCharges, [value]: {} });
            formik.setFieldTouched("payment.additionalCharges", true, true);
        }
    };

    const handleRemove = (propertyName: string, index: number) => {
        const { additionalCharges } = formik.values.payment;
        if (additionalCharges.hasOwnProperty(propertyName)) {
            const newAdditionalCharges = {...additionalCharges};
            delete newAdditionalCharges[propertyName];
            formik.setFieldValue("payment.additionalCharges",newAdditionalCharges);
            formik.setFieldTouched("payment.additionalCharges", true, true);
        }
    };

    return (
        <>
            <Grid item sm={12}>
                <Card variant="outlined">
                    <CardHeader classes={{ root: classes.root, title: classes.title }} title={t("additionalCharges")} />
                    <CardContent>
                        <Grid container spacing={3} alignItems="center" classes={{ root: classes.my0 }}>
                            <TableHeader key="header" />

                            {additionals.map((fee, index) => (
                                <Fragment key={index}>
                                    <TableRow formik={formik} name={t(fee)} property={`payment.additionalCharges.${fee}`} />
                                    <Grid item xs={1} classes={{ root: classes.py0 }}>
                                        {!isProd ? (
                                            <IconButton
                                                color="primary"
                                                data-testid={`delete-${index}`}
                                                size="small"
                                                onClick={() => {handleRemove(fee, index)}}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        ) : null}
                                    </Grid>
                                </Fragment>
                            ))}

                            {!isProd ? (
                                <Grid item xs={12} key="btn add charges">
                                    <Divider />
                                    <Box textAlign="right" mt={1}>
                                        <Button endIcon={<AddIcon />} variant="text" onClick={() => setOpenPromt(true)}>
                                            {t("addCharges")}
                                        </Button>
                                    </Box>
                                </Grid>
                            ) : null}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <PromptDialog open={openPrompt} placeholder={`${t("e.g.")} ${t("ipt")}`} theme={theme} onClose={handleNew} />
        </>
    );
};

export default AdditionalCharges;
