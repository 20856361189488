import React, { useContext } from "react";
import { Card, CardContent, CardHeader, Grid, Typography, useTheme } from "@material-ui/core";
import useStyles from "../../../styles";
import { useTranslationNamespaces } from '@blocksure/blocksure-core/dist/src/utilities';
import { i18n, InputCustom, IProduct } from "@surelync/common";
import TableHeader from "./TableHeader";
import TableRow from "./TableRow";
import { IFormik } from "../../../models";
import GlobalContext from "../../../../context/global-context";
import { handleChangeNumber } from "../utils";

interface IProps {
    formik: IFormik<IProduct>;
}

const CancellationFeePayees: React.FC<IProps> = ({ formik }) => {
    const { isProd } = useContext(GlobalContext);
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslationNamespaces(i18n, ["surebyld", "surelync"]);

    return (
        <Grid item sm={12}>
            <Card variant="outlined">
                <CardHeader classes={{ root: classes.root, title: classes.title }} title={t("cancellationFeePayees")} />
                <CardContent>
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={6}>
                            <Typography align="right" variant="body1">
                                {t("cancellationFee")}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <InputCustom
                                autoComplete="off"
                                error={formik.errors.cancellationFee as string}
                                name="payment.cancellationFee"
                                readOnly={isProd}
                                theme={theme}
                                touched={!!formik.touched.payment?.cancellationFee}
                                value={formik.values.payment.cancellationFee as string}
                                onBlur={formik.handleBlur}
                                onChange={handleChangeNumber("payment.cancellationFee", formik)}
                            />
                        </Grid>
                        <Grid item xs={4} />

                        <TableHeader />
                        <TableRow
                            formik={formik}
                            name={t("platformCancellationFee")}
                            property="payment.cancellationFeePayees.Platform Cancellation Fee"
                        />
                        <TableRow
                            formik={formik}
                            name={t("brokerCancellationFee")}
                            property="payment.cancellationFeePayees.Broker Cancellation Fee"
                        />
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
};

export default CancellationFeePayees;
