import React, { Fragment, useMemo, useState } from "react";
import { Box, Button, Divider, Grid, Typography, useTheme } from "@material-ui/core";
import { useTranslationNamespaces } from '@blocksure/blocksure-core/dist/src/utilities';
import { i18n, InputCustom, IProduct, SelectCustom } from "@surelync/common";
import get from "lodash/get";
import { Add } from "@material-ui/icons";
import { IFormik } from "../../../models";
import useStyles from "../../../styles";
import { AddRowDialog } from "./AddRowDialog";

interface ITableRow {
    property: string;
    formik: IFormik<IProduct>;
    name: string;
}

const TableRow: React.FC<ITableRow> = ({ property: path, formik, name }) => {
    const [openPrompt, setOpenPrompt] = useState(false);
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslationNamespaces(i18n, ["surebyld", "surelync"]);
    const property = `${path}.${name}`;

    const getPayees = () => Object.keys(get(formik.values, `${property}.payees`));

    const handleAdd = (values: { distribution: string; payee: string } | null) => {
        const obj = get(formik.values, path);

        setOpenPrompt(false);

        if (!values) {
            return;
            // Add premiumSplits["Net Premium"]
        } else if (!obj[values.distribution]) {
            const payees = values.payee ? { [values.payee]: {} } : {};
            const newValue = { amount: null, payee: null, payees, payer: null };
            formik.setFieldValue(path, { ...obj, [values.distribution]: newValue }, true);

            // Add premiumSplits["Net Premium"].payees["Insurer Platform Fee"]
        } else if (!obj[values.distribution].payees[values.payee]) {
            const newValue = { amount: null, payee: null, payer: null };
            formik.setFieldValue(`${path}.${values.distribution}.payees`, { ...obj[values.distribution].payees, [values.payee]: newValue }, true);
        }
    };

    const getPath = (payee: string, propertyName: string) => `${property}.payees.${payee}.${propertyName}`;

    const optionsParties = useMemo(() => {
        const parties = Object.keys(formik.values.partiesAndRoles).sort();
        return ["Blocksure", ...parties].map((it) => ({ label: it, value: it }));
        // eslint-disable-next-line
    }, [formik.values.partiesAndRoles]);

    return (
        <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} classes={{ root: classes.py0 }}>
                <Divider />
                <Typography variant="caption">{t(name)}</Typography>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={11} classes={{ root: classes.py0 }}>
                <Typography variant="body1">
                    {t("distributionOf")} {t(name)}
                </Typography>
            </Grid>
            {getPayees().map((payee, index) => (
                <Fragment key={`payee-${index}`}>
                    <Grid item xs={2} />
                    <Grid item xs={4}>
                        <Grid container spacing={1} alignItems="flex-end" justifyContent="space-between" wrap="nowrap">
                            <Grid item>
                                <Typography variant="caption" style={{ marginRight: 8 }} noWrap>
                                    {payee.startsWith("Net ") ? t("subTotal") : t("deduction")}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography align="right" variant="body2">
                                    {payee}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={2} classes={{ root: classes.py0 }}>
                        <InputCustom
                            autoComplete="off"
                            name={getPath(payee, "amount")}
                            theme={theme}
                            touched={!!get(formik.touched, getPath(payee, "amount"))}
                            value={get(formik.values, getPath(payee, "amount")) || ""}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid item xs={2} classes={{ root: classes.py0 }}>
                        <SelectCustom
                            name={getPath(payee, "payer")}
                            options={optionsParties}
                            theme={theme}
                            touched={!!get(formik.touched, getPath(payee, "payer"))}
                            value={get(formik.values, getPath(payee, "payer")) || ""}
                            onChange={formik.handleChange}
                        />
                    </Grid>
                    <Grid item xs={2} classes={{ root: classes.py0 }}>
                        <SelectCustom
                            name={getPath(payee, "payee")}
                            options={optionsParties}
                            theme={theme}
                            touched={!!get(formik.touched, getPath(payee, "payee"))}
                            value={get(formik.values, getPath(payee, "payee")) || ""}
                            onChange={formik.handleChange}
                        />
                    </Grid>
                </Fragment>
            ))}
            <Grid item xs={12}>
                <Box textAlign="right" mb={1}>
                    <Button endIcon={<Add />} variant="text" onClick={() => setOpenPrompt(true)}>
                        {t("addRow")}
                    </Button>
                </Box>
            </Grid>
            <AddRowDialog open={openPrompt} name={name} onClose={handleAdd} />
        </Grid>
    );
};

export default TableRow;
