import React, { useContext } from "react";
import { Card, CardContent, CardHeader, Grid, Typography, useTheme } from "@material-ui/core";
import { useTranslationNamespaces } from '@blocksure/blocksure-core/dist/src/utilities';
import { i18n, InputCustom, IProduct } from "@surelync/common";
import get from "lodash/get";
import useStyles from "../../../styles";
import { IFormik } from "../../../models";
import GlobalContext from "../../../../context/global-context";

interface IProps {
    formik: IFormik<IProduct>;
}

const IDSettings: React.FC<IProps> = ({ formik }) => {
    const { isProd } = useContext(GlobalContext);
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslationNamespaces(i18n, ["surebyld", "surelync"]);

    const isTouched = (path: string): boolean => get(formik.touched, path);

    return (
        <Grid item sm={12}>
            <Card variant="outlined">
                <CardHeader classes={{ root: classes.root, title: classes.title }} title={t("idSettings")} />
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item md={4} classes={{ root: classes.py0 }} />
                        <Grid item md={2} classes={{ root: classes.py0 }}>
                            <Typography variant="caption">{`${t("start")}*`}</Typography>
                        </Grid>
                        <Grid item md={2} classes={{ root: classes.py0 }}>
                            <Typography variant="caption">{`${t("end")}*`}</Typography>
                        </Grid>
                        <Grid item md={2} classes={{ root: classes.py0 }}>
                            <Typography variant="caption">{t("prefix")}</Typography>
                        </Grid>
                        <Grid item md={2} classes={{ root: classes.py0 }}>
                            <Typography variant="caption">{t("suffix")}</Typography>
                        </Grid>

                        {/* POLICIES  */}
                        <Grid item md={4} classes={{ root: classes.py0 }}>
                            <Typography align="right" variant="body1">
                                {t("policies")}
                            </Typography>
                        </Grid>
                        <Grid item md={2} classes={{ root: classes.py0 }}>
                            <InputCustom
                                autoComplete="off"
                                error={formik.errors.policiesStart as string}
                                name="idRanges.policies.start"
                                readOnly={isProd}
                                theme={theme}
                                touched={isTouched("idRanges.policies.start")}
                                value={formik.values.idRanges.policies.start as string}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                            />
                        </Grid>
                        <Grid item md={2} classes={{ root: classes.py0 }}>
                            <InputCustom
                                autoComplete="off"
                                error={formik.errors.policiesEnd as string}
                                name="idRanges.policies.end"
                                readOnly={isProd}
                                theme={theme}
                                touched={isTouched("idRanges.policies.end")}
                                value={formik.values.idRanges.policies.end as string}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                            />
                        </Grid>
                        <Grid item md={2} classes={{ root: classes.py0 }}>
                            <InputCustom
                                autoComplete="off"
                                name="idRanges.policies.prefix"
                                readOnly={isProd}
                                theme={theme}
                                value={formik.values.idRanges.policies.prefix as string}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                            />
                        </Grid>
                        <Grid item md={2} classes={{ root: classes.py0 }}>
                            <InputCustom
                                autoComplete="off"
                                name="idRanges.policies.suffix"
                                readOnly={isProd}
                                theme={theme}
                                value={formik.values.idRanges.policies.suffix as string}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                            />
                        </Grid>

                        {/* CLAIMS  */}
                        <Grid item md={4} classes={{ root: classes.py0 }}>
                            <Typography align="right" variant="body1">
                                {t("claims")}
                            </Typography>
                        </Grid>
                        <Grid item md={2} classes={{ root: classes.py0 }}>
                            <InputCustom
                                autoComplete="off"
                                error={formik.errors.claimsStart as string}
                                name="idRanges.claims.start"
                                readOnly={isProd}
                                theme={theme}
                                touched={isTouched("idRanges.claims.start")}
                                value={formik.values.idRanges.claims.start as string}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                            />
                        </Grid>
                        <Grid item md={2} classes={{ root: classes.py0 }}>
                            <InputCustom
                                autoComplete="off"
                                error={formik.errors.claimsEnd as string}
                                name="idRanges.claims.end"
                                readOnly={isProd}
                                theme={theme}
                                touched={isTouched("idRanges.claims.end")}
                                value={formik.values.idRanges.claims.end as string}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                            />
                        </Grid>
                        <Grid item md={2} classes={{ root: classes.py0 }}>
                            <InputCustom
                                autoComplete="off"
                                name="idRanges.claims.prefix"
                                readOnly={isProd}
                                theme={theme}
                                value={formik.values.idRanges.claims.prefix as string}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                            />
                        </Grid>
                        <Grid item md={2} classes={{ root: classes.py0 }}>
                            <InputCustom
                                autoComplete="off"
                                name="idRanges.claims.suffix"
                                readOnly={isProd}
                                theme={theme}
                                value={formik.values.idRanges.claims.suffix as string}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
};

export default IDSettings;
