import React, { useCallback, useContext, useMemo } from "react";
import { Grid, Hidden, useTheme } from "@material-ui/core";
import { useTranslationNamespaces } from '@blocksure/blocksure-core/dist/src/utilities';
import { i18n, InputCustom, SelectCustom } from "@surelync/common";
import { IFormik } from "../../../../models";
import { IDataDictionary } from "../../data-dictionary.model";
import GlobalContext from "../../../../../context/global-context";
import DataFields from "../DataFields/DataFields";
import { ProductContext } from "../../../../contexts";
import { autoGenerateSystemId, handleFocus } from "../../helper";
import { GroupType } from "../../group.model";
import Questions from "../Questions/Questions";
import { AdvancedModeCheckbox } from "../../../AdvancedModeCheckbox/AdvancedModeCheckbox";

const TYPES = [GroupType.Address, GroupType.Personal, GroupType.Questions];

interface IProps {
    countryLabel: string;
    formik: IFormik<IDataDictionary>;
    selectedGroupIndex: number;
}

const Overview: React.FC<IProps> = ({ countryLabel, formik, selectedGroupIndex }) => {
    const { isProd } = useContext(GlobalContext);
    const [{ isAdvancedMode }, dispatch] = useContext(ProductContext);
    const theme = useTheme();
    const { t } = useTranslationNamespaces(i18n, ["surebyld", "surelync"]);
    const group = formik.values.groups[selectedGroupIndex]

    const optionsType = useMemo(() => TYPES.map((it) => ({ label: t(it), value: it })), [t]);

    const handleAutoGenerateSystemID = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const property = `groups[${selectedGroupIndex}].groupSystemInternalId`;
        const { groupName, groupSystemInternalId } = group;
        formik.handleBlur(event);
        autoGenerateSystemId(formik, property, groupSystemInternalId, groupName);
    }, [formik, group])

    const handleChangeType = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        formik.setFieldValue(`groups[${selectedGroupIndex}].type`, value, true);
        formik.setFieldValue(`groups[${selectedGroupIndex}].properties`, null, true);
    };

    if (!formik.values.groups) {
        return null;
    }

    const renderDictionary = () => {
        switch (group.type) {
            case GroupType.AdditionalDictionary:
            case GroupType.Address:
            case GroupType.Personal: {
                return <DataFields countryLabel={countryLabel} formik={formik} property={`groups[${selectedGroupIndex}]`} />;
            }
            case GroupType.Questions: {
                return <Questions formik={formik} property={`groups[${selectedGroupIndex}]`} />
            }
            default:
                console.warn("Unsupported Type", group.type);
        }
    };

    return (
        <Grid item xs={12} data-testid="dictionary-overview">
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <InputCustom
                        autoComplete="off"
                        data-testid="group-name"
                        error={formik.errors?.groups?.[selectedGroupIndex]?.groupName}
                        label={`${t("group")} ${t("name")} (${t("displayedToAllParties")})`}
                        name={`groups[${selectedGroupIndex}].groupName`}
                        readOnly={isProd || group.isFromUnersalSchema}
                        required
                        theme={theme}
                        touched={formik.touched.groups?.[selectedGroupIndex]?.groupName}
                        value={formik.values.groups?.[selectedGroupIndex].groupName}
                        onBlur={handleAutoGenerateSystemID}
                        onChange={formik.handleChange}
                    />
                </Grid>
                <Grid item xs={3}>
                    <SelectCustom
                        name={`groups[${selectedGroupIndex}].type`}
                        label={t("type")}
                        options={optionsType}
                        readOnly={isProd || group.isFromUnersalSchema}
                        required
                        theme={theme}
                        value={formik.values.groups?.[selectedGroupIndex].type}
                        onChange={handleChangeType}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Hidden xsUp={!isAdvancedMode}>
                        <InputCustom
                            autoComplete="off"
                            data-testid="group-system-internal-id"
                            error={formik.errors?.groups?.[selectedGroupIndex]?.groupSystemInternalId}
                            label={`${t("group")} ${t("id")} (${t("systemInternal")} ${t("id")})`}
                            name={`groups[${selectedGroupIndex}].groupSystemInternalId`}
                            readOnly={isProd || group.isFromUnersalSchema}
                            required
                            theme={theme}
                            touched={formik.touched.groups?.[selectedGroupIndex]?.groupSystemInternalId}
                            value={formik.values.groups?.[selectedGroupIndex].groupSystemInternalId}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            onFocus={isProd || group.isFromUnersalSchema
                                ? null
                                : handleFocus(formik.values.groups?.[selectedGroupIndex].groupSystemInternalId, formik.touched.groups?.[selectedGroupIndex]?.groupSystemInternalId, dispatch)}
                        />
                    </Hidden>
                </Grid>
                <Grid item xs={12}>
                    {renderDictionary()}
                </Grid>
                <Grid item xs={12}>
                    <AdvancedModeCheckbox />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default React.memo(Overview);
