import React, { useMemo } from "react";
import { Card, CardContent, CardHeader, Divider, Grid, Typography } from "@material-ui/core";
import { useTranslationNamespaces } from '@blocksure/blocksure-core/dist/src/utilities';
import { i18n, IProduct } from "@surelync/common";
import { IFormik } from "../../../models";
import useStyles from "../../../styles";
import TableHeader from "./TableHeader";
import TableRow from "./TableRow";

interface IProps {
    formik: IFormik<IProduct>;
    selectedSection: string;
}

const Distribution: React.FC<IProps> = ({ formik, selectedSection }) => {
    const classes = useStyles();
    const { t } = useTranslationNamespaces(i18n, ["surebyld", "surelync"]);
    const premiumSplits = formik.values.sections[selectedSection].payment?.premiumSplits;

    const distributes = useMemo(() => (premiumSplits ? Object.keys(premiumSplits) : []), [premiumSplits]);

    return (
        <Grid item sm={12}>
            <Card variant="outlined">
                <CardHeader classes={{ root: classes.root, title: classes.title }} title={t("distribution")} />
                <CardContent>
                    <Typography paragraph variant="caption">
                        {t("showsDistributed")}
                    </Typography>
                    <TableHeader />
                    {distributes.map((name, index) => (
                        <TableRow
                            key={`premium-${index}`}
                            formik={formik}
                            name={name}
                            property={`sections.${selectedSection}.payment.premiumSplits`}
                        />
                    ))}
                    <Divider />
                    <Typography variant="caption">{t("validation")}</Typography>
                </CardContent>
            </Card>
        </Grid>
    );
};

export default Distribution;
