import React, { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useState } from "react";
import { useFormik } from "formik";
import { DELAY_AUTO_SAVE, ETab, ProductContext } from "../../contexts";
import { autosave, setFiles, update } from "../../reducer";
import Grid from "@material-ui/core/Grid";
import { EAttachmentTransaction, i18n, IAttachment, INotification, IProduct } from "@surelync/common";
import { getLocationPath, processAttachments } from "../../../utils/helper";
import { Box, Button, Card, CardContent, Typography } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import Document from "../Document/Document";
import { useTranslationNamespaces } from '@blocksure/blocksure-core/dist/src/utilities';
import useStyles from "../../styles";
import GlobalContext from "../../../context/global-context";
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import set from "lodash/set";
import { storeProduct } from "../../../utils";
import DialogTransactions from "./components/DialogTransactions";
import { getEmailName, getTemplateName, isSystemName, validNotificationName } from "./helpers";
import camelCase from "lodash/camelCase";

const transactionNames = Object.keys(EAttachmentTransaction);

type AttachmentArrayNames = "dynamicDocumentTemplates" | "staticAttachments";
type IProps = Record<string, unknown>;

const TabDocuments: React.ForwardRefRenderFunction<Record<string, unknown>, IProps> = (props, ref) => {
    const { isProd, genericAttachments } = useContext(GlobalContext);
    const [{ data, files }, dispatch] = useContext(ProductContext);
    const { t } = useTranslationNamespaces(i18n, ["surebyld", "surelync"]);
    const classes = useStyles();
    const [openTransactionsModal, setOpenTransactionsModal] = useState<"doc" | "email" | null>(null);

    const prepareData = useCallback(
        (values): IProduct => {
            const product = { ...data };

            product.dynamicDocumentTemplates = processAttachments(values.dynamicDocumentTemplates, files);
            product.staticAttachments = processAttachments(values.staticAttachments, files);
            product.notifications = values.notifications;

            return product;
        },
        [data.dynamicDocumentTemplates, data.notifications, data.staticAttachments, files]
    );

    const formik = useFormik({
        initialValues: {
            dynamicDocumentTemplates: [...data.dynamicDocumentTemplates],
            notifications: [...data.notifications],
            staticAttachments: [...data.staticAttachments],
        },
        enableReinitialize: true,
        validate: (values) => {

            let errors: { [key: string]: string | Array<{ [key: string]: string }> } = {};

            const validateAttachments = (propertyName: string) => {
                values[propertyName].forEach((attachment, idx) => {
                    if (!attachment.name.trim()) {
                        errors = set({ ...errors }, `[${idx}].name`, t("required"));
                    }
                    if (!attachment.location) {
                        errors = set({ ...errors }, `[${idx}].file`, t("required"));
                    }
                });
            };

            validateAttachments("dynamicDocumentTemplates");
            validateAttachments("staticAttachments");

            return errors;
        },
        onSubmit: (values) => {
            const product: IProduct = prepareData(values);
            dispatch(update(product));
        },
    });

    // AutoSave
    useEffect(() => {
        if (!formik.dirty) return;

        const timerId = setTimeout(() => {
            const params = {
                activeTab: ETab.Documents,
                product: prepareData(formik.values),
            };
            const savedProduct = storeProduct(params);
            dispatch(update(savedProduct));
            dispatch(autosave(Date.now()));
        }, DELAY_AUTO_SAVE);

        return () => clearTimeout(timerId);
    }, [formik.dirty, formik.values, dispatch, prepareData]);

    const addFileToState = (file: File, attachment: IAttachment, arrayName: AttachmentArrayNames, isReplace: boolean) => {
        const url = window.URL.createObjectURL(file);
        const type = file.type || "text/html";
        const id = `local_${Date.now()}`;
        const params = { file, location: id, type, url };

        attachment.location = id;
        // System-set name takes priority over whatever user provided
        if (isReplace && !isSystemName(attachment, genericAttachments)) {
            attachment.name = getTemplateName(file);
        }
        dispatch(setFiles({ ...files, [id]: params }));
        formik.setFieldTouched(arrayName, true, true);
    };

    const handleAttachToEmail = useCallback((transaction: EAttachmentTransaction, document: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const attach = event.target.checked;
        const { notifications } = formik.values;
        const notification = notifications.find((it) => it.transaction === transaction);
        if (attach) {
            notification.documentsAttached = [...notification.documentsAttached, document];
        } else {
            notification.documentsAttached = notification.documentsAttached.filter((it) => it !== document);
        }
        formik.setFieldValue("notifications", [...notifications]);
    }, [formik.values.notifications]);

    const handleCreateNew = useCallback((event?: React.ChangeEvent<HTMLInputElement>, transactions?: EAttachmentTransaction[]) => {
        setOpenTransactionsModal(null);
        if (!transactions) {
            return;
        }

        const file = event.target.files[0];
        const newAttachment: IAttachment = {
            contentType: null,
            description: null,
            location: null,
            modifiedDate: null,
            name: "",
            transactions
        };
        let arrayName: AttachmentArrayNames;

        if (openTransactionsModal === "doc") {
            arrayName = file.type === "text/html" || file.type === "text/plain" ? "dynamicDocumentTemplates" : "staticAttachments";
            newAttachment.name = getTemplateName(file);
            transactions.forEach((transaction) => {
                const notification = formik.values.notifications.find((it) => it.transaction === transaction);
                notification.documentsAttached.push(newAttachment.name);
            });
        } else if (openTransactionsModal === "email") {
            arrayName = "dynamicDocumentTemplates";
            newAttachment.name = getEmailName(file);
            const newNotifications: INotification[] = transactions.map((transaction) => ({
                document: newAttachment.name,
                documentsAttached: [],
                offsets: null,
                relativeTo: null,
                transaction,
            }));
            formik.setFieldValue("notifications", [...formik.values.notifications, ...newNotifications]);
        }

        formik.setFieldValue(arrayName, [...formik.values[arrayName], newAttachment]);
        handleFileChange(newAttachment, arrayName, false)(event);

    }, [openTransactionsModal]);

    const handleEnableEmail = useCallback((transaction: EAttachmentTransaction, document: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const enable = event.target.checked;
        const { notifications } = formik.values;
        let newNotifications: INotification[];
        if (enable) {
            const newNotification: INotification = {
                document,
                documentsAttached: [],
                offsets: null,
                relativeTo: null,
                transaction
            };
            newNotifications = [...notifications, newNotification];
        } else {
            newNotifications = notifications.filter((it) => !(it.transaction === transaction && it.document === document));
        }
        formik.setFieldValue("notifications", newNotifications);
    }, [formik.values.notifications]);

    const handleFileChange = useCallback((attachment: IAttachment, arrayName: AttachmentArrayNames, isReplace: boolean) => (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.addEventListener("load", () => addFileToState(file, attachment, arrayName, isReplace), false);
        reader.readAsBinaryString(file);
    }, [formik.values.notifications, formik.values.dynamicDocumentTemplates, formik.values.staticAttachments]);

    const handleRemoveAttachment = useCallback((transaction: EAttachmentTransaction, attachment: IAttachment, arrayName: AttachmentArrayNames) => () => {
        const attachments = formik.values[arrayName];
        const { notifications } = formik.values;
        const newAttachment = attachments.find((it) => it === attachment);

        newAttachment.transactions = newAttachment.transactions.filter((it) => it !== transaction);
        formik.setFieldValue(arrayName, [...attachments]);

        const notification = notifications.find((it) => it.transaction === transaction);
        if (notification) {
            notification.documentsAttached = notification.documentsAttached.filter((it) => it !== attachment.name);
            formik.setFieldValue("notifications", [...notifications]);
        }
    }, [formik.values.notifications]);

    const handleRemoveNotification = useCallback((transaction: EAttachmentTransaction, attachment: IAttachment) => () => {
        const { dynamicDocumentTemplates, notifications } = formik.values;
        const newAttachment = dynamicDocumentTemplates.find((it) => it === attachment);
        const newNotifications = notifications.filter((it) => it.transaction !== transaction);
        formik.setFieldValue("notifications", newNotifications);
        newAttachment.transactions = newAttachment.transactions.filter((it) => it !== transaction);
        formik.setFieldValue("dynamicDocumentTemplates", [...dynamicDocumentTemplates]);

    }, [formik.values.notifications, formik.values.dynamicDocumentTemplates, formik.values.staticAttachments, files]);

    const isAttached = (transaction: EAttachmentTransaction, attachmentName: string) => {
        const notification = formik.values.notifications.find((it) => it.transaction === transaction);
        return notification?.documentsAttached.includes(attachmentName);
    };

    // call methods from parent
    useImperativeHandle(ref, () => formik);

    const renderAttachment = (arrayName: AttachmentArrayNames, transaction: EAttachmentTransaction) => {
        const notificationTransactions = formik.values.notifications.map((it) => it.transaction);
        const attachments: IAttachment[] = formik.values[arrayName]
            .filter((attachment: IAttachment) => attachment.transactions.includes(transaction))
            // FIXME not 100% sure this suffix condition
            .filter((attachment: IAttachment) => !validNotificationName(attachment.name))
            .sort((attachment1: IAttachment, attachment2: IAttachment) => attachment1.name.localeCompare(attachment2.name));

        return attachments
            .map((attachment: IAttachment, idx: number) => (
                <Document
                    attachment={attachment}
                    disabled={isProd}
                    error={formik.errors[arrayName]?.[idx] as string}
                    files={files}
                    href={getLocationPath(data?.id, attachment.location)}
                    isAttached={!!isAttached(transaction, attachment.name)}
                    isCanBeAttached={notificationTransactions.includes(transaction)}
                    key={`att_${attachment.location}_${idx}`}
                    name={`attachments[${idx}].name`}
                    margin="dense"
                    readOnly={isProd}
                    touched={formik.touched[arrayName]?.[idx]?.name}
                    type="Document"
                    value={attachment.name}
                    onCheckboxChange={handleAttachToEmail(transaction, attachment.name)}
                    onFileChange={handleFileChange(attachment, arrayName, true)}
                    onRemove={handleRemoveAttachment(transaction, attachment, arrayName)}
                />
            ));
    };

    const renderNotification = (transaction: EAttachmentTransaction) => {
        const notificationLikeAttachments: IAttachment[] = formik.values.dynamicDocumentTemplates
            .filter((attachment: IAttachment) => attachment.transactions.includes(transaction))
            .filter((attachment: IAttachment) => validNotificationName(attachment.name));

        return notificationLikeAttachments.map((attachment: IAttachment, idx: number) => (
            <Document
                attachment={attachment}
                disabled={isProd}
                files={files}
                href={getLocationPath(data?.id, attachment.location)}
                isAttached={!!formik.values.notifications.find((it) => it.document === attachment.name && it.transaction === transaction)}
                isCanBeAttached={true}
                key={`att_${attachment.location}_${idx}`}
                name={null} // it is readonly
                margin="dense"
                readOnly={isProd}
                type="Email"
                value={attachment.name}
                onCheckboxChange={handleEnableEmail(transaction, attachment.name)}
                onFileChange={handleFileChange(attachment, "dynamicDocumentTemplates", true)}
                onRemove={handleRemoveNotification(transaction, attachment)}
            />
        ));
    }

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <Grid container>
                    <Grid item sm={12} md={10} lg={8} xl={6}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Grid container justifyContent="flex-end">
                                    <Grid item classes={{ root: classes.py0 }}>
                                        <Button
                                            color="primary"
                                            endIcon={<Add />}
                                            variant="text"
                                            onClick={() => setOpenTransactionsModal("doc")}
                                        >
                                            {t("createDocument")}
                                        </Button>
                                    </Grid>
                                    <Grid item classes={{ root: classes.py0 }}>
                                        <Button
                                            color="primary"
                                            endIcon={<Add />}
                                            variant="text"
                                            onClick={() => setOpenTransactionsModal("email")}
                                        >
                                            {t("createEmail")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Box pl={1}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={2} classes={{ root: classes.py0 }}>
                                            <Typography variant="body2">{t("transaction")}</Typography>
                                        </Grid>
                                        <Grid item classes={{ root: classes.py0 }}>
                                            <Box visibility="hidden">
                                                <EmailOutlinedIcon />
                                            </Box>
                                        </Grid>
                                        <Grid item classes={{ root: classes.py0 }}>
                                            <Typography variant="body2">{t("notificationOrDocumentName")}</Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>

                            {transactionNames.map((transaction: EAttachmentTransaction, index: number) => (
                                <Grid item xs={12} key={`transaction${index}-${transaction}`}>
                                    <Card variant="outlined">
                                        <CardContent>
                                            <Grid container spacing={2}>
                                                <Grid item xs={2}>
                                                    <Grid container alignItems="center" className={classes.fullHeight}>
                                                        <Grid item>
                                                            {t(camelCase(transaction))}
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={10}>
                                                    {renderNotification(transaction)}
                                                    {renderAttachment("dynamicDocumentTemplates", transaction)}
                                                    {renderAttachment("staticAttachments", transaction)}
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </form >
            <DialogTransactions open={!!openTransactionsModal} onClose={handleCreateNew} />
        </>
    );
};

export default forwardRef(TabDocuments);
