const times = ["H", "S"];
export const getPrefix = (denominValue: string) => (times.includes(denominValue) ? "PT" : "P");

export const parseDenomination = (s: string, isForServer?: boolean) => {
    const result = s ? s.replace(new RegExp("[PT0-9]", "g"), "") : "";

    // S is not valid for storing. It should be replaced with PT30M
    if (!isForServer) {
        return result === "M" && s.includes("T") ? "S" : result;
    }
    return result;
};

export const parseNumber = (s: string) => s ? s.replace(new RegExp("[^0-9]", "g"), "") : "";

export function applyDenomination(propertyValue: string, newDenomination: string): string {
    const denominationValue = newDenomination === "S" ? "M" : newDenomination;
    const prefix = getPrefix(newDenomination);
    return `${prefix}${parseNumber(propertyValue) || "1"}${denominationValue}`;
}

export function applyNumber(propertyValue: string, newNumber: string): string {
    const denominationValue = parseDenomination(propertyValue, true) || "H";
    const prefix = getPrefix(parseDenomination(propertyValue));
    let numValue = parseNumber(newNumber) || 1;

    if (numValue > 60) numValue = 60;

    return `${prefix}${numValue}${denominationValue}`
}
