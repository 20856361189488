import { i18n } from "@surelync/common";
import { IDataDictionary } from "./data-dictionary.model";
import { IEnumWidget, IGroup, IStringWidget } from "./group.model";
import set from "lodash/set";

const firstCharsRegex = /^[a-zA-Z].*/;
const spacesRegex = /^\S+$/;
const underscoreRegex = /^[^_]+$/;

export function validateDataDictionary(values: IDataDictionary): { groups: Partial<IGroup>[] } {
    const errors: Partial<IGroup>[] = [];

    values.groups.forEach((group, index) => {
        const result: Partial<IGroup> = {};
        if (!group.groupName) {
            result.groupName = i18n.t("required");
        }
        if (!group.groupSystemInternalId) {
            result.groupSystemInternalId = i18n.t("required");
        } else {
            const idValidationValue = idValidation("groupSystemInternalId", group);
            if (idValidationValue) {
                result.groupSystemInternalId = idValidationValue;
            }
        }
        if (group.properties) {
            if (group.type !== "questions") {
                const sections: IStringWidget[] | IEnumWidget[] = group.properties as IEnumWidget[] | IStringWidget[];
                sections.forEach((s, index) => {
                    const err = validateSection(s);
                    if (err) {
                        set(result, `properties[${index}]`, err);
                    }
                });
            }
            // else if (group.type === "questions") {
            //     const questions = group.properties as IQuestionWidget;
            //     const err = validateQuestion(questions);
            //     if (err) {
            //         set(result, `properties`, err);
            //     }
            // }
        }
        if (Object.keys(result).length) {
            errors[index] = result;
        }
    });
    return errors.length ? { groups: errors } : null;
}

function idValidation (key, values) {
    const value = values[key];
    if (!value) {
        return i18n.t("required");
    }
    if (!spacesRegex.test(value)) {
        return i18n.t("notSpaces");
    }
    if (!underscoreRegex.test(value)) {
        return i18n.t("notUnderscore");
    }
    if (!firstCharsRegex.test(value)) {
        return i18n.t("notStartWithNumber");
    }
}

function validateSection(values: IStringWidget): Partial<IStringWidget> | null {
    const result: Partial<IStringWidget> = {};
    const title = values.fieldName ? values.fieldName.trim() : "";
    const idValidationValue = idValidation("fieldSystemInternalId", values);
    if (!title) {
        result.fieldName = i18n.t("required");
    }
    if (idValidationValue) {
        result.fieldSystemInternalId = idValidationValue;
    }
    return Object.keys(result).length ? result : null;
}

// function validateQuestion(values: IQuestionWidget): Partial<IQuestionWidget> | null {
//     const result: Partial<IQuestionWidget> = {};
//     const header = values.header ? values.header.trim() : "";
//     const instructionQuote = values.instructionQuote ? values.instructionQuote.trim() : "";
//     const instructionMTA = values.instructionMTA ? values.instructionMTA.trim() : "";

//     if (!header) {
//         result.header = i18n.t("required");
//     }
//     if (!instructionQuote) {
//         result.instructionQuote = i18n.t("required");
//     }
//     if (!instructionMTA) {
//         result.instructionMTA = i18n.t("required");
//     }
//     if (values.properties) {
//         const questionErrors = [];
//         values.properties.forEach((question, index) => {
//             const answerPositive = question.answerPositive ? question.answerPositive.trim() : "";
//             const answerNegative = question.answerNegative ? question.answerNegative.trim() : "";
//             const text = question.text.trim();
//             if (!text) {
//                 set(questionErrors, `[${index}].text`, i18n.t("required"));
//             }
//             if (!answerPositive) {
//                 set(questionErrors, `[${index}].answerPositive`, i18n.t("required"));
//             }
//             if (!answerNegative) {
//                 set(questionErrors, `[${index}].answerNegative`, i18n.t("required"));
//             }
//         });
//         if (questionErrors.length) {
//             result.properties = questionErrors;
//         }
//     }
//     if (values.isAnswerAll) {
//         const answerAllPositive = values.answerAllPositive ? values.answerAllPositive.trim() : "";
//         const answerAllNegative = values.answerAllNegative ? values.answerAllNegative.trim() : "";
//         if (!answerAllPositive) {
//             result.answerAllPositive = i18n.t("required");
//         }
//         if (!answerAllNegative) {
//             result.answerAllNegative = i18n.t("required");
//         }
//     }
//     if (values.footer && values.footer.questionsQuote) {
//         values.footer.questionsQuote.forEach((item, index) => {
//             if (!item.trim()) {
//                 set(result, `footer.questionsQuote[${index}]`, i18n.t("required"));
//             }
//         });
//     }
//     if (values.footer && values.footer.questionsMTA) {
//         values.footer.questionsMTA.forEach((item, index) => {
//             if (!item.trim()) {
//                 set(result, `footer.questionsMTA[${index}]`, i18n.t("required"));
//             }
//         });
//     }
//     return Object.keys(result).length ? result : null;
// }
